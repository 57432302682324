<template>
    <!-- 顶部图片 -->
  <div class="container text-center home-container all">
	<div class="breadcrumbs">
		<router-link to="/policy">新闻资讯</router-link> > {{info.articleTypeName}}
	</div>
    <!-- 标题区 -->
    <div class="title">
      <div class="text">
        {{info.title}}
      </div>
      <div class="title-time">
        <div class="time-box" v-if="getTime()!=''">
          发布时间：<span class="time">{{getTime()}}</span>
        </div>
        <div class="from" v-if="info.source!=null">来源：{{info.source}}</div>
        <div class="readnum" v-if="info.author!=null">作者：{{info.author}}</div>
      </div>
    </div>
    <!-- 下划线 -->
    <div class="line"></div>
    <!-- 内容 -->
    <div class="detail-content">
      <div v-html="info.content"></div>
    </div>
    <!-- 底部文字 -->
  <!--  <div class="bottom">
      <img src="../assets/public/leftarrow.png" alt="" class="arrow" />
      <div class="text-font">
        <p>暂无数据</p>
        <p>最新政务网通知规定新出炉…</p>
      </div>
      <img src="../assets/public/rightarrow.png" alt="" class="arrow" />
    </div> -->
    <div class="search-right">
      <div class="right-text">推荐资讯</div>

		<div class="hot-content" v-for="(item2, i2) in hotList" :key="i2" @click="goPolicyDetail(item2)">
		  <img :src="item2.tailorPictureUrl" class="content-img" />
		  <div class="hot-box">
			<div class="hot-title">
			  {{ item2.title }}
			</div>
			<div class="hot-time">
			  <div>
				<img src="../assets/policy/icon-time.png" alt="" /><span
				  class="time-text"
				  >{{ item2.releaseTime.split('T')[0] }}</span
				>
			  </div>
			  <div class="yclaiyuan">来源：{{ item2.source }}</div>
			</div>
		  </div>
		</div>
    </div>
  </div>
</template>

<script>
	
import {GetPolicyInformationDto,GetWebSiteGetDetails,GetTuiJianInfo} from '@/api/index'
import PolicyRightView from "@/views/PolicyRightView.vue";
export default {
  name: "PolicyDetailView",
  components: {
    PolicyRightView,
  },
  data() {
    return {
      info:{},
	  id:'',
	  hotList:[]
    };
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
					this.getGetWebSiteGetDetails()
					this.getGetTuiJianInfo()
				}
			},
			deep: true,
		},
	},
  mounted() {
	this.id = this.$route.query.id
  	this.getGetWebSiteGetDetails()
	this.getGetTuiJianInfo()
  },
  methods:{
	getGetTuiJianInfo(){
		GetTuiJianInfo({}).then(res=>{
			this.hotList=res
			
		})
	},
	getTime(){
	  return this.info.releaseTime==undefined?'':this.info.releaseTime.split('T')[0]
	},
	getGetWebSiteGetDetails(){
		GetWebSiteGetDetails({Id:this.id}).then(res=>{
			this.info=res
		})
	},
    goPolicyDetail(item) {
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/policydetail?id='+item.id);
		}
    },
  }
};
</script>

<style lang="less" scoped>
.top-bigbg {
  position: relative;
  height: 25.25rem;
  .bigbg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .bigbg-box {
    position: relative;
    padding-top: 10rem;
    padding-left: 10.2rem;
    color: #ffffff;
    text-align: left;
    .bigbg-text {
      font-size: 3rem;
    }
    .text-eng {
      font-size: 1.1rem;
      letter-spacing: 3px;
    }
  }
}
.all {
  position: relative;
  .title {
    display: flex;
    flex-direction: column;
    width: 47.6rem;
	margin-top: 20px;
    .text {
      font-size: 1.8rem;
      font-weight: bold;
      width: 47.1rem;
      min-height: 2.5rem;
    }
    .title-time {
      display: flex;
      justify-content: center;
      font-size: 0.7rem;
      margin-top: 1.45rem;
      height: 1rem;
      .time-box {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 0 1rem;
		width: 235px;
        .box-img {
          position: relative;
          margin-top: 3px;
          width: 0.7rem;
          height: 0.7rem;
        }
        .time {
          margin-left: 0.45rem;
        }
      }
      .from {
        margin: 0 1rem;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
      }
      .readnum {
        margin: 0 1rem;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
      }
    }
  }
  .line {
    width: 46.95rem;
    height: 0.05rem;
    background-color: #efefef;
    margin: 1.5rem 0;
  }
  .detail-img-box {
    text-align: left;
    .detail-img {
      width: 47.15rem;
      height: 19.25rem;
    }
  }
  .detail-content {
    width: 47.15rem;
    min-height: 26.95rem;
    margin-top: 1.5rem;
    text-align: left;
    font-size: 0.7rem;
    .bigtitle {
      font-weight: bold;
      margin-top: 2rem;
    }
  }
  .state {
    font-size: 0.7rem;
    font-weight: bold;
    text-align: left;
  }
  .bottom {
    display: flex;
    justify-content: center;
    margin-top: 7.25rem;
    font-size: 0.7rem;
    margin-bottom: 5.5rem;
    .arrow {
      width: 0.35rem;
      height: 0.65rem;
      margin-top: 4px;
    }
    .text-font {
      display: flex;
      justify-content: space-between;
      margin-left: 1.4rem;
      margin-right: 0.9rem;
      width: 20rem;
      height: 1rem;
    }
  }
  .search-right {
    .right-text {
      font-size: 1.1rem;
      font-weight: bold;
      text-align: left;
    }
    position: absolute;
    top: 0.3rem;
    right: 0;
  }
}
.detail-content img{
	max-width: 100% !important;
}

.hot-content {
  margin-top: 1.1rem;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .content-img {
    width: 8.45rem;
    height: 4.4rem;
  }
  .hot-box {
    margin-left: 1rem;
    .hot-title {
      text-align: left;
      width: 11.3rem;
      height: 2rem;
      font-size: 0.7rem;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .hot-time {
      margin-top: 1.1rem;
      display: flex;
      font-size: 0.6rem;
      justify-content: space-between;
      .time-text {
        margin-left: 0.55rem;
      }
	  .yclaiyuan{
		max-width: 117px;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

	  }
    }
  }
}
@media (max-width: 768px) {
	.hot-title {
		width: 100% !important;
		height: auto !important;
		
	}
  .top-bigbg {
    height: 200px;
    .bigbg-box {
      position: relative;
      padding-top: 70px !important;
      padding-left: 70px !important;
      .bigbg-text {
        font-size: 25px;
      }
      .text-eng {
        font-size: 20px;
        letter-spacing: 1px;
      }
      .box-line {
        margin-top: 20px;
      }
    }
  }
  .search-right{
    display: none;
  }
  .all{
    .title{
      width: 100%;
	  margin-top: 20px;
      .text{
        width: 100%;
        font-size: 16px;
		height: auto;
      }
      .title-time{
        font-size: 12px;
		height: auto;
		margin-top: 15px;
		display: block;
		text-align: left;
        .time-box{
          margin: 0 0px;
		  align-items: center;
			display: inline-block;
			margin: 0 15px;
          .box-img {
            margin-top: 0px;
            width: 0.7rem;
            height: 0.7rem;
          }
          .time {
            margin-left: 5px;
          }
        }
        .from {
          margin: 0 15px;
        }
        .readnum {
          margin: 0 15px;
        }
        }
    }
    .line{
      width: 100%;
    }
    .detail-img-box{
      width: 100%;
      height: 200px;
      .detail-img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .all .detail-content{
	  width: 100%;
  }
}
</style>