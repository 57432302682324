<template>
	 <searchIndustry :ArticleTypeId='id' @searchFun="searchFun"></searchIndustry>
	 <div class="block-list text-left row">
      <!-- 头部导航区 -->
		 <div class="block-one col-12 col-md-6 cursorpointer" v-for="(item,index) in list"  @click="gotoDetail(item)">
			 <div class="block-one-sun">
				 <div class="block-head-img">
					<img :src="item.tailorPictureUrl"/>
				 </div> 
				 <div  class="block-btcontent">
					 <div>
						 <div class="block-title">{{item.title}}</div>
						 <div class="block-content">
							 <div v-html="item.synopsis"></div>
						 </div>
					 </div>
					 <div class="block-bottom fx">
						 <div class="block-span-block fx">
							 <div class="block-span-block-button fx-center" v-for="(item,index) in catalogueNamesqg(item.catalogueNames)">{{item}}</div>
						 </div>
						 <div class="block-span-block">
							 <span>{{item.sysContantName}}</span>
						 </div>
					 </div>
				 </div>
			 </div>
		 </div>
	 </div>
	 <div class="page">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div>
</template>

<script>
import {GetHeZuoAnLiApiData} from '@/api/index'
import searchIndustry from '@/components/searchIndustry.vue' 
export default {
  name: "PolicyDetailView",
  components:{
	searchIndustry
  },
  data() {
    return {
		list:[],
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 5,
		  "sysContantId":''
		},
		total:0
    };
  },
  props: {
    id: String,
  },
  mounted() {
  	this.getGetFaLvFaGuiApiData()
  },
  methods:{
	  catalogueNamesqg(val){
		  return val==null || val==''?[]:val.split(',')
	  },
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/resourcecentredetail?id='+item.id+'&pid='+this.id+'&titlename=合作案例')
		}
	},
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.queryParma.currentPage = size
		this.getGetFaLvFaGuiApiData()
	},
	handleCurrentChange (currentPage) {
		this.queryParma.currentPage = currentPage
		this.getGetFaLvFaGuiApiData()
	},
	searchFun(val){
		//console.log(val,'===============w333')
		this.queryParma.sysContantId = val
		this.getGetFaLvFaGuiApiData()
	},
	getGetFaLvFaGuiApiData(){
		GetHeZuoAnLiApiData(this.queryParma).then(res=>{
			if(res.code == 0){
				this.list = res.data
			}else{
				this.list = []
			}
			this.total = res.total
		})
	},
  }
};
</script>

<style lang="less" scoped>
	
	
	.block-list{
		
		.block-one:nth-child(2n+1) .block-one-sun{
			margin-right:1.55rem ;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-left:1.5rem ;
		}
		.block-one{
			.block-one-sun{
				background: #FFFFFF;
				.block-btcontent{
					
					border-radius: 0.2rem;
					margin-bottom: 1.15rem;
					height: 5.7rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
				.block-title{
					font-size: 0.9rem;
					font-weight: 700;
					color: #333333;
					line-height: 0.9rem;
					margin-bottom: 1rem;
				}
				.block-head-img img{
					width: 100%;
					height: 9.5rem;
					margin-bottom: 1rem;
				}
				.block-content{
					font-size: 0.7rem;
					color: #666666;
					line-height: 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.block-bottom{
					display: flex;
					justify-content: space-between;
					.block-span-block{
						
					}
					span{
						font-size: 0.8rem;
						color: #0C2F66;
						line-height: 1.1rem;
					}
					.block-span-block-lable{
						font-size: 0.8rem;
						color: #0C2F66;
						line-height: 1.1rem;
					}
					.block-span-block-button{
						background: #0C2F66;
						border-radius: 0.15rem;
						font-size: 0.6rem;
						color: #FFFFFF;
						line-height: 0.85rem;
						padding: 2px 5px;
						margin-right: 5px;
						span{
							font-size: 0.7rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 1rem;
						}
					}
				}
			}
		}
	}
	/**********************************/
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}
	
	@media (max-width: 768px){
		
		// .block-list{
		// 	padding:0 10px;
		// }
		.block-one:nth-child(2n+1) .block-one-sun{
			margin-right:0rem !important;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-left:0rem !important;
		}
	}
</style>