<template>
  <!-- 顶部图片 -->
  <div class="container text-center home-container all-box" v-if="screenWidth>768">
    <div class="all">
      <!-- 头部导航区 -->
      <searchIndustry :ArticleTypeId='4' @searchFun="searchFun" :pageType="1"></searchIndustry>
	  <div class="news-center-parent">
		  <div class="news-content">
		    <div
		      v-for="(item1, i1) in newsList"
		      :key="i1"
		      class="content-box cursorpointer"
		      @click="goPolicyDetail(item1)"
		    >
		      <div class="news-content-box">
  		        <img :src="item1.tailorPictureUrl" class="content-box-img" />
		        <div class="img-top"  v-if="item1.isTop == 1">
		          <img src="../assets/public/star.png" class="star" />
		          <div class="img-top-text">置顶</div>
		        </div>
		      </div>
		      <div class="content-text">
		        <span class="text-title">{{ item1.title }}</span>
		        <div class="text">{{ item1.synopsis }}</div>
		        <div class="from">
		          <div class="from-time">
		            <img
		              src="../assets/policy/icon-time.png"
		              class="time-img"
  		            /><span class="time">{{ item1.releaseTime.split('T')[0] }}</span>
  		          </div>
  		          <div class="from-text">来源：{{ item1.source }}</div>
		        </div>
		      </div>
		    </div>
		  </div>
		  <!-- 右侧搜索区 -->
		  <div class="search">
		    <div class="hot">
		      <div class="hot-top">
		        <img src="../assets/policy/fire.png" class="hot-img" /><span
		          class="hot-text"
		          >最热资讯</span
		        >
		      </div>
		      <PolicyRightView></PolicyRightView>
		    </div>
		  </div>
	  </div>
      <!-- 中间信息区 -->
     
      <!-- 底部分页 -->
      <div class="page">
        <el-pagination
          small
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          class="mt-4"
		  :page-size='BoutiqueSerch.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
  <div class="container text-center home-container all-box" v-else>
    <div class="all">
      <!-- 头部导航区 -->
  	  <div class="news-center-parent">
		  <searchIndustry :ArticleTypeId='4' @searchFun="searchFun"></searchIndustry>
  		  <div class="news-content cursorpointer">
  		    <div
  		      v-for="(item1, i1) in newsList"
  		      :key="i1"
  		      class="content-box"
  		      @click="goPolicyDetail(item1)"
  		    >
  		      <div class="news-content-box">
  		        <img :src="item1.tailorPictureUrl" class="content-box-img" />
  		        <div class="img-top" v-if="item1.isTop == 1">
  		          <img src="../assets/public/star.png" class="star" />
  		          <div class="img-top-text">置顶</div>
  		        </div>
  		      </div>
  		      <div class="content-text">
  		        <span class="text-title">{{ item1.title }}</span>
  		        <div class="text">{{ item1.synopsis }}</div>
  		        <div class="from">
  		          <div class="from-time">
  		            <img
  		              src="../assets/policy/icon-time.png"
  		              class="time-img"
  		            /><span class="time">{{ item1.releaseTime.split('T')[0] }}</span>
  		          </div>
  		          <div class="from-text">来源：{{ item1.source }}</div>
  		        </div>
  		      </div>
  		    </div>
  		  </div>
  	  </div>
      <!-- 中间信息区 -->
     
      <!-- 底部分页 -->
      <div class="page">
        <el-pagination
          small
          background
          layout="prev, pager, next"
          prev-text="上一页"
          next-text="下一页"
          :total="total"
          class="mt-4"
		  :page-size='BoutiqueSerch.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {WebSiteNews,TrainingPlanGetNavTagList} from '@/api/index'
import PolicyRightView from "@/views/PolicyRightView.vue";
import searchIndustry from '@/components/searchIndustry.vue' 
export default {
  name: "PolicyView",
  components: {
    PolicyRightView,
	searchIndustry
  },
  data() {
    return {
	  trainingPlanSerchList:[],
	  serchData:{
		'行业':{
			name:'sysContanstIdInput',
			icon:require('@/assets/icons/icon-hangye.png')
		},
		'月份':{
			name:'timeTypeInput',
			icon:require('@/assets/icons/month-ico.png')
		},
		'状态':{
			name:'stateInput',
			icon:require('@/assets/icons/status-ico.png')
		},
	  },
	  BoutiqueSerch:{
		"currentPage": 1,
		"pageSize": 8,
		"articleTypeId":'4',
		"sysContanstIdInput":""
	  },
      navListIndex: 0,
	  total:0,
      titleList: [
        
      ],
      newsList: [
      ],
	  screenWidth:0
    };
  },
  mounted() {
	this.getNewsList()
    this.screenWidth = document.body.clientWidth
 
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
  },
  methods: {
	searchFun(val){
		this.BoutiqueSerch.sysContanstIdInput = val
		this.getNewsList()
	},
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.BoutiqueSerch.currentPage = size
		  this.getNewsList()
	},
	handleCurrentChange (currentPage) {
	  this.BoutiqueSerch.currentPage = currentPage
	  this.getNewsList()
	},
	// 获取新闻数据
	getNewsList(){
		WebSiteNews(this.BoutiqueSerch).then(res=>{
			if(res.code == 0 && res.data.length>0){
				this.newsList=res.data
			}else{
				this.newsList=[]
			}
			this.total = res.total
			//console.log(this.newsList,'=================res')
		})
	},
	gotoDetail(id){
		this.$router.push('/industrydetail?id='+id)
	},
    navSelect(index) {
      this.navListIndex = index;
	  this.BoutiqueSerch.sysContanstIdInput = this.titleList[index].id
	  this.getNewsList()
    },
    goPolicyDetail(item) {
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/policydetail?id='+item.id);
		}
    },
  },
};
</script>

<style lang="less" scoped>
.nav-select {
  border: 0.05rem solid #014f93 !important;
  color: #014f93;
}
.all {
  // position: relative;
  .news-center-parent{
	  margin-top: 1.8rem;
	  display: flex;
	  justify-content: space-between;
  }
  .top {
    text-align: left;
    display: flex;
    font-size: 0.7rem;
    margin-top: 1.5rem;
    height: 48px;
    justify-content: left;
    align-items: center;
    .title {
		min-width: 60px;
      img {
        width: 14px;
        height: 14px;
        margin-top: -2px;
      }
      span {
        margin-left: 0.4rem;
      }
    }
    .title-list {
      ul {
        display: flex;
		flex-wrap: wrap;
        li {
		  margin-bottom: 5px;
          list-style-type: none;
          margin-left: 0.5rem;
          height: 1.4rem;
          line-height: 1.4rem;
          text-align: center;
          border-radius: 0.2rem;
          border: 0.05rem solid #dfe3e5;
          padding: 0 0.55rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
  .news-content {
    text-align: left;
    .content-box {
      margin-bottom: 1.5rem;
      display: flex;
      &:hover {
        cursor: pointer;
      }
      .news-content-box {
        position: relative;
        .content-box-img {
          width: 14rem;
          height: 7.3rem;
        }
        .img-top {
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          width: 2.9rem;
          height: 1.2rem;
          background-color: #006aff;
          display: flex;
          justify-content: center;
          align-items: center;
          .star {
            width: 0.7rem;
            height: 0.7rem;
          }
          .img-top-text {
            font-size: 0.7rem;
            color: #ffffff;
            margin-left: 0.15rem;
          }
        }
      }
      .content-text {
        margin-left: 1rem;
        margin-top: -5px;
        .text-title {
          width: 28.15rem;
          // height: 1.1rem;
          font-size: 1.1rem;
          font-weight: bold;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .text {
          margin-top: 1.1rem;
          width: 25.2rem;
          font-size: 0.7rem;
          color: #999999;
          width: 25.2rem;
          height: 1.9rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .from {
          font-size: 0.7rem;
          color: #666666;
          display: flex;
          margin-top: 1.5rem;
          .from-time {
            .time-img {
              width: 0.7rem;
              height: 0.7rem;
              margin-top: -3px;
            }
            .time {
              margin-left: 0.45rem;
            }
          }
          .from-text{
            margin-left: 2rem;
			max-width: 360px;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
          }
        }
      }
    }
  }
  .search {
    // top: 5rem;
    // left: 50.65rem;
    width: 21rem;
    .search-news {
      text-align: left;
      .news-img {
        width: 1.1rem;
        height: 1.1rem;
      }
      .news-text {
        margin-left: 0.6rem;
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
    .search-input {
      margin-top: 1.3rem;
      display: flex;
      height: 2.25rem;
      .el-input.inp {
        width: 15.1rem;
        height: 2.2rem;
        display: flex;
        
        .el-input__wrapper{
          border-bottom-right-radius: 0 !important;
          border-top-right-radius: 0 !important;
        }
      }
      .btn {
        width: 5.55rem;
        height: 2.25rem;
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }
    }
    .crumbs {
      margin-top: 0.6rem;
      font-size: 0.7rem;
      color: #1483bf;
      width: 17.15rem;
      height: 1rem;
      ul {
        display: flex;
        li {
          position: relative;
          list-style-type: none;
          padding: 0 0.39rem;
          &::after {
            content: "|";
            display: block;
            position: absolute;
            right: 0;
            top: 0;
          }
        }
      }
    }
  }
  .hot {
    text-align: left;
    margin-top: 0rem;
    .hot-top {
      .hot-img {
        width: 0.9rem;
        height: 1.1rem;
      }
      .hot-text {
        margin-left: 0.7rem;
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }
  .page {
    margin: 5.25rem 0;
    display: flex;
    justify-content: center;
    align-items: end;
    ::v-deep .el-pagination.is-background .el-pager li {
      width: 1.1rem;
      height: 1.1rem;
      background-color: #ffffff;
      color: #999999;
      border: 1px solid #d9d9d9;
      &.is-active {
        background-color: #0c2f66;
        color: #ffffff;
      }
    }
    ::v-deep .el-pagination.is-background .btn-prev,
    ::v-deep .btn-next.is-last {
      width: 2.8rem;
      height: 1.5rem;
      background-color: #0c2f66;
      color: #ffffff;
      &:hover {
        background-color: #ffffff;
        color: #0c2f66;
        border: 1px solid #0c2f66;
      }
    }
    ::v-deep .el-pagination.is-background .btn-prev {
      margin-right: 1rem;
    }
    ::v-deep .btn-next.is-last {
      margin-left: 1rem;
    }
  }
}
.top{
	.title{
		min-width: 50px;
		font-weight: 700;
	}
}
.all .top .title-list ul{
  flex-wrap: wrap;
	padding-left: 0;
	margin-bottom: 0;
}
.all .top .title-list ul li{
  margin-bottom: 0px;
}
@media (max-width: 768px) {
  .selectplan {
    margin-top: 15px;
  }
  .all {
	.news-center-parent{
	  margin-top: 1.8rem;
	  display: block;
	}
    .top {
      text-align: left;
      display: flex;
      font-size: 0.7rem;
      margin-top: 1.5rem;
      height: 48px;
      justify-content: left;
      align-items: center;
      .title {
        img {
          width: 14px;
          height: 14px;
          margin-top: -2px;
        }
        span {
          margin-left: 0.4rem;
        }
      }
      .title-list {
        ul {
          display: flex;
          li {
            list-style-type: none;
            margin-left: 0.5rem;
            height: 1.4rem;
            line-height: 1.4rem;
            text-align: center;
            border-radius: 0.2rem;
            border: 0.05rem solid #dfe3e5;
            padding: 0 0.55rem;
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
    .news-content {
      margin-top: 20px;
      text-align: left;
      .content-box {
		margin-top: 1.5rem;
		display: block;
		padding-bottom: 10px;
		border-bottom: 1px solid #ccccccab;
        &:hover {
          cursor: pointer;
        }
        .news-content-box {
          margin: 10px;
          .content-box-img {
			width: 100%;
			height: auto;
          }
          .img-top {
            position: absolute;
            top: 0.5rem;
            left: 0.5rem;
            width: 2.9rem;
            height: 1.2rem;
            background-color: #006aff;
            display: flex;
            justify-content: center;
            align-items: center;
            .star {
              width: 0.7rem;
              height: 0.7rem;
            }
            .img-top-text {
              font-size: 0.7rem;
              color: #ffffff;
              margin-left: 0.15rem;
            }
          }
        }
        .content-text {
          // margin-left: 10px;
          margin-top: -5px;
		  overflow: hidden;
          .text-title {
            font-size:14px;
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: block;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
			width: 100%;
          }
          .text {
            margin-top: 10px;
            font-size: 0.7rem;
            color: #999999;
            height: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
			width: 100%;
          }
          .from {
            font-size: 0.7rem;
            color: #666666;
            display: flex;
            margin-top: 10px;
            .from-time {
              .time-img {
                width: 0.7rem;
                height: 0.7rem;
                margin-top: -3px;
              }
              .time {
                margin-left: 0.45rem;
              }
            }
            .from-text{
				margin-left: 2rem;
				max-width: 360px;
				display: block;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
            }
          }
        }
      }
    }
    .search {
      
      width: 100%;
      .search-news {
        text-align: left;
        .news-img {
          width: 1.1rem;
          height: 1.1rem;
        }
        .news-text {
          margin-left: 0.6rem;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
      .search-input {
        margin-top: 1.3rem;
        display: flex;
        height: 2.25rem;
        .el-input.inp {
          width: 15.1rem;
          height: 2.2rem;
          display: flex;
          
          .el-input__wrapper{
            border-bottom-right-radius: 0 !important;
            border-top-right-radius: 0 !important;
          }
        }
        .btn {
          width: 5.55rem;
          height: 2.25rem;
          border-bottom-left-radius: 0;
          border-top-left-radius: 0;
        }
      }
      .crumbs {
        margin-top: 0.6rem;
        font-size: 0.7rem;
        color: #1483bf;
        width: 100%;
        // height: 1rem;
        ul {
          display: flex;
          li {
            position: relative;
            list-style-type: none;
            padding: 0 10px;
            &::after {
              content: "|";
              display: block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
    .hot {
      text-align: left;
      margin-top: 15px;
      .hot-top {
        .hot-img {
          width: 0.9rem;
          height: 1.1rem;
        }
        .hot-text {
          margin-left: 0.7rem;
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }
    .page {
      margin: 5.25rem 0;
      display: flex;
      justify-content: center;
      align-items: end;
      ::v-deep .el-pagination.is-background .el-pager li {
        width: 1.1rem;
        height: 1.1rem;
        background-color: #ffffff;
        color: #999999;
        border: 1px solid #d9d9d9;
        &.is-active {
          background-color: #0c2f66;
          color: #ffffff;
        }
      }
      ::v-deep .el-pagination.is-background .btn-prev,
      ::v-deep .btn-next.is-last {
        width: 2.8rem;
        height: 1.5rem;
        background-color: #0c2f66;
        color: #ffffff;
        &:hover {
          background-color: #ffffff;
          color: #0c2f66;
          border: 1px solid #0c2f66;
        }
      }
      ::v-deep .el-pagination.is-background .btn-prev {
        margin-right: 1rem;
      }
      ::v-deep .btn-next.is-last {
        margin-left: 1rem;
      }
    }
  }
  
  .all .top .title-list ul{
	  flex-wrap: wrap;
		padding-left: 0;
		margin-bottom: 0;
  }
  .all .top .title-list ul li{
	  margin-bottom: 5px;
  }
  .all .top{
	  align-items: flex-start;
		height: auto;
  }
  .top-bigbg {
    height: 200px;
    .bigbg-box {
      position: relative;
      padding-top: 70px !important;
      padding-left: 70px !important;
      .bigbg-text {
        font-size: 25px;
      }
      .text-eng {
        font-size: 20px;
        letter-spacing: 1px;
      }
      .box-line {
        margin-top: 20px;
      }
    }
  }
  .plannav{
    margin-right: 10px;
  }
  .navigation-content .navigation .nav-titlelist ul{
	  
  }
  .navigation-content{
	  margin-bottom: 15px;
    .navigation{
		align-items: flex-start;
      .img-box{
      width: 60px;
      display: flex;
      align-items: center;
      img{

      }
      span{
        width: 40px;
        margin-left: 5px;
      }
      }
    .nav-titlelist{
      align-items: center;
	  height: auto;
      ul{
		flex-wrap: wrap;
		padding-left: 0rem !important;
        li{
          text-align: center;
          display: block;
          white-space: nowrap;
		  margin-bottom: 5px;
        }
      }
    }
    }
  }
 }
</style>