<template>
  <div class="hot-content" v-for="(item2, i2) in hotList" :key="i2"  @click="goPolicyDetail(item2)">
	<img :src="item2.tailorPictureUrl" class="content-img" />
    <div class="hot-box">
      <div class="hot-title">
        {{ item2.title }}
      </div>
      <div class="hot-time">
        <div>
          <img src="../assets/policy/icon-time.png" alt="" /><span
            class="time-text"
            >{{ item2.createTime.split('T')[0] }}</span
          >
        </div>
        <div class="yclaiyuan">来源：{{ item2.source }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {GetPolicyInformationDto} from '@/api/index'
export default {
  name: "PolicyRightView",
  data() {
    return {
      hotList: [
      ],
    };
  },
  mounted() {
  	this.getGetPolicyInformationDto()
  },
  methods:{
	getGetPolicyInformationDto(){
		GetPolicyInformationDto({ArticleTypeId:'4'}).then(res=>{
			
				this.hotList=res
			
		})
	},
    goPolicyDetail(item) {
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/policydetail?id='+item.id);
		}
    },
  },
  
};
</script>

<style lang="less" scoped>
.hot-content {
  margin-top: 1.1rem;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .content-img {
    width: 8.45rem;
    height: 4.4rem;
  }
  .hot-box {
    margin-left: 1rem;
    .hot-title {
      text-align: left;
      width: 11.3rem;
      height: 2rem;
      font-size: 0.7rem;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .hot-time {
      margin-top: 1.1rem;
      display: flex;
      font-size: 0.6rem;
      justify-content: space-between;
      .time-text {
        margin-left: 0.55rem;
      }
	  .yclaiyuan{
		  max-width: 117px;
		display: block;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

	  }
    }
  }
}

@media (max-width: 768px) {
	
	.hot-title {
		width: 100% !important;
		height: auto !important;
		
	}
}
</style>