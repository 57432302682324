<template>
  <!-- 顶部图片 -->
  <div class="container text-center home-container all-box mg-tp-content">
	<div class="row">
		<div class="col-6 col-md-3" v-for="(item,index) in blockOneList" v-bind:key="index" @click="gotoDetail(item.id)">
			<div class="bock-one">
				<img :src="item.tailorPictureUrl">
				<div class="bock-mode">
					<div class="mode-cn">{{item.title}}</div>
					<div class="mode-en">{{item.enName}}</div>
					<div class="mode-info">查看详细</div>
				</div>
			</div>
		</div>
	</div>
  </div>
</template>

<script>
import {WebSiteNews} from '@/api/index'
export default {
  name: "PolicyView",
  data() {
    return {
	  screenWidth:0,
	  blockOneList:[]
    };
  },
  mounted() {
    this.screenWidth = document.body.clientWidth
    window.onresize = () => {
      return (() => {
        this.screenWidth = document.body.clientWidth
      })()
    }
	this.getNewsList()
  },
  methods: {
    // 获取新闻数据
    getNewsList(){
    	WebSiteNews({articleTypeId:"3"}).then(res=>{
			//console.log(res.data,'=================res')
    		if(res.code == 0 && res.data.length>0){
    			this.blockOneList=res.data
    		}
    	})
    },
	gotoDetail(id){
		
		this.$router.push('/industrydetail?id='+id)
	}
  },
};
</script>
<style lang="less" scoped>
	.mg-tp-content{
		margin-top: 2rem;
		min-height: 1000px;
	}
	.bock-one{
		margin: 6.5px;
		margin-bottom: 2rem;
	}
	.bock-one img{
		width: 100%;
		height: 10rem;
		// object-fit: contain;
		border-radius: 6px;
	}
	.bock-one .bock-mode{
		width: 15.25rem;
		height: 5.05rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.3rem 0.75rem -0.05rem rgba(0,0,0,0.06);
		border-radius: 0.2rem;
		text-align: center;
		margin: 0 auto;
		margin-top: -1.5rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.bock-one .bock-mode .mode-cn{
		font-size: 1rem;
		font-weight: 700;
		color: #333333;
		line-height: 1.4rem;
	}
	.bock-one .bock-mode .mode-en{
		font-size: 0.6rem;
		color: #333333;
		line-height: 0.85rem;
	}
	.bock-one .bock-mode .mode-info{
		margin-top: 0.4rem;
		font-size: 0.6rem;
		color: #014F93;
		line-height: 0.85rem;
	}
	
	@media (max-width: 768px) {
		
		.bock-one .bock-mode{
			width: 90%;			
			height: 90px;

		}
		
		.bock-one .bock-mode .mode-cn{
			font-size: 14px;
			line-height: 20px;
		}
		.bock-one .bock-mode .mode-en{
			font-size:8px;
			line-height:15px;
		}
		.bock-one{
			margin-bottom: 15px;
		}
		.bock-one img{
			height: 107px;
		}
	}
</style>