<template>
	<div class="container text-center home-container">
		<!-- 面包屑开始 -->
		<!-- 面包屑结束 -->
		<div class="row">
			<div class="col-md-2 ">
				<div v-for="(item,index) in blockOneList" class="left-block-one fx-center" :class="id==item.id || ArticleTypeId==item.articleTypeId?'left-block-one-sel':''" @click="gotoDetail('/industrydetail?id='+item.id)">{{item.title}}</div>
			</div>
			<div class="col-md-10">
				<div class="breadcrumbs">
					<router-link to="/industry">行业</router-link> > {{info.title}}
				</div>
				<div class="tab-title-block mg-top" >
					<span  class="tab-title-block-select2" >
						{{info.title}}
						<div class="tab-title-block-select-line"></div>
					</span>
				</div>
				<div class="fx-justify-space-between xiangqing">
					<div class="rich-text ">
						<div v-html="info.content" class="rich-text-html"></div>
					</div>
					<div class="butterfly">
						<!-- <img :src="info.editBannerUrl" /> -->
						<div class="tab-title-block" >
							<span  class="tab-title-block-select2 tab-title-block-select33" >
								业务咨询
								<div class="tab-title-block-select-line"></div>
							</span>
						</div>
						<div class="maskthree-content-bottom row mg-tp-15rem">
							<div class="col-12">
								<img src="../assets/icons/icon-persone.png">
								<span>联系人：<span>{{info.contact}}</span></span>
							</div>
							<div class="col-12">
								<img src="../assets/icons/icon-phone.png">
								<span>联系方式：<span>{{info.contactWay}}</span></span>
							</div>
							<div class="col-12" v-if="info.contactEmail!=null && info.contactEmail!='' && info.contactEmail!=undefined">
								<img src="../assets/icons/icon-email.png">
								<span>联系邮箱：<span>{{info.contactEmail}}</span></span>
							</div>
							<div class="col-12" v-if="info.contactAddress!=null && info.contactAddress!='' && info.contactAddress!=undefined">
								<img src="../assets/icons/icon-location.png">
								<span>联系地址：<span>{{info.contactAddress}}</span></span>
							</div>
						</div>
						<div class="tab-title-block mg-top"  v-if="info.applicationName!=null">
							<span  class="tab-title-block-select2 tab-title-block-select33" >
								申请表
								<div class="tab-title-block-select-line"></div>
							</span>
						</div>
						<div class="shqnqingbiao row mg-tp-15rem" v-if="info.applicationName!=null">
							<div class="col-12 fx-justify-content">
								<span>{{info.applicationName }}</span>
								<button @click="dowload(info.applicationDownload,info.applicationName)">下载</button>
							</div>
						</div>
					</div>
				</div>
				<div class="tab-title-block"  >
					<div  v-for="(item,index) in tabOneList" @click="tabOneClick(index)" >
						<span :class="tabOneIndex==index?'tab-title-block-select2':''" class="tab-title-block-select-line" v-if="info[item.fileds]!=undefined && info[item.fileds].length>0 ">
							<div v-if="info[item.fileds]!=undefined && info[item.fileds].length>0 ">{{item.title}}</div>
							<div class="tab-title-block-select-line" v-if="info[item.fileds]!=undefined && info[item.fileds].length>0 "></div>
						</span>
					</div>
				</div>
				<div class=" tab-title grid-2 grid-md-3" v-if="info.introductionServerOutList!=undefined && info.introductionServerOutList.length>0 && tabOneIndex==0">
					<div v-for="(item, index) in   info.introductionServerOutList " :key="index" class="industry"  style="overflow: hidden;border-radius: 8px;" @click="gotoDetail('/servicedetail?id='+item.id)">
						<div style="overflow: hidden; width: 100%;border-radius: 8px;">
							<img :src="item.picturlUrl" class="img-hover" />
						</div>
						<div class="tab-title-content" style="padding-top: 1rem;border-radius: 8px;">
							<div class="tab-title-title" style="font-size: 1rem;">
								<div class="tab-title-title-line"></div>{{ item.title }}
							</div>
							<div class="tab-title-titleen">{{ item.enName }}</div>
							<!-- <div class="tab-title-desc">{{ item.desc }}<span>了解详情</span></div> -->
						</div>
					</div>
				</div>
				<div class="grid-2 grid-md-3 grid-gl-5 tab-title" v-if="info.starndList!=undefined && info.starndList.length>0 && tabOneIndex==1">
					<div v-for="(item, index) in   info.starndList " :key="index" class="industry"  style="overflow: hidden;border-radius: 8px;"  @click="gotoDetail('/standard?id='+item.id)">
						<div style="overflow: hidden; width: 100%;border-radius: 8px;">
							<img :src="item.tailorPictureUrl" class="img-hover" />
						</div>
						<div class="tab-title-content" style="padding-top: 1rem;border-radius: 8px;">
							<div class="tab-title-title">
								<div class="tab-title-title-line"></div>{{ item.name }}
							</div>
							<div class="tab-title-titleen">{{ item.enName }}</div>
							<!-- <div class="tab-title-desc">{{ item.desc }}<span>了解详情</span></div> -->
						</div>
					</div>
				</div>
				<div class="grid-2 grid-md-3 grid-gl-5 hangyeyanjiu-list" v-if="info.industryResearch!=undefined && info.industryResearch.length>0 && tabOneIndex==2">
					<div v-for="(item, index) in   info.industryResearch " :key="index" class="industry cursorpointer"  style="overflow: hidden;border-radius: 8px;"   @click="gotoWB(item.toUrl)">
						<div style="overflow: hidden; width: 100%;border-radius: 8px;">
							<img :src="item.tailorPictureUrl" class="img-hover" />
						</div>
						<div class="hangyeyanjiu-list-title" style="padding-top: 1rem;border-radius: 8px;">
							{{ item.title }}
							<!-- <div class="tab-title-desc">{{ item.desc }}<span>了解详情</span></div> -->
						</div>
					</div>
				</div>
				
				<div class="grid-2 grid-md-3 trainingplan" v-if="info.introductionTranOutList!=undefined && info.introductionTranOutList.length>0 && tabOneIndex==3">
				  <div v-for="(  item, index  ) in   info.introductionTranOutList " :key="index" class="planbox" @click="gotoDetail('/trainingdetail?id='+item.id)">
				      <div class="theTopbox">
						<img :src="item.picturlUrlCaijian" class="planbox-img img-hover"/>
				       <!-- <div class="theTop" v-if="item.topPostion==1">
				          <img src="../assets/public/star.png" alt="" class="theTop-star">
				          <div class="theTop-text">置顶</div>
				        </div> -->
				      </div>
				      <div class="plancontent">
				        <div class="plantitle">{{ item.trainingPlanName }}</div>
				        <div class="planstatus">
				          <div class="status-box" v-if="item.stopCnName=='报名中'">
				            <img src="../assets/training/begin.png" alt="">
				            <span class="status-text begin">报名中</span>
				          </div>
				          <div class="status-box" v-if="item.stopCnName=='进行中'">
				            <img src="../assets/training/begining.png" alt="">
				            <span class="status-text begining">进行中</span>
				          </div>
				          <div class="status-box" v-if="item.stopCnName=='已结束'">
				            <img src="../assets/training/begining.png" alt="">
				            <span class="status-text over">已结束</span>
				          </div>
				          <div class="plantime-box">
				            <span>{{ item.startTimeCn }}</span>
				            <div class="planbox-line"></div>
				            <span>{{ item.city }}</span>
				          </div>
				        </div>
				      </div>
				  </div>
				</div>
				
				<div class="tab-title-block"  >
					<div  v-for="(item,index) in tabTwoList" @click="tabTwoClick(index)" >
						<span :class="tabTwoIndex==index?'tab-title-block-select2':''" class="tab-title-block-select-line" v-if="info[item.fileds]!=undefined && info[item.fileds].length>0 ">
							<div >{{item.title}}</div>
							<div class="tab-title-block-select-line"></div>
						</span>
					</div>
				</div>
				<div class="grid-2 yxanli tab-title" v-if="info.industryList!=undefined && info.industryList.length>0 && tabTwoIndex==1">
					<div v-for="(item, index) in   info.industryList " :key="index" class="yxanli-block"    @click="gotoDetail('/datadetail?id='+item.id)">
						<div class="six-bock">
							<img :src="item.tailorPictureUrl" class="img-hover" />
							<div class="six-bock-left">
								<div class="six-bock-left-title">{{item.title}}</div>
								<div class="six-bock-left-desc">{{item.synopsis}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="row tab-title-five" v-if="info.partnerList!=undefined && info.partnerList.length>0 && tabTwoIndex==0">
					
					<div  v-for="(  item, index  ) in  info.partnerList  " :key="index"  @click="gotoWB(item.toUrl)">
						<img :src="item.pictureUrl" />
					</div>
				</div> 
			</div>
		</div>
	</div>
	
	
	<div class="alert-maskthree" v-if="isAlertMasktwo">
		<div class="alert-maskthree-content">
			<div class="maskthree-header">
				<span>业务资讯</span>
				<img src="../assets/icons/icon-close-two.png" class="icon-close cursorpointer" @click="closeAlerttwo()"/>
			</div>
			<div class="maskthree-content"  >
				<div class="maskthree-content-bottom row">
					<div class="col-12">
						<img src="../assets/icons/icon-persone.png">
						<span>联系人：<span>{{info.contact}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-phone.png">
						<span>联系方式：<span>{{info.contactWay}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-email.png">
						<span>联系邮箱：<span>{{info.contactEmail}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-location.png">
						<span>联系地址：<span>{{info.contactAddress}}</span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {GetWebSiteGetDetails,WebSiteNews,GetIndustryResearch} from '@/api/index'
export default {
  name: "PolicyDetailView",
  data() {
    return {
		isAlertMasktwo:false,
		id:'',
		info:{},
		blockOneList:[],
		blockOneIndex:0,
		ArticleTypeId:'',
		tabOneList:[
			{
				title:'业务介绍',
				fileds:'introductionServerOutList'
			},
			{
				title:'标准介绍',
				fileds:'starndList'
			},
			{
				title:'行业研究',
				fileds:'industryResearch'
			},
			{
				title:'相关培训',
				fileds:'introductionTranOutList'
			}
		],
		tabOneIndex:0,
		tabTwoList:[
			{
				title:'合作伙伴',
				fileds:'partnerList'
			},
			{
				title:'合作案例',
				fileds:'industryList'
			},
		],
		tabTwoIndex:0,
    };
  },
	watch: {
		$route: {
			handler(to, from) {
				
				if(to.query.type==1){
					this.id = ''
					this.ArticleTypeId = to.query.id
				}else{
					this.id = to.query.id
					this.ArticleTypeId = ''
				}
				this.getNewsInfo()
			},
		},
		deep: true,
	},
  mounted() {
	if(this.$route.query.type==1){
		this.id = ''
		this.ArticleTypeId = this.$route.query.id
	}else{
		this.id = this.$route.query.id
		this.ArticleTypeId = ''
	}
	this.getNewsList()
	this.getNewsInfo()
  },
  methods:{
	gotoWB(url){
		if(url==undefined){
			return
		}
		window.open(url)
	},
	tabOneClick(index){
		this.tabOneIndex = index
	},
	tabTwoClick(index){
		this.tabTwoIndex = index
	},
	// 下载 pdf
	dowload(file, title) {
		// window.open(pdfUrl);
		// 查看点击事件后传递过来的数据
		//console.log(file, '下载文件事件');
		// 创建 a 标签
		const a = document.createElement('a');
		// 创建一个点击事件
		const event = new MouseEvent('click');
		// 将 a 的 download 属性设置为我们想要下载的图片的名称，若 name 不存在则使用'图片'作为默认名称
		a.download = title || '文件名称';
		// 将生成的 url 设置为 a.href 属性 （file.downloadUrl 属性也是一个链接，是一个自定义的链接）
		a.href = file;
		// 打开新的窗口（如果是一个图片类型的文件，在浏览器打开新的标签页打开）
		a.target = '_blank';
		// 实现触发生成的 a 标签
		a.dispatchEvent(event);
	},
	gotoWB(url){
		if(url==undefined){
			return
		}
		window.open(url)
	},
	closeAlerttwo(){
		this.isAlertMasktwo = false
	},
	openAlerttwo(){
		this.isAlertMasktwo = true
	},
	// 获取新闻数据
	getNewsList(){
		WebSiteNews({articleTypeId:"3"}).then(res=>{
			if(res.code == 0 && res.data.length>0){
				this.blockOneList=res.data
			}
		})
	},
	gotoDetail(url){
		
		this.$router.push(url)
	},
	// 获取新闻详情
	getNewsInfo(){
		GetWebSiteGetDetails({id:this.id,ArticleTypeId:this.ArticleTypeId}).then(res=>{
			this.info = res
			this.ArticleTypeId = res.articleTypeId
			this.getGetIndustryResearch()
		})
	},
	// 获取新闻详情
	getGetIndustryResearch(){
		GetIndustryResearch({IndustryType:1,ArticleTypeId:this.ArticleTypeId}).then(res=>{
			this.info.industryResearch = res.data
			this.setIndex()
		})
	},
	setIndex(){
		let that = this
		let info = this.info
		let tabOneList = this.tabOneList
		let tabTwoList = this.tabTwoList
		let oneArr = []
		let twoArr = []
		tabOneList.forEach(function(e,index){
			if(info[e.fileds]!=undefined && info[e.fileds].length>0){
				oneArr.push(index)
			}
			
		})
		this.tabOneIndex = oneArr[0]==undefined?0:oneArr[0]

		tabTwoList.forEach(function(e,index){
			if(info[e.fileds]!=undefined && info[e.fileds].length>0){
				twoArr.push(index)
			}
			
		})
		this.tabTwoIndex = twoArr[0]==undefined?0:twoArr[0]
		//console.log(tabOneList,'=============tabOneListtabOneListtabOneList')
	}
	
  }
};
</script>

<style lang="less" scoped>
	.tab-title-block{
		display: flex;
		flex-wrap: wrap;
	}
	.industry>div>img{
		width: 100%;
	}
	.maskthree-content-bottom>div>span{
		text-align: left;
	}
	.hangyeyanjiu-list-title{
		
		color: #141414;
		line-height: 1.5rem;
		font-weight: 600;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
		overflow: hidden;
		text-align: left;
		margin-bottom: 1rem;
	}
	.left-block-one{
		width: 9rem;
		/* height: 3rem; */
		background: #EFF1F6;
		border-radius: 1.5rem;
		font-size: 1rem;
		color: #333333;
		line-height: 1.3rem;
		margin: 0.7rem 0;
		cursor: pointer;
		padding: 0.8rem 10px;
	}
	.left-block-one-sel{
		color: #FFFFFF;
		background: #0C2F66;
		border-radius: 1.5rem;
	}
	.container {
		padding-bottom: 50px;
	}
	.tab-title-five{
		div{
			display: inline-block;
			margin: 0 3rem !important;
			width:10rem;
			img{
				width: 100%;
			}
		}
		div:nth-child(4n+1){
			margin-left: 0rem !important;
		}
		div:nth-child(4n+4){
			margin-right: 0 !important;
		}
	}
	.trainingplan{
	  margin-top: 1rem;
	  // margin-bottom: 9.7rem;
	  .planbox{
	  // width: 16.65rem;
	  height: 15rem;
	  // padding: 0.5rem !important;
	  margin-bottom: 1.2rem !important;
	  &:hover{
	      cursor: pointer;
	    }
	  .theTopbox{
	    position: relative;
	    .planbox-img{
	      width: 100%;
	      height: 8.6rem;
		  border-top-left-radius: 0.6rem;
		  border-top-right-radius: 0.6rem;
	    }
	    .theTop{
	      border-radius: 0.05rem;
	      position: absolute;
	      left: 0.75rem;
	      top: 0.7rem;
	      width: 2.9rem;
	      height: 1.2rem;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      background-color: #006AFF;
	      color: #FFFFFF;
	      font-size: 0.7rem;
	      .theTop-star{
	        width: 0.7rem;
	        height: 0.7rem;
	        margin-top: -2px;
	      }
	      .theTop-text{
	        margin-left: 0.2rem;
	      }
	    }
	  }
	  .plancontent{
	    border-bottom-left-radius: 0.6rem;
	    border-bottom-right-radius: 0.6rem;
	    border: 0.05rem solid #D8D8D8;
	    height: 6.4rem;
	    .plantitle{
	      font-size: 0.9rem;
	      font-weight: bold;
	      margin-top: 0.95rem;
	      margin-left: 1rem;
	      margin-right: 1rem;
	      text-align: left;
			display: block;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
	    }
	    .planstatus{
	      display: flex;
	      justify-content: space-between;
	      align-items: center;
	      padding: 0 1rem;
	      margin-top: 1.8rem;
	      .status-box{
	        .status-text{
	          font-size: 0.8rem;
	          margin-left: 0.4rem;
	          font-weight: bold;
	          &.begin{
	            color: #18CD04;
	          }
	          &.begining{
	            color: #FFAF4A;
	          }
	          &.over{
	            color: #8C8C8C;
	          }
	        }
	      }
	      .plantime-box{
	        display: flex;
	        font-size: 0.7rem;
	        color: #333333;
	        .planbox-line{
	          width: 0.05rem;
	          height: 0.7rem;
	          background: #333333;
	          border-radius: 0.03rem;
	          margin: 0 0.5rem;
	          margin-top: 4px;
	        }
	      }
	    }
	  }
	}
	}
	
	.yxanli{
		align-items: center;
		justify-content: center;
		flex-wrap: wrap;
		justify-content: space-between;
		display: flex;
		.yxanli-block{
			width: 49%;
			padding: 0px !important;
		}
	}
	.six-bock{
		text-align: left;
		// margin-bottom: 1.7rem;
		width: 100%;
		img{
			width: 100%;
			height:9.5rem ;
			// margin-right: 1.2rem;
			margin-bottom: 1rem;
		}
		.six-bock-left{
			width: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			.six-bock-left-title{
				width: 100%;
				color: #141414;
				line-height: 1.5rem;
				font-weight: 600;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 1;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
			.six-bock-left-desc{
				margin-top: 0.8rem;
				width: 100%;
				font-size: 0.8rem;
				color: #666666;
				line-height: 1.1rem;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				height: 70px;
			}
		}
	}
	.rich-text{
		text-align: left;
		width: 100%;
		padding-right: 1.55rem;
		
	}
	.rich-text-html{
		min-height: 276px;
	}
	.rich-button{
		margin-top: 1.6rem;
		width: 7.05rem;
		height: 2.4rem;
		border-radius: 1.2rem;
		border: 0.05rem solid #0B3D8A;
		font-size: 0.8rem;
		color: #0B3D8A;
		line-height: 1.1rem;
		img{
			width: 0.9rem !important;
			height: 0.9rem !important;
			margin-left: 0.6rem;
		}
	}
	.butterfly{
		width: 424px;
		min-height: 442px;
		>img{
			width: 356px;
			height: 356px;
			max-width: 356px !important;
		}
	}
	
	.mg-tp-15rem{
		margin-top: 1rem;
	}
	
	.shqnqingbiao{
		span{
			font-size: 0.8rem;
		}
		button{
			font-size: 0.65rem;
			background: #0C2F66;
			width: 70px;
			height: 30px;
			color: #FFFFFF;
			border-radius: 6px;
		}
	}
	@media (max-width: 768px) {
		.six-bock{
			margin-bottom: 0px !important;
		}
		.col-md-2{
			display: flex;
			flex-wrap: wrap;
			// margin-bottom: 20px !important;
		}
		.left-block-one{
			width: auto;
			height: auto;
			padding: 3px 10px;
			font-size: 12px;
			line-height: normal;
			margin: 4px;
		}
		.col-md-10{
			width: 100%;
		}
		.fx-justify-content{
			// display: block;
		}
		.six-bock img{
			height: auto;
		}
		.six-bock .six-bock-left{
			justify-content: flex-start;
			
		}
		.col-md-2{
			display: flex;
			flex-wrap: wrap;
			// margin-bottom: 20px !important;
		}
		.six-bock .six-bock-left .six-bock-left-desc{
			-webkit-line-clamp: 2;
			height: 37px;
		}
		.butterfly{
			width: 100%;
			min-height: 180px;
			>img{
				width: 100%;
				height: 150px;
				robject-fit: cover;
			}
		}
		.xiangqing{
			display: block;
		}
		.rich-text{
		    text-align: left;
		    width:100%;
		    padding-right: 0px;
			font-size: 12px;
			
		}
		.rich-text-html{
			min-height: 112px;
		}
		.rich-button{
		    margin-top: 10px;
		    width: 100px;
		    height: auto;
		    font-size: 12px;
		    color: #0B3D8A;
		    line-height:20px;
			padding: 3px 0px;
		}
		.six-bock img{
			width: 100%;
			height: auto;
		}
		.six-bock .six-bock-left{
		    width:100%;
		    display: flex;
		    flex-direction: column;
		    justify-content: center;
		}
		.six-bock .six-bock-left .six-bock-left-title{
			width: 98%;
			font-size: 16px;
		}
		.six-bock .six-bock-left .six-bock-left-desc{
			width: 98%;
			font-size: 12px;
		}
		
		.tab-title-five{
			div{
				display: inline-block;
				margin: 0 0rem !important;
				width:25%;
				height: auto !important;
				img{
					width: 100%;
				}
			}
			div:nth-child(4n+1){
				margin-left: 0rem !important;
			}
			div:nth-child(4n+4){
				margin-right: 0 !important;
			}
		}
		.trainingplan{
		  .planbox{
		    margin-bottom: 0px !important;
		    // height: 310px;
			  height: auto;
			  margin-bottom: 10px !important;
		    .theTopbox{
		    .planbox-img{
		      // height: 180px;
				height: 95px;
		    }
		  }
		  .plancontent{
		    height: 90px;
		      .plantitle{
		        font-size: 13px;
		        font-weight: bold;
		        text-align: left;
		        margin: 10px;
		      }
		      .planstatus{
		        padding: 0 10px;
				  display: block;
		        margin-top: 0px;
		        .status-box{
		          display: flex;
		          align-items: center;
		          img{
		            width: 14px;
		            height: 14px;
		          }
		          .status-text{
		            font-size: 14px;
		          }
		        }
		        .plantime-box{
		          font-size: 10px;
					height: auto;
		          .planbox-line{
		            margin-top: 2px;
		          }
		        }
		      }
		    }
		  }
		}
	}
</style>