<template>
	
	<div class="container inner fx-justify-space-between" style="padding-bottom: 20px;">
		<div class="breadcrumbs">
			<span @click="go('/resourcecentre')">资源中心</span> > <span @click="go('/resourcecentre?id=3a0acf0d596265e80d1014e4ed118118&type=1')">数据与报告</span>
		</div>	
	</div>
	<div class="container inner fx-justify-space-between">
		<div class="container-left">
			<div class="container-left-description">
				{{ rearchInfo.description }}
			</div>
			<div class="container-left-tip">
				<span>温馨提示：</span
				><span>因版权原因，仅展示原文概要，查看原文内容请下载</span>
			</div>
			<p class="container-left-content" v-html="rearchInfo.content"></p>
		</div>
		<div class="container-right">
			<div class="container-right-row1 fx-justify-space-between">
				<div v-if="rearchInfo.catalogueNames != null">
					{{
						rearchInfo.catalogueNames[
							rearchInfo.catalogueNames.length - 1
						]
					}}
				</div>
				<div v-if="rearchInfo.releaseTime != null">
					{{ rearchInfo.releaseTime }}
				</div>
			</div>
			<div class="container-right-title">
				{{ rearchInfo.title }}
			</div>
			<!-- <el-rate v-model="rearchInfo.score" disabled text-color="#ff9900">
			</el-rate> -->
			<div class="container-right-page" v-if="rearchInfo.pageNum != null">
				<span>页数:{{ rearchInfo.pageNum }}页</span>
				<span>格式:{{ rearchInfo.fileFormat }}</span>
			</div>
			<div
				class="container-right-page"
				v-if="rearchInfo.biaoZhunNo != null"
			>
				<span>标准编号:{{ rearchInfo.biaoZhunNo }}页</span>
			</div>
			<div
				class="container-right-page"
				v-if="rearchInfo.biaoZhunStateCn != null"
			>
				<span>标准状态:{{ rearchInfo.biaoZhunStateCn }}</span>
			</div>
			<div class="container-right-page" v-if="rearchInfo.author != null">
				<span>作者:{{ rearchInfo.author }}</span>
			</div>
			<div
				class="container-right-page"
				style="border-bottom: 1px solid #d4d4d4"
				v-if="rearchInfo.source != null"
			>
				<span>来源:{{ rearchInfo.source }}</span>
			</div>
			<div class="container-right-price" v-if="rearchInfo.isFree == true">
				¥{{ rearchInfo.money }}
			</div>
			<div
				class="container-right-price"
				v-if="rearchInfo.isFree == false"
			>
				免费
			</div>
			<!-- 购买状态切换 未登录 立即登录-->
			<div class="buyState" v-if="purchaseState == false">
				<div class="container-right-tip">
					<div class="text-center margin-top-20">
						<el-button
							type="text"
							 @click="login()"
							class="container-right-button"
							>立即登录或注册</el-button
						>
					</div>
					<div class="margin-top-20">请先登录注册,再立即购买</div>
				</div>
			</div>
			<!-- 购买状态切换 已登录 未购买 立即购买-->
			<div
				class="buyState"
				v-if=" purchaseState == true && rearchInfo.bayState==false"
			>
				<div class="container-right-tip">
					<div class="text-center margin-top-20">
						<el-button
							type="text"
							@click="purchase()"
							class="container-right-button"
							>立即购买</el-button
						>
					</div>
				</div>
			</div>
			<!-- 购买状态切换 已登录 已购买 立即下载-->
			<div
				class="buyState"
				v-if="purchaseState == true && rearchInfo.bayState==true "
			>
				<div class="container-right-tip">
					<div class="text-center margin-top-20">
						<el-button
							type="text"
							class="container-right-button"
							@click="
								dowload(rearchInfo.pdfFileUrl, rearchInfo.title)
							"
							>立即下载
						</el-button>
					</div>
				</div>
			</div>
		</div>
	</div>
	<!-- 微信支付 -->
	<el-dialog
		title="微信支付提示"
		v-model="centerDialogVisible"
		width="380px"
		center
	>
		<div class="text-center">
			<!-- <div>请支付课程订单</div> -->
			<img :src="codeUrl" alt="" class="weixinImg" />
			<el-row class="text-center fx-justify-space-around">
				<el-button type="primary" round @click="ToCancel()"
					>取消支付</el-button
				>
				<el-button type="primary" round @click="ToPersonInfo()"
					>支付成功</el-button
				>
			</el-row>
		</div>
	</el-dialog>
</template>

<script>
import {
	GetWebSiteGetDetails,
	SengMessage,
	LoginByAccount,
	CreateNewsOrder,
	QrCodePay,
} from '@/api/index';
export default {
	name: 'dataDetail',
	data() {
		return {
			matenameYi: '',
			matenameEr: '',
			matetitle: '',
			buyState: false, //是否购买
			centerDialogVisible: false, //微信支付的弹窗显示
			rearchInfo: {}, //研究与洞察信息
			dialogVisible: false, //登录弹窗的显示与否
			purchaseState: false, //购买状态的切换
			radio1: '1',
			labelPosition: 'right',
			value: '2',
			formPersonSubmit: {
				//个人登录
				phoneNumber: '',
				code: '', //验证码
				type: '', //同意隐私政策
			},
			rulesPerson: {
				//个人登录校验
				phoneNumber: [
					{
						required: true,
						pattern:
							/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					},
				],
				code: [
					{
						required: true,
						message: '请输入正确的验证码',
						trigger: 'blur',
					},
				],
				type: [
					{
						required: true,
						message: '请勾选同意用户协议和隐私政策按钮',
						trigger: 'change',
					},
				],
			},
			formCompanySubmit: {
				//企业登录
				loginName: '', //账号
				password: '', //密码
				type: '',
			},
			rulesCompany: {
				//企业登录校验
				loginName: [
					{
						required: true,
						message: '请输入企业账号',
						trigger: 'blur',
					},
				],
				password: [
					{ required: true, message: '请输入密码', trigger: 'blur' },
				],
				type: [
					{
						required: true,
						message: '请勾选同意用户协议和隐私政策按钮',
						trigger: 'change',
					},
				],
			},
			timer: null, //计时器
			count: '', //倒计时
			show: true, //控制按钮
			TIME_COUNT: 30,
			changeNum: 0, // 0是个人登录  1是企业登录
			codeUrl: '', //微信二维码地址
			outTradeNo: '', //商户号
			OrderTypeInput: '',
			matePath:'',
			isLoginAlertA:1,
		};
	},
	mounted() {
		this.getResearchInfo(this.$route.query.id);
		if (localStorage.getItem('token')) {
			//获取到token 登录状态改为可购买状态
			this.purchaseState = true;
		}
	},
	methods: {
		login(){
			this.isLoginAlertA = this.isLoginAlertA+1
			this.$emit('isLoginAlertFun',this.isLoginAlertA)
		},
		go(url){
			this.$router.push(url);
		},
		changeAgree(type) {
			if (type == false) {
				this.formPersonSubmit.type = '';
				this.formCompanySubmit.type = '';
			}
		},
		waibu(url) {
			window.open(url, '_blank');
		},
		// 获取研究与洞察数据详情
		getResearchInfo(Id) {
			GetWebSiteGetDetails({ Id: Id }).then((res) => {
					this.rearchInfo = res;
					if (this.rearchInfo.releaseTime != null) {
						this.rearchInfo.releaseTime =
							res.releaseTime.split('T')[0];
					}

					this.rearchInfo.catalogueNames =
						res.catalogueNames.split(',');
					this.rearchInfo.content = res.content.replace(
						/\<img/g,
						'<img style="max-width:100%;height:auto;"'
					);
			
					this.OrderTypeInput = 1;
					//(this.rearchInfo.bayState,'====================rearchInfo.BayStaterearchInfo.BayStaterearchInfo.BayStaterearchInfo.BayState')
			});
		},
		// 报告下单 重新购买
		purchase() {
			let role = window.localStorage.getItem('role');
			// 将报告id 传入 购买接口 OrderTypeInput 研究报告为1  标准为2
			CreateNewsOrder({
				NewsId: this.$route.query.id,
				OrderTypeInput: this.OrderTypeInput,
			}).then((res) => {
				// code 为 0 购买成功
				if (res.code == 0) {
					// 获取商户号
					this.outTradeNo = res.data.outTradeNo;
					if (this.rearchInfo.isFree ==false) {
						this.$message.success({
							message: '购买成功',
							type: 'success',
						});
						this.getResearchInfo(this.$route.query.id);
					} else {
						this.$message.success({
							message: '下单成功正在唤起微信支付',
							type: 'success',
						});
						//调用微信支付
						this.wxYuPay(this.outTradeNo);
					}
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 微信预下单
		wxYuPay(outTradeNo) {
			let role = window.localStorage.getItem('role');
			let data = {
				outTradeNo: outTradeNo,
				description: 'string',
				total: 0,
				notifyUrl: 'string',
				payerClientIp: 'string',
				orderType: this.OrderTypeInput, // 0是课程订单 1报告订单 2标准订单
			};
			// 商户号 传入微信支付接口
			QrCodePay(data).then((res) => {
				// code 为0 成功 返回支付二维码
				if (res.code == 0) {
					this.codeUrl = res.codeUrl;
					// if (role == 1) {
						// 弹出微信支付窗口
						this.centerDialogVisible = true;
					// } else {
					// 	this.getResearchInfo(this.$route.query.id);
					// }
				}
				// else {
				// 	this.$message.error(res.msg);
				// }
			});
		},
		// 取消订单
		ToCancel() {
			this.$message.success({
				message: '取消成功',
				type: 'success',
			});
			this.centerDialogVisible = false;
			this.getResearchInfo(this.$route.query.id);
		},
		// 支付成功
		ToPersonInfo() {
			// this.$router.push({
			// 	path: '/person/dataReport',
			// });
			this.centerDialogVisible = false;
			this.getResearchInfo(this.$route.query.id);
		},
		// 下载 pdf
		dowload(file, title) {
			// window.open(pdfUrl);
			// 查看点击事件后传递过来的数据
			//console.log(file, '下载文件事件');
			// 创建 a 标签
			const a = document.createElement('a');
			// 创建一个点击事件
			const event = new MouseEvent('click');
			// 将 a 的 download 属性设置为我们想要下载的图片的名称，若 name 不存在则使用'图片'作为默认名称
			a.download = title || '文件名称';
			// 将生成的 url 设置为 a.href 属性 （file.downloadUrl 属性也是一个链接，是一个自定义的链接）
			a.href = file;
			// 打开新的窗口（如果是一个图片类型的文件，在浏览器打开新的标签页打开）
			a.target = '_blank';
			// 实现触发生成的 a 标签
			a.dispatchEvent(event);
		},
	},
};
</script>

<style scoped>
::v-deep(.el-checkbox__label) {
	color: #000000;
	display: inline-flex !important;
}

::v-deep(.el-link__inner a) {
	color: #000000;
	font-weight: bold;
}
::v-deep(.el-link__inner a:hover) {
	text-decoration: line;
	color: #0c2f66 !important;
}
.dialogAlive {
	display: block;
}
.dialogPhoneAlive {
	display: none;
}
/* 面包屑 */
.el-breadcrumb {
	margin-bottom: 10px;
	font-size: 20px;
	font-weight: bold;
	color: #303133;
}

/* ::v-deep(.el-breadcrumb__inner.is-link) {
	font-weight: normal;
} */

::v-deep(.el-breadcrumb__inner.is-link:hover) {
	color: #000000;
}

/* 容器 */
.container {
	padding: 48px 0;
	padding-top: 0;
}

/* 左侧 */
.container-left {
	width: 950px;
	text-align: left
}

/* 右侧 */
.container-right {
	width: 380px;
	height: 70%;
	padding: 23px 31px;
	background: #ffffff;
	box-shadow: 0px 20px 160px 8px rgba(0, 0, 0, 0.05);
	border-radius: 8px;
	box-sizing: border-box;
}

/* 描述 */
.container-left-description {
	padding: 32px 35px;
	background: #e7f0ff;
	font-size: 16px;
	color: #383838;
	line-height: 25px;
	border-left: 2px solid #0b3d8a;
	margin-bottom: 24px;
}

.container-left-tip {
	margin-bottom: 20px;
}

/* 提示 */
.container-left-tip span:first-child {
	font-size: 14px;
	color: #a23333;
}

.container-left-tip span:last-child {
	font-size: 14px;
	color: #000;
}

/* pdf预览 */
.container-left .container-left-context {
	width: 100%;
	padding: 29px 44px;
	background: #ffffff;
	border: 1px solid #acacac;
}

::v-deep(.container-left-content div) {
	width: 950px !important;
}

p {
	word-break: break-all;
}

.container-left-content p {
	width: 950px !important;
	overflow: hidden !important;
}

.container-left-content p img {
	max-width: 100% !important;
}

/* 右侧 */
.container-right-row1 {
	margin-bottom: 25px;
}

.container-right-row1 div:first-child {
	padding: 2px 8px;
	background: #0b3d8a;
	border-radius: 4px;
	color: #fff;
	font-size: 12px;
	font-weight: normal;
}

.container-right-row1 div:last-child {
	font-size: 16px;
	/* color: #bec2c2; */

      color: #333;
}

.container-right-title {
	font-size: 22px;
	font-weight: bold;
	color: #383838;
	/* padding-bottom: 21px; */
	overflow: hidden;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
	border-bottom: 1px solid #d4d4d4;
}

.container-right-page {
	/* border-top: 1px solid #d4d4d4; */
	border-bottom: 1px solid #d4d4d4;
	/* margin-top: 27px; */
	/* margin-bottom: 20px; */
	padding: 20px 0;
	font-size: 18px;
	color: #777777;
}

.container-right-page span:first-child {
	margin-right: 14px;
}

.container-right .container-right-page:last-child {
	border-bottom: 1px solid #d4d4d4;
}

.container-right-price {
	font-size: 36px;
	font-weight: bold;
	color: #000000;
	padding-bottom: 20px;
	border-bottom: 1px solid #d4d4d4;
	/* border-top: 1px solid #d4d4d4; */
	margin-bottom: 20px;
	margin-top: 10px;
}

.container-right-tip {
	font-size: 22px;
	font-weight: bold;
	color: #0b3d8a;
	text-align: center;
	margin-bottom: 15px;
	margin-top: 15px;
}

.weixinImg {
	width: 200px;
	height: 200px;
}

.btn-active {
	color: #0c2f66 !important;
	border-bottom: 2px solid #0c2f66 !important;
}

.yanzhen-btn {
	position: absolute;
	right: 8px;
	top: 8px;
}

.el-radio {
	margin-bottom: 20px;
}

.loginBtn .el-button {
	display: inline-block;
	padding: 10px 15px;
	font-size: 20px;
	height: 42px;
	font-weight: bold;
	margin-bottom: 40px;
	border: none;
	border-radius: 0;
	color: #000000;
	background-color: #fff;
	box-sizing: border-box;
	border-bottom: 2px solid transparent;
}

::v-deep(.el-dialog) {
	width: 380px;
	display: flex;
	flex-direction: column;
	margin: 0 !important;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	max-height: calc(100% - 30px);
	max-width: calc(100% - 30px);
}

.el-dialog__header {
	padding: 0;
}

.el-button--text:not(.is-disabled):focus,
.el-button--text:not(.is-disabled):hover {
	background: #0c2f66;
	color: #fff;
}

::v-deep(.el-input__wrapper) {
	height: 50px;
}

.container-right-button {
	width: 200px;
	text-align: center;
	height: 55px;
	line-height: 55px;
	background: #0c2f66;
	border-radius: 6px;
	backdrop-filter: blur(1px);
	font-size: 16px;
	color: #fff;
	letter-spacing: 2px;
	margin: 0 auto;
}

@media (min-width: 320px) and (max-width: 768px) {
	.inner {
		width: 360px;
	}
	.container {
		display: block;
	}

	.container-left {
		width: 100%;
	}

	.container-right {
		width: 100%;
		padding: 20px;
	}
	.container-right-tip {
		font-size: 18px;
		margin-top: 10px;
	}
	.container-left-description {
		padding: 25px;
		font-size: 14px;
	}
	.container-left-tip span:last-child {
		font-size: 13px;
	}
	.container-right-title {
		font-size: 16px;
		margin-bottom: 10px;
	}
	.container-right-page {
		padding: 12px 0;
		font-size: 14px;
	}
	.container-right-price {
		font-size: 20px;
		padding-bottom: 10px;
		margin-bottom: 10px;
		margin-top: 5px;
	}
	::v-deep(.container-left-content p) {
		text-align: justify;
	}
	.margin-top-20 {
		margin-top: 10px;
	}
	.el-breadcrumb__item {
		font-size: 16px;
	}
	.container-right-button {
		height: 45px;
		line-height: 45px;
	}
	.container-right-row1 {
		margin-bottom: 15px;
	}
	.dialogAlive {
		display: none;
	}
	.dialogPhoneAlive {
		display: block;
	}
}
</style>
