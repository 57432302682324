<template>
  
  <div class="container container-fluid fx-justify-space-between mgtop215 pdbt50 ">
	 <div class="block-left20">
		 <div v-for="(item,index) in blockOneList" class="nav-menu fx-center cursorpointer" :class="item.id==id?'nav-menu-sel':''" @click="navTab(item.id)">{{item.name}}</div>
	 </div>
	 <div class="block-right78" v-if="blockOneList.length>0">
		<div class="block-content">
			<div v-html = "menuJgObj[id]"></div>
		</div>
	 </div>
  </div>
</template>

<script>

import {FindConstantBySysContantId,GetAboutUs} from '@/api/index'

// import PolicyRightView from "@/views/PolicyRightView.vue";
export default {
  name: "PolicyDetailView",
  components:{
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
				}
			},
			deep: true,
		},
	},
  data() {
    return {
		blockOneList:[],
		id:'',
		menuJgObj:{},
		menuJgObjTwo:{}
    };
  },
  mounted() {
  	this.getFindConstantBySysContantId()
  },
  methods:{
	navTab(id){
		this.id = id
	},  
	getFindConstantBySysContantId(){
		let that = this
		FindConstantBySysContantId({ArticleTypeId:'10'}).then(res=>{
			this.blockOneList=res
			this.id = this.$route.query.id==undefined?res[0].id:this.$route.query.id
			res.forEach(function(e,i){
				that.menuJgObj[e.id] = ''
				that.menuJgObjTwo[e.id] = i
				that.getGetAboutUs(e.id)
			})
			
			// console.log(this.menuJgObj,'========this.menuJgObjthis.menuJgObj')
		})
	},
	getGetAboutUs(ArticleTypeId){
		GetAboutUs({ArticleTypeId:ArticleTypeId}).then(res=>{
			this.menuJgObj[ArticleTypeId] = res.data.content
		})
	}
	
  }
};
</script>

<style lang="less" scoped>
	.block-left20{
		width: 20%;
	}
	.block-right78{
		width: 78%;
	}
	.nav-menu{
		height: 3rem;
		font-size: 1.1rem;
		color: #333333;
		line-height: 1.3rem;
		background: #EFF1F6;
		border-radius: 0.1rem;
		margin-bottom: 0.7rem;
	}
	.nav-menu-sel{
		background: #0C2F66;
		border-radius: 0.1rem;
		color: #FFFFFF;
	}
	
	@media (max-width: 768px){
		.fx-justify-space-between{
			display: block;
		}
		.block-left20{
			width: 100%;
			display:flex;
			flex-wrap:wrap;
		}
		.nav-menu{
			font-size: 13px;
			width: auto;
			height: auto;
			padding: 3px 5px;
			margin-right: 8px;
		}
		.block-right78{
			width: 100%;
		}
	}
</style>