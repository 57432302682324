<template>
	<div class="dialogPhoneAlive">
		
			<el-dialog v-model="dialogVisible" width="30px" center>
				<el-row class="flex-justify-around loginBtn">
					<el-button
						:class="changeNum == 0 ? 'btn-active' : ''"
						
						>登录</el-button
					>
				<!-- 	<el-button
						:class="changeNum == 1 ? 'btn-active' : ''"
						@click="changeButton"
						>企业登录</el-button
					> -->
				</el-row>
				<!-- 个人登录 -->
				<el-form
					:model="formPersonSubmit"
					:rules="rulesPerson"
					ref="formPersonSubmit"
					v-if="changeNum == 0"
				>
					<el-form-item prop="phoneNumber">
						<el-input
							v-model="formPersonSubmit.phoneNumber"
							placeholder="请输入手机号"
							style="text-align: center"
							@change="setInputValue"
						></el-input>
					</el-form-item>
					<el-form-item prop="code">
						<el-input
							v-model="formPersonSubmit.code"
							placeholder="请输入验证码"
							style="text-align: center"
						></el-input>
						<el-button
							class="yanzhen-btn"
							slot="suffix"
							size="mini"
							style="border: none"
							@click="sendcode"
							:disabled="!show"
						>
							获取验证码
							<span v-show="!show" class="count">({{ count }}s)</span>
						</el-button>
					</el-form-item>
					<!-- <el-radio v-model="radio1" label="1"
					>我已阅读并同意“用户协议”和“隐私政策”</el-radio
				> -->
					<el-form-item prop="type"
						><el-checkbox
							v-model="formPersonSubmit.type"
							@change="changeAgree(formPersonSubmit.type)"
							><span class="color-black">我已阅读并同意</span
							><el-link :underline="false"
								><router-link to="/Agree?ServiceType=2" target="_blank"
									>“用户协议”</router-link
								></el-link
							><span class="color-black">和</span
							><el-link :underline="false"
								><router-link to="/Agree?ServiceType=1" target="_blank"
									>“隐私政策”</router-link
								></el-link
							></el-checkbox
						></el-form-item
					>
					<div class="text-center width-100 margin-top-20">
						<el-button
							type="text"
							class="container-right-button"
							@click="submitPrForm('formPersonSubmit')"
							>登录/注册</el-button
						>
					</div>
				</el-form>
				<!-- 企业登录 -->
				<el-form
					:model="formCompanySubmit"
					:rules="rulesCompany"
					ref="formCompanySubmit"
					v-if="changeNum == 1"
				>
					<el-form-item prop="loginName">
						<el-input
							v-model="formCompanySubmit.loginName"
							placeholder="请输入账号"
							style="text-align: center"
						></el-input>
					</el-form-item>
					<el-form-item prop="password">
						<el-input
							v-model="formCompanySubmit.password"
							placeholder="请输入密码"
							style="text-align: center"
							type="password"
						></el-input>
					</el-form-item>
					<!-- <el-radio v-model="radio1" label="1"
					>我已阅读并同意“用户协议”和“隐私政策”</el-radio
				> -->
					<el-form-item prop="type"
					
						><el-checkbox
							v-model="formCompanySubmit.type"
							@change="changeAgree(formCompanySubmit.type)"
							><span class="color-black" >我已阅读并同意</span
							><el-link :underline="false"
								><router-link to="/Agree" target="_blank"
									>“用户协议”</router-link
								></el-link
							><span class="color-black">和</span
							><el-link :underline="false"
								><router-link to="/Policy" target="_blank"
									>“隐私政策”</router-link
								></el-link
							></el-checkbox
						></el-form-item
					>
					<div class="text-center width-100 margin-top-20">
						<el-button
							type="text"
							class="container-right-button"
							@click="submitQyForm('formCompanySubmit')"
							>登录</el-button
						>
					</div>
				</el-form>
			</el-dialog>
	
	</div>
</template>

<script>
	
import CryptoJS from "crypto-js";
import {LoginByAccount,SengMessage,ProjectGreenjcGettoken} from '@/api/index'
export default {
  name: 'Agree',
  components: {},
  props: {
    source: String,
	isLoginAlertA:Boolean
  },
	watch:{
		isLoginAlertA:{
			handler(newValue){
				this.dialogVisible = newValue
			},
			immediate:true
		}
	},
  data() {
  	return {
		centerDialogVisible: false, //微信支付的弹窗显示
		courseInfo: {}, //课程信息
		videoUrl: '', //视频地址
		dialogVisible: false, //登录弹窗的显示与否
		purchaseState: false, //购买状态的切换
		radio1: '1',
		labelPosition: 'right',
		formPersonSubmit: {
			//个人登录
			phoneNumber: '',
			code: '', //验证码
			type: '', //同意隐私政策
		},
		rulesPerson: {
			//个人登录校验
			phoneNumber: [
				{
					required: true,
					pattern:
						/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
					message: '请输入正确的手机号',
					trigger: 'blur',
				},
			],
			code: [
				{
					required: true,
					message: '请输入正确的验证码',
					trigger: 'blur',
				},
			],
			type: [
				{
					required: true,
					message: '请勾选同意用户协议和隐私政策按钮',
					trigger: 'change',
				},
			],
		},
		formCompanySubmit: {
			//企业登录
			loginName: '', //账号
			password: '', //密码
			type: '',
		},
		rulesCompany: {
			//企业登录校验
			loginName: [
				{
					required: true,
					message: '请输入企业账号',
					trigger: 'blur',
				},
			],
			password: [
				{ required: true, message: '请输入密码', trigger: 'blur' },
			],
			type: [
				{
					required: true,
					message: '请勾选同意用户协议和隐私政策按钮',
					trigger: 'change',
				},
			],
		},
		timer: null, //计时器
		count: '', //倒计时
		show: true, //控制按钮
		TIME_COUNT: 30,
		changeNum: 0, // 0是个人登录  1是企业登录
		codeUrl: '', //微信二维码地址
		outTradeNo: '', //商户号
	}
  },
  mounted() {
  	this.dialogVisible = this.isLoginAlertA
    
    // let phone = 13577348792
    
    // // let phone = 13477115777
    // 	let encrymchuan =  this.encrypt(phone)
    //     console.log(encrymchuan,'===================encrymchuanencrymchuanencrymchuan')
  },
  methods: {
	changeAgree(type) {
		if (type == false) {
			this.formPersonSubmit.type = '';
			this.formCompanySubmit.type = '';
		}
	},
	// 获取手机号
	setInputValue() {
		// console.log(
		// 	this.formPersonSubmit.phoneNumber,
		// 	'-----------phoneNumber'
		// );
	},
	//发送验证码
	sendcode() {
		if(this.formPersonSubmit.phoneNumber==''){
			this.$message.error('请输入正确手机号');
			return
		}
		if(!/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(this.formPersonSubmit.phoneNumber)){
			this.$message.error('请输入正确手机号');
			return
		}
		if (!this.timer) {
			this.count = this.TIME_COUNT;
			this.show = false;
			this.timer = setInterval(() => {
				if (this.count > 0 && this.count <= this.TIME_COUNT) {
					this.count--;
				} else {
					this.show = true;
					clearInterval(this.timer); // 清除定时器
					this.timer = null;
				}
			}, 1000);
		}
		// 发送短息
		SengMessage({
			phone: this.formPersonSubmit.phoneNumber,
			type: 2,
		}).then((res) => {
			console.log(res);
		});
	},
	// 切换企业登录
	changeButton() {
		// 0为个人登录 1为企业登录
		if (this.changeNum == 0) {
			this.changeNum = 1;
		} else {
			this.changeNum = 0;
		}
	},
	getPhoneToken(phone){
		let encrymchuan =  this.encrypt(this.formPersonSubmit.phoneNumber)
		ProjectGreenjcGettoken({MobileSecret:encrymchuan}).then(res=>{
			// console.log(res,'============ProjectGreenjcGettoken')
			window.localStorage.setItem('ProjectGreenjcCnToken', res.token);
		})
	},
	encrypt(word, keyStr) {
		keyStr = keyStr ? keyStr : "4a4347594c534454";
		let key = CryptoJS.enc.Utf8.parse(keyStr);
		let srcs = CryptoJS.enc.Utf8.parse(word);
		let encrypted = CryptoJS.AES.encrypt(srcs, key, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7
		});
		return encodeURIComponent(encrypted.toString());
	},
	// 个人登录
	submitPrForm(formName) {
		this.$refs[formName].validate((valid) => {
			if (valid) {
				//账号 验证码 用户角色
				let data = {
					loginName: this.formPersonSubmit.phoneNumber,
					password: '',
					code: this.formPersonSubmit.code,
					peopleRole: 1, //普通用户
				};
				// 登录接口
				LoginByAccount(data).then((res) => {
					//code 为0 提示成功
					if (res.code == 0) {
						this.$message.success({
							message: res.message,
							type: 'success',
						});
						//this.getPhoneToken(res.customerInfo.phone)
						// 存储token值
						window.localStorage.setItem('token', res.token);
						// 客户id
						window.localStorage.setItem(
							'customerInfo',
							JSON.stringify(res.customerInfo)
						);
		// 				// 客户角色
		// 				window.localStorage.setItem(
		// 					'role',
		// 					res.customerInfo.role
		// 				);
		// 				// 客户手机号
		// 				window.localStorage.setItem(
		// 					'phone',
		// 					res.customerInfo.phone
		// 				);
						this.dialogVisible = false;
  
		// 				this.purchaseState = true;
						// console.log(this.purchaseState, 'yicang');
						window.location.reload();
					} else {
						this.$message.error(res.message);
					}
				});
			} else {
				// console.log('error submit!!个人');
				return false;
			}
		});
	},
  }
};
</script>

<style scoped>
	
	.container-right-button {
		width: 200px;
		text-align: center;
		height: 55px;
		line-height: 55px;
		background: #0c2f66;
		border-radius: 6px;
		backdrop-filter: blur(1px);
		font-size: 16px;
		color: #fff;
		letter-spacing: 2px;
		margin: 0 auto;
	}
	::v-deep(.el-checkbox__label) {
		color: #000000;
		display: inline-flex !important;
	}
	
	::v-deep(.el-link__inner a) {
		color: #000000;
		font-weight: bold;
	}
	::v-deep(.el-link__inner a:hover) {
		text-decoration: line;
		color: #0c2f66 !important;
	}
	::v-deep(.el-checkbox__label) {
		flex-direction: row;
		flex-wrap: wrap;
	}
	
	::v-deep(.el-dialog--center .el-dialog__body) {
		padding: 0px calc(var(--el-dialog-padding-primary) + 5px) 30px;
	}
	
	.dialogAlive {
		display: block;
	}
	.dialogPhoneAlive {
		display: block;
	}
	.weixinImg {
		width: 200px;
		height: 200px;
	}
	
	.yanzhen-btn {
		position: absolute;
		right: 8px;
		top: 8px;
	}
	.el-radio {
		margin-bottom: 20px;
	}
	.loginBtn .el-button {
		display: inline-block;
		padding: 10px 15px;
		font-size: 20px;
		height: 42px;
		font-weight: bold;
		margin-bottom: 40px;
		border: none;
		border-radius: 0;
		color: #000000;
		background-color: #fff;
		box-sizing: border-box;
		border-bottom: 2px solid transparent;
	}
	.btn-active {
		color: #0c2f66 !important;
		border-bottom: 2px solid #0c2f66 !important;
	}
	::v-deep(.el-dialog) {
		width: 380px;
		display: flex;
		flex-direction: column;
		margin: 0 !important;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: calc(100% - 30px);
		max-width: calc(100% - 30px);
	}
	.el-dialog__header {
		padding: 0;
	}
	.el-button--text:not(.is-disabled):focus,
	.el-button--text:not(.is-disabled):hover {
		background: #0c2f66;
		color: #fff;
	}
	::v-deep(.el-input__wrapper) {
		height: 50px;
	}
</style>
