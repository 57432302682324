<template>
	 <div class="myproject-list">
		<div class=" fx-justify-content  myproject-list-one-title">
			<div class="myproject-left">项目编号名称</div>
			<div class="myproject-center">流程图</div>
			<div class="myproject-right">去处理</div>
		</div>
		<div class="myproject-list-one fx-justify-content  " v-for="(project,index) in list">
			<div class="myproject-left">
				<div class="myproject-number">{{project.task_no}}</div>
				<div class="myproject-title">{{project.factory_name}}</div>
			</div>
			<div class="myproject-center">
				<div class="project-one-par">
					<div class="project-one " v-for="(item,i) in project.course_info_list">
						
						<div class="project-one-bolck">
							<div class="project-step fx-center" :class="project.course_show_list[i]=='show'?'project-stepsel':''">{{i+1}}</div>
							<div class="project-title" :class="project.course_show_list[i]=='show'?'project-titlesel':''">{{item.name}}</div>
							<div v-if="project.course_show_list[i]=='show'==true">
								<div class="project-line"></div>
								<div class="project-reviewer">{{item.person}}</div>
								<div class="project-reviewerTime">{{item.date}}</div>
							</div>
						</div>
						<div class="project-heng-line"  :class="project.course_show_list[i]=='show'?'project-heng-linesel':''" v-if="i!=0"></div>
					</div>
				</div>
			</div>
			<div class="myproject-right">
				<button @click="gotoWb(project.link)" :style="project.link==''?'background: #0c2f6642;border: 1px solid #0c2f6642;':''"><img src="../../assets/icons/icon-right-zhishi.png"/>去处理</button>
			</div>
		</div>
	 </div>
	
	 <div class="page">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div>
	 
</template>

<script>
import {ProjectGreenjcCourse} from '@/api/index'
import searchIndustry from '@/components/searchIndustry.vue' 
export default {
  name: "PolicyDetailView",
  components:{
	searchIndustry
  },
  data() {
    return {
		list:[],
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 6,
		  "sysContantId":''
		},
		total:0,
		projectProcessList:[
		],
		isAlertMasktwo:false
    };
  },
  props: {
    id: String,
  },
  mounted() {
	  
  	this.getGetFaLvFaGuiApiData()
  },
  methods:{
	gotoWb(url){
		if(url==''){
			return
		}
		window.open(url)
	},
	closeAlerttwo(){
		this.isAlertMasktwo = false
	},
	openAlerttwo(){
		this.isAlertMasktwo = true
	},
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.queryParma.currentPage = size
		this.getGetFaLvFaGuiApiData()
	},
	handleCurrentChange (currentPage) {
		this.queryParma.currentPage = currentPage
		this.getGetFaLvFaGuiApiData()
	},
	getGetFaLvFaGuiApiData(){
		let ProjectGreenjcCnToken = window.localStorage.getItem('ProjectGreenjcCnToken')
		ProjectGreenjcCourse({token:ProjectGreenjcCnToken}).then(res=>{
			this.list = res.list
			//console.log(res,'=-----ProjectGreenjcCourseProjectGreenjcCourse')
			// this.total = res.total
		})
	},
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/datadetail?id='+item.id)
		}
	},
  }
};
</script>

<style lang="less" scoped>
	.myproject-list{
		.myproject-list-one{
			text-align: center;
			border: 1px solid #EFEFEF;
			height: 9.65rem;
			.myproject-left{
				width: 11.8rem;
				border-right: 1px solid #EFEFEF;
				height: 100%;
				display: flex;
				flex-direction: column;
				font-size: 0.8rem;
				color: #141414;
				line-height: 1.1rem;
				padding:0 1.15rem;
				text-align: left;
				align-items: flex-start;
				justify-content: center;
			}
			.myproject-center{
				width: 32.3rem;
				height: 100%;
				border-right: 1px solid #EFEFEF;
				display: flex;
				align-items: center;
				justify-content: center;
			}
			.myproject-right{
				width: 9.95rem;
				height: 100%;
				display: flex;
				align-items: center;
				justify-content: center;
				button{
					width: 6.9rem;
					height: 2.1rem;
					background: #0C2F66;
					border-radius: 0.2rem;
					font-size: 0.8rem;
					color: #FFFFFF;
					line-height: 1.1rem;
					text-align: center;
					img{
						width: 0.8rem;
						height: 0.8rem;
						margin-right: 0.55rem;
					}
				}
			}
		}
		
		.myproject-list-one-title{
			background: #FAFAFA;
			border-radius: 0.1rem;
			font-size: 0.8rem;
			font-weight: 700;
			color: #141414;
			line-height: 1.3rem;
			height: 3rem;
			text-align: center;
			border: 1px solid #EFEFEF;
			
			>div{
				justify-content: center;
			}
			.myproject-left{
				width: 11.8rem;
				border-right: 1px solid #EFEFEF;
				height: 100%;
				display: flex;
				flex-direction: column;
				font-size: 0.8rem;
				color: #141414;
				line-height: 1.1rem;
				padding:0 1.15rem;
				text-align: left;
				align-items: flex-start;
			}
			.myproject-center{
				width: 32.3rem;
				height: 100%;
				border-right: 1px solid #EFEFEF;
				display: flex;
				align-items: center;
			}
			.myproject-right{
				width: 9.95rem;
				height: 100%;
				display: flex;
				align-items: center;
			}
			 .myproject-left{
				align-items: center;
			 }
		}
	}
		.myproject-center .project-one-par{
			display: flex;
		}
	
		.myproject-center .project-one .project-heng-line{
			width: 1.5rem;
			height: 1.5px;
			border: 1px dashed #0C2F66;
			position: absolute;
			top: 0.95rem;
			left: -0.8rem;
			opacity: 0.4;
		}
		.myproject-center .project-one .project-heng-linesel{
			opacity: 1;
			border: 1px solid #0C2F66;
		}
		
		.myproject-center .project-one{
			position: relative;
		}
		.myproject-center .project-step{
			border: 0.2rem solid #0C2F66;
			font-size: 0.9rem;
			color: #0C2F66;
			border-radius: 50%;
			width: 1.9rem;
			height: 1.9rem;
			opacity: 0.4;
			margin: 0 auto;
			font-weight: 700;
		}
		.myproject-center  .project-stepsel{
			opacity: 1;
			border: 0.2rem solid #A5B9D9;
			background: #0C2F66;
			font-size: 0.9rem;
			color: #FFFFFF;
			line-height: 1.25rem;
		}
		.myproject-center .project-one-bolck{
			width: 4.3rem;
			text-align: center;
		}
		.myproject-center  .project-title{
			font-size: 0.64rem;
			font-weight: 700;
			color: #0C2F66;
			line-height: 1.1rem;
			margin-top: 0.5rem;
			opacity: 0.4;
		}
		.myproject-center  .project-titlesel{
			opacity: 1;
		}
		.myproject-center .project-line{
			width: 0.05rem;
			height: 0.52rem;
			border: 1px dashed  #0C2F66;
			margin: 0 auto;
			margin-top: 10px;
		}
		.myproject-center  .project-reviewer{
			font-size: 0.64rem;
			font-weight: 600;
			color: #383838;
			line-height: 1.1rem;
			margin-top: 0.55rem;
		}
		.myproject-center .project-reviewerTime{
			font-size: 0.56rem;
			font-weight: 600;
			color: #383838;
			line-height: 1rem;
			margin-top: 0.2rem;
		}
	/**********************************/
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}

	@media (max-width: 768px){
		
	}
</style>