<template>
    <!-- 顶部图片 -->
	
  <div class="container text-center home-container all fx-justify-space-between">
    <div>
		
		<div class="breadcrumbs">
			<router-link to="/training">国建学院</router-link> > <router-link to="/training">培训计划</router-link> 
		</div>
		<!-- 标题区 -->
		<div class="title">
		  <div class="text">
		    {{info.trainingPlanName}}
		  </div>
		  <div class="title-time">
		    <div class="time-box">
		      <span class="time">培训时间：{{info.trainingPlaneTime}}</span>
		    </div>
		    <div class="from">培训地点：{{info.city}} | {{info.place}} | {{info.address}}</div>
		  </div>
		</div>
		<!-- 下划线 -->
		<div class="line"></div>
		<!-- 内容 -->
		<div class="detail-content">
			<div  v-html="info.content"></div>
		</div>
	</div>
	<div class="search-right">
		<div class="breadcrumbs" style="opacity: 0;">
			<router-link to="/industry">行业</router-link> > {{info.trainingPlanName}}
		</div>
		<div class="right-text">相关推荐</div>
		<div class="hot-content" v-for="(item2, i2) in trainingPlanList" :key="i2" @click="goToTrainDetail(item2.id)">
		  <img :src="item2.picturlUrlCaijian" class="content-img" />
		  <div class="hot-box">
			<div class="hot-title">
			  {{ item2.trainingPlanName }}
			</div>
			<div class="hot-time">
			  <div>
				<img src="../assets/policy/icon-time.png" alt="" /><span
				  class="time-text"
				  >{{ item2.startTimeCn }}</span
				>
			  </div>
			  <div></div>
			</div>
		  </div>
		</div>
    </div>
  </div>
</template>

<script>
import {TrainingPlanGetWebSiteTrainingPlanDetail,TrainingPlanGetWebSiteTrainingPlanData} from '@/api/index'
import PolicyRightView from "@/views/PolicyRightView.vue";
export default {
  name: "PolicyDetailView",
  components: {
    PolicyRightView,
  },
  data() {
    return {
      title: "五部门关于严格能效约束推动重点领域节能降",
      time: "2023-06-28",
      from: "来源：中国政府网",
      readNum: "121",
      imgurl: "",
	  id:'',
	  info:{},
	  trainingPlanSerch:{
		"currentPage": 1,
		"pageSize": 4,
		"articleTypeId":'3',
		"sysContanstIdInput":'',
		"timeTypeInput":'',
		"stateInput":'',
	  },
	  trainingPlanList:[]
    };
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
					this.getCoursesGetWebSiteCourseList()
					this.getTrainingPlanGetWebSiteTrainingPlanData()
				}
			},
			deep: true,
		},
	},
  mounted() {
	  
	this.id = this.$route.query.id
	this.getCoursesGetWebSiteCourseList()
	this.getTrainingPlanGetWebSiteTrainingPlanData()
  },
  methods:{
    goToTrainDetail(id){
      this.$router.push('/trainingdetail?id='+id)
    },
	getCoursesGetWebSiteCourseList(){
		TrainingPlanGetWebSiteTrainingPlanDetail({Id:this.id}).then(res=>{//精品课堂列表
			this.info=res
		})
	},
	getTrainingPlanGetWebSiteTrainingPlanData(){
		TrainingPlanGetWebSiteTrainingPlanData(this.trainingPlanSerch).then(res=>{//培训计划列表
			if(res.code == 0 && res.data.length>0){
				this.trainingPlanList = res.data
			}else{
				this.trainingPlanList = []
			}
			this.total = res.total
		})
	},
  }
};
</script>

<style lang="less" scoped>
.top-bigbg {
  position: relative;
  height: 25.25rem;
  .bigbg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .bigbg-box {
    position: relative;
    padding-top: 10rem;
    padding-left: 10.2rem;
    color: #ffffff;
    text-align: left;
    .bigbg-text {
      font-size: 3rem;
    }
    .text-eng {
      font-size: 1.1rem;
      letter-spacing: 3px;
    }
  }
}
.all {
  position: relative;
  .title {
    display: flex;
    flex-direction: column;
    width: 47.6rem;
	margin-top: 20px;
    .text {
      font-size: 1.8rem;
      font-weight: bold;
      width: 47.1rem;
      // height: 2.5rem;
    }
    .title-time {
      display: flex;
      justify-content: center;
      font-size: 0.7rem;
      margin-top: 1.45rem;
      height: 1rem;
      .time-box {
        display: flex;
        justify-content: space-between;
        align-content: center;
        margin: 0 1rem;
        .box-img {
          position: relative;
          margin-top: 3px;
          width: 0.7rem;
          height: 0.7rem;
        }
        .time {
          margin-left: 0.45rem;
        }
      }
      .from {
        margin: 0 1rem;
      }
      .readnum {
        margin: 0 1rem;
      }
    }
  }
  .line {
    width: 46.95rem;
    height: 0.05rem;
    background-color: #efefef;
    margin: 1.5rem 0;
  }
  .detail-img-box {
    text-align: left;
    .detail-img {
      width: 47.15rem;
      height: 19.25rem;
    }
  }
  .detail-content {
    width: 47.15rem;
    // height: 26.95rem;
    margin-top: 1.5rem;
    text-align: left;
    font-size: 0.7rem;
	padding-bottom: 50px;
    .bigtitle {
      font-weight: bold;
      margin-top: 2rem;
    }
  }
  .state {
    font-size: 0.7rem;
    font-weight: bold;
    text-align: left;
  }
  .bottom {
    display: flex;
    justify-content: center;
    margin-top: 7.25rem;
    font-size: 0.7rem;
    margin-bottom: 5.5rem;
    .arrow {
      width: 0.35rem;
      height: 0.65rem;
      margin-top: 4px;
    }
    .text-font {
      display: flex;
      justify-content: space-between;
      margin-left: 1.4rem;
      margin-right: 0.9rem;
      width: 20rem;
      height: 1rem;
    }
  }
  .search-right {
    .right-text {
      font-size: 1.1rem;
      font-weight: bold;
      text-align: left;
    }
  }
}

.search-right{
	width: 20.35rem;
}
.detail-content img{
	max-width: 100% !important;
}
@media (max-width: 768px) {
  .top-bigbg {
    height: 200px;
    .bigbg-box {
      position: relative;
      padding-top: 70px !important;
      padding-left: 70px !important;
      .bigbg-text {
        font-size: 25px;
      }
      .text-eng {
        font-size: 20px;
        letter-spacing: 1px;
      }
      .box-line {
        margin-top: 20px;
      }
    }
  }
  .search-right{
	  width: 100%;
	  .breadcrumbs{
		  display: none;
	  }
  }
  .all{
	  display: block;
	  .detail-content{
		  padding: 0;
	  }
    .title{
      width: 100%;
	  margin-top: 20px;
      .text{
        width: 100%;
        font-size: 16px;
		height: auto;
      }
      .title-time{
        font-size: 12px;
		height: auto;
		margin-top: 15px;
		display: block;
        .time-box{
          margin: 0 0px;
		  align-items: center;
		  justify-content: flex-start;
          .box-img {
            margin-top: 0px;
            width: 0.7rem;
            height: 0.7rem;
          }
          .time {
            margin-left: 5px;
          }
        }
        .from {
          margin: 0 15px;
		  text-align: left;
        }
        .readnum {
          margin: 0 15px;
        }
        }
    }
    .line{
      width: 100%;
	  margin: 0.5rem 0;
    }
    .detail-img-box{
      width: 100%;
      height: 200px;
      .detail-img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .all .detail-content{
	  width: 100%;
  }
}

.hot-content {
  margin-top: 1.1rem;
  display: flex;
  border-bottom: 1px solid #f5f5f5;
  padding-bottom: 1rem;
  &:hover {
    cursor: pointer;
  }
  &:last-child {
    border: none;
  }
  .content-img {
    width: 8.45rem;
    height: 4.4rem;
  }
  .hot-box {
    margin-left: 1rem;
    .hot-title {
      text-align: left;
      width: 11.3rem;
      height: 2rem;
      font-size: 0.7rem;
      color: #333333;
      font-weight: bold;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .hot-time {
      margin-top: 1.1rem;
      display: flex;
      font-size: 0.6rem;
      justify-content: space-between;
      .time-text {
        margin-left: 0.55rem;
      }
    }
  }
}
@media (max-width: 768px) {
	
	.hot-title {
		width: 100% !important;
		height: auto !important;
		
	}
}
</style>