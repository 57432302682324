import axios from '../util/request.js';
let {get, post,getLogin,getCode } = axios;

// account 登录
export const LoginByAccount = (data) =>
	post('/api/Account/LoginByAccount', data); //登录

export const SengMessage = (data) => get('/api/Account/SengMessage', data); //发送短信验证码

// Customeres 顾客
export const GetCuostomereInfo = (data) =>
	get('/api/Customeres/GetCuostomereInfo', data); //个人中心
export const CreateAndEditInfo = (data) =>
	post('/api/Customeres/CreateAndEditInfo', data); //个人信息修改

// 获取首页友情链接数据
export const WebSiteGetArticle = (data) =>
    get('/api/Home/WebSiteYouQingLianJie', data);

// 获取首页轮播图
export const WebSiteGetSysBanner = (data) => get('/api/Home/WebSiteGetSysBanner', data)
    // 获取行业导航接口
export const WebSiteContantList = (data) => get('/api/Home/WebSiteContantList', data)
    // 应用数据中心
export const Articlee = (data) => get('/api/Home/FindArticleData', data)
    // 合作伙伴
export const Partneres = (data) => get('/api/Home/WebSiteHeZuoHouBan', data)
    // 网站头部菜单数据
export const WebSiteGetSysContant = (data) => get('/api/Home/WebSiteGetSysContant', data)
    // 获取新闻数据
export const WebSiteNews = (data) => post('/api/News/WebSiteNews', data)
    // 国建学院信息
export const GetHomeTrainingPlan = (data) => get('/api/Home/GetHomeTrainingPlan', data)
    // 获取新闻详情
export const GetWebSiteGetDetails = (data) => get('/api/News/GetWebSiteGetDetails', data)
    //  根据二级菜单获取三级菜单列表
export const FindConstantBySysContantId = (data) => get('/api/News/FindConstantBySysContantId', data)
    // 精品课堂列表
export const CoursesGetWebSiteCourseList = (data) => post('/api/Courses/GetWebSiteCourseList', data)
    // 精品课堂详情
export const CoursesGetWebSiteCourseInfo = (data) => get('/api/Courses/GetWebSiteCourseInfo', data)
    // 精品课堂筛选条件列表
export const CoursesGetNavTagData = (data) => get('/api/Courses/GetNavTagData', data)
 // 培训计划筛选条件列表
export const TrainingPlanGetNavTagList = (data) => get('/api/TrainingPlan/GetNavTagList', data)
 // 培训计划详情
export const TrainingPlanGetWebSiteTrainingPlanDetail = (data) => get('/api/TrainingPlan/GetWebSiteTrainingPlanDetail', data)
 // 培训计划列表
export const TrainingPlanGetWebSiteTrainingPlanData = (data) => post('/api/TrainingPlan/GetWebSiteTrainingPlanData', data)
 // 标准-培训计划
export const TrainingPlanGetCriterionesTrainingPlanApi = (data) => post('/api/TrainingPlan/GetCriterionesTrainingPlanApi', data)
 // 应用中心-获取弹窗外链
export const FindArticleChildByIdData = (data) => get('/api/Home/FindArticleChildByIdData', data)
 // 标准 五个豆腐块
export const GetStarndData = (data) => get('/api/Home/GetStarndData', data)
 // 业务咨询与申请
export const GetHomeMessageData = (data) => get('/api/Home/GetHomeMessageData', data)
 // 获取最热资讯
export const GetPolicyInformationDto = (data) => get('/api/News/GetPolicyInformationDto', data)


// NewsOrder新闻下单
export const CreateNewsOrder = (data) =>
	get('/api/NewsOrder/CreateNewsOrder', data); //新闻下单接口
export const GetCustomerNewsOrderList = (data) =>
	post('/api/NewsOrder/GetCustomerNewsOrderList', data); //获取个人订单数据 研究报告、数据报告
export const GetHangYCustomerOrderList = (data) =>
	post('/api/NewsOrder/GetCustomerOrderList', data); //获取个人行业报告

// CourseOrder 课程下单
export const CreateCourseOrder = (data) =>
get('/api/CourseOrder/CreateCourseOrder', data); //课程下单
export const GetCustomerOrderList = (data) =>
post('/api/CourseOrder/GetCustomerOrderList', data); //获取用户课程订单数据
export const RemoveCourseOrder = (data) =>
get('/api/CourseOrder/RemoveCourseOrder', data); //删除订单
export const CancelCourseOrder = (data) =>
get('/api/CourseOrder/CancelCourseOrder', data); //取消订单

// WeChatPay
export const QrCodePay = (data) => post('/api/WeChatPay/QrCodePay', data); //Web扫码支付
export const H5Pay = (data) => post('/api/WeChatPay/H5Pay', data); //Web扫码支付
export const ContinueQrCodePay = (data) =>
	get('/api/WeChatPay/ContinueQrCodePay', data); //Web个人中心继续支付订单 (Auth)
export const QueryByTransactionId = (data) =>
	get('/api/WeChatPay/QueryByTransactionId', data); //微信支付订单号查询
export const QueryByOutTradeNo = (data) =>
	get('/api/WeChatPay/QueryByOutTradeNo', data); //商户订单号查询

// Invoices 发票信息
export const CreateOrEditInvoice = (data) =>
	post('/api/Invoices/CreateOrEditInvoice', data); //创建或修改个人发票信息
export const GetInvoiceInfo = (data) =>
	get('/api/Invoices/GetInvoiceInfo', data); //获取个人发票信息
	
	
	
// Criteriones 标准
export const GetCriterionApiInfo = (data) => get('/api/Criteriones/GetCriterionApiInfo', data); //获取五个标准下的数据
export const GetCriterionServiceTeamApiInfo = (data) => get('/api/Criteriones/GetCriterionServiceTeamApiInfo', data); //标准下-服务团队
export const GetCriterionImportanceApiInfo = (data) => get('/api/Criteriones/GetCriterionImportanceApiInfo', data); //标准-行业重要标准清单
export const GetCriterionUnderStudyApiData = (data) => post('/api/Criteriones/GetCriterionUnderStudyApiData', data); //标准-在演标准清单



	
// ResourceCenter 资源中心
export const GetFaLvFaGuiApiData = (data) => post('/api/ResourceCenter/GetFaLvFaGuiApiData', data); //法律法规
export const GetShuJuYuBaoGaoApiData = (data) => post('/api/ResourceCenter/GetShuJuYuBaoGaoApiData', data); //数据与报告
export const GetHeZuoAnLiApiData = (data) => post('/api/ResourceCenter/GetHeZuoAnLiApiData', data); //合作案例
export const GetRengZhengZiYuan = (data) => get('/api/ResourceCenter/GetRengZhengZiYuan', data); //认证资源-认证实施规则
export const GetShenQingBiaoApiData = (data) => post('/api/ResourceCenter/GetShenQingBiaoApiData', data); //认证资源-申请表
export const GetPingJiaZiYuanApiData = (data) => post('/api/ResourceCenter/GetPingJiaZiYuanApiData', data); //评价资源


// Home 关于我们
export const GetAboutUs = (data) => get('/api/Home/GetAboutUs', data); //关于我们


// news 服务与解决方案详情
export const GetHangYeData = (data) => get('/api/news/GetHangYeData', data); //涵盖行业
export const GetHangYeBaioGaoData = (data) => get('/api/news/GetHangYeBaioGaoData', data); //cjc开展得行业研究
export const GetPeiXunData = (data) => get('/api/news/GetPeiXunData', data); //相关培训
export const GetYouXiuAnLiData = (data) => get('/api/news/GetYouXiuAnLiData', data); //优秀案例
export const GetTuiJianInfo = (data) => get('/api/news/GetTuiJianInfo', data); //推荐资讯

// 全局搜索
export const GetOverallSituationData = (data) =>
	get('/api/Home/GetOverallSituationData', data); //全局搜索
	
// 
export const GetAutoAirApi = (data) =>
	get('/api/AutoAir/GetAutoAirApi', data); //全局搜索
		
export const GetServicesAndAgreementInfo = (data) =>
	get('api/Home/GetServicesAndAgreementInfo', data); //获取隐私协议

export const GetIndustryResearch = (data) =>
	get('api/News/GetIndustryResearch', data); //行业研究
	
export const WebSiteGetNewsDetails = (data) =>
	get('api/News/WebSiteGetNewsDetails', data); //行业研究
	
export const GetHeZuoHuoBanBan = (data) =>
	get('api/News/GetHeZuoHuoBanBan', data); //行业研究
	
export const ProjectGreenjcGettoken = (data) =>
	get('api/ProjectGreenjcCn/GetToken', data); //

export const ProjectGreenjcCertificateList = (data) =>
	get('api/ProjectGreenjcCn/CertificateList', data); //

export const ProjectGreenjcCertificate = (data) =>
	get('api/ProjectGreenjcCn/Certificate', data); //

export const ProjectGreenjcCourse = (data) =>
	get('api/ProjectGreenjcCn/Course', data); //
	
export const ProjectGreenjcCertificateall = (data) =>
	get('api/ProjectGreenjcCn/Certificateall', data); //


export const getCertificatePage = (data) => post('/api/CertificateApi/CertificatePage', data); // 证书查询
export const GetCertificateDetail = (id) => get('/api/CertificateApi/GetDetail'+ `?id=${id}`); // 证书详情