<template>
  <div class="container text-center home-container all-box mg-tp-content">
	<div class="tab-title-block" v-if="blockOneList">
		<span v-for="(item, index) in blockOneList" :key="index"
			:class="blockOneTitleIndex == index ? 'tab-title-block-select' : ''" @click="blockTabone(item,index)">{{ item.name }}
			<div class="tab-title-block-select-line"></div>
		</span>
	</div>
	<div class="row pdbt50">
		<div class="col-12 col-md-8 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-fwjs.png"/>
				<span>服务介绍</span>
			</div>
			<div class="title-cs-line"></div>
			<div class="content">
				<div v-html="info.content"></div>
			</div>
		</div>
		
		<div class="col-12 col-md-1"></div>
		<div class="col-12 col-md-3 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-fwtt.png"/>
				<span>服务团队</span>
			</div>
			<div class="team-list row">
				<div class="col-12 team-list-one" v-for="(item,index) in teamList">
					<div>{{item.name}}</div>
					<div class="zhicheng">{{item.jobType}}</div>
				</div>
			</div>
			<div class="text-button fx-center" @click="openAlerttwo()">
				业务咨询<img src="../assets/icons/icon-jt-quan-left.png" />
			</div>
		</div>
	</div>
	<div class="row pdbt50" v-show="blockOneTitleIndex==1">
		<!-- <div class="col-12 col-md-8 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-fwjs.png"/>
				<span>服务介绍</span>
			</div>
			<div class="title-cs-line"></div>
			<div class="content">
				<div v-html="info.content"></div>
			</div>
			<div class="text-button fx-center" @click="openAlerttwo()">
				业务咨询<img src="../assets/icons/icon-jt-quan-left.png" />
			</div>
		</div> -->
		<!-- 
		<div class="col-12 col-md-1"></div>
		<div class="col-12 col-md-3 text-left">
			<img :src="info.tailorPictureUrl" style="max-width: 100%;"/>
		</div> -->
		<div class="col-12 col-md-12 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-pxfw.png"/>
				<span>标准培训</span>
			</div>
		</div>
		  <div class="row trainingplan">
		    <div v-for="(  item, index  ) in trainingList " :key="index" class="col-6 col-md-3 planbox" @click="goToTrainDetail(item.id)">
		        <div class="theTopbox">
				  <img :src="item.picturlUrlCaijian" class="planbox-img"/>
		          <div class="theTop" v-if="item.topPostion==1">
		            <img src="@/assets/public/star.png" alt="" class="theTop-star">
		            <div class="theTop-text">置顶</div>
		          </div>
		        </div>
		        <div class="plancontent">
		          <div class="plantitle">{{ item.trainingPlanName }}</div>
		          <div class="planstatus">
		            <div class="status-box" v-if="item.stopCnName=='报名中'">
		              <img src="@/assets/training/begin.png" alt="">
		              <span class="status-text begin">报名中</span>
		            </div>
		            <div class="status-box" v-if="item.stopCnName=='进行中'">
		              <img src="@/assets/training/begining.png" alt="">
		              <span class="status-text begining">进行中</span>
		            </div>
		            <div class="status-box" v-if="item.stopCnName=='已结束'">
		              <img src="@/assets/training/begining.png" alt="">
		              <span class="status-text over">已结束</span>
		            </div>
		            <div class="plantime-box">
		              <span>{{ item.startTimeCn }}</span>
		              <div class="planbox-line"></div>
		              <span>{{ item.city }}</span>
		            </div>
		          </div>
		        </div>
		  </div>
		</div>
	</div>
	
	<div class="row pdbt50" v-show="blockOneTitleIndex==3">
		<div class="col-12 col-md-8 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-hyzybzqd.png" class="hyzybzqdimg"/>
				<span>行业重要标准清单</span>
			</div>
			<div class="title-cs-line"></div>
			<div class="content row">
				<div class="col-6 col-md-4 hyzybzqd" v-for="(item,index) in industryList" @click="goToWL(item.toUrl)">
					<div class="hyzybzqd-cn">{{item.name}} ></div>
					<div class="hyzybzqd-en">{{item.criterionNo}}</div>
				</div>
			</div>
		</div>
		
		<div class="col-12 col-md-1"></div>
		<div class="col-12 col-md-3 text-left row">
		</div>
	</div>
	
	<div class="row pdbt50" v-show="blockOneTitleIndex==4">
		<div class="col-9 col-md-8 text-left">
			<div class="title-cs">
				<img src="../assets/icons/icon-hyzybzqd.png" class="hyzybzqdimg"/>
				<span>在研标准清单</span>
			</div>
			<div class="title-cs-line"></div>
			<div class="content row">
				<div class="col-12 zybiaozhunqingdan fx" v-for="(item,index) in zabzqdList"  @click="goToZyqd(item)">
					<img  src="../assets/icons/icon-menu.png"/>
					<div class="hyzybzqd-lable fx">{{item.dictionariesName}}</div>
					<div  class="hyzybzqd-title">{{item.title}}</div>
				</div>
			</div>
		</div>
		
		<div class="col-0 col-md-1"></div>
		<div class="col-3 col-md-3 text-left row">
			<div class="title-cs" style="opacity: 0;">
				<img src="../assets/icons/icon-fwjs.png"/>
				<span>在研标准清单</span>
			</div>
			<div class="title-cs-line" style="opacity: 0;"></div>
			<div class="row">
				<div class="col-12 zybiaozhunqingdan-two fx" v-for="(item,index) in zabzqdList"  @click="goToZyqd(item)">
					<img src="../assets/icons/icon-time.png"/>
					{{item.releaseTime.split('T')[0]}}
				</div>
			</div>
		</div>
	</div>
	<div class="alert-maskthree" v-show="isAlertMasktwo">
		<div class="alert-maskthree-content">
			<div class="maskthree-header">
				<span>业务咨询</span>
				<img src="../assets/icons/icon-close-two.png" class="icon-close" @click="closeAlerttwo()"/>
			</div>
			<div class="maskthree-content"  >
				<div class="maskthree-content-bottom row">
					<div class="col-12">
						<img src="../assets/icons/icon-persone.png">
						<span>联系人：<span>{{info.contact}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-phone.png">
						<span>联系方式：<span>{{info.contactWay}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-email.png">
						<span>联系邮箱：<span>{{info.contactEmail}}</span></span>
					</div>
					<div class="col-12">
						<img src="../assets/icons/icon-location.png">
						<span>联系地址：<span>{{info.contactAddress}}</span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
  </div>
  
</template>

<script>
import {GetCriterionApiInfo,GetStarndData,GetCriterionServiceTeamApiInfo,GetCriterionImportanceApiInfo,GetCriterionUnderStudyApiData,TrainingPlanGetCriterionesTrainingPlanApi} from '@/api/index'
export default {
  name: "",
  data() {
    return {
		isAlertMasktwo:false,
		info:{},
		blockOneList:[],
		blockOneTitleIndex:0,
		id:'',
		teamList:[],
		industryList:[],
		zabzqdList:[],
		criterionUnderStudySecher:{
		  "currentPage": 1,
		  "pageSize": 100
		},
		trainingList:[],
		menuJgObj:{}
    };
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
					this.blockOneTitleIndex = this.menuJgObj[to.query.id]
					this.getList()
				}
			},
			deep: true,
		},
	},
  mounted() {
	this.getGetStarndData()
  },
  methods:{
    goToTrainDetail(id){
      this.$router.push('/trainingdetail?id='+id)
    },
	goToZyqd(item){
	  if(item.isWaibuUrl==true){
		this.goToWL(item.toUrl)
	  }else{
		this.$router.push('/standarddetail?id='+item.id)
	  }
	},
	goToZybzqd(item){
		if(item.isWaibuUrl==true){
			this.goToWL(item.toUrl)
		}else{
			this.$router.push('/standarddetail?id='+item.id)
		}
	},
	goToWL(url){
	  window.open(url)
	  
	},
	closeAlerttwo(){
		this.isAlertMasktwo = false
	},
	openAlerttwo(){
		this.isAlertMasktwo = true
	},
	blockTabone(item,index){
		this.blockOneTitleIndex = index
		this.id = item.id
		this.getList()
	},
	getList(){
		this.getGetCriterionApiInfo()
		this.getGetCriterionServiceTeamApiInfo()
		this.getGetCriterionImportanceApiInfo()
		this.getGetCriterionUnderStudyApiData()
		this.getTrainingPlanGetCriterionesTrainingPlanApi()
	},
	getGetCriterionApiInfo(){
		GetCriterionApiInfo({ArticleTypeId:this.id}).then(res=>{
			if(res.code == 0){
				this.info=res.data
			}
		})
	},
	getGetCriterionServiceTeamApiInfo(){
		GetCriterionServiceTeamApiInfo({ArticleTypeId:this.id}).then(res=>{
			if(res.code == 0){
				this.teamList = res.data
			}
		})
	},
	getGetCriterionImportanceApiInfo(){
		GetCriterionImportanceApiInfo().then(res=>{
			if(res.code == 0){
				this.industryList = res.data
			}
		})
	},
	getGetCriterionUnderStudyApiData(){
		GetCriterionUnderStudyApiData(this.criterionUnderStudySecher).then(res=>{
			if(res.code == 0){
				this.zabzqdList = res.data
			}
		})
	},
	getTrainingPlanGetCriterionesTrainingPlanApi(){
		TrainingPlanGetCriterionesTrainingPlanApi({
		  "currentPage": 1,
		  "pageSize":10,
		  "sysContantId": ""
		}).then(res=>{
			if(res.code == 0){
				this.trainingList = res.data
			}
		})
	},
	getGetStarndData(){
		GetStarndData({currentPage: 1,pageSize: 5}).then(res=>{
			this.blockOneList=res
			this.id = res[0].id
			
			this.id = this.$route.query.id!=undefined?this.$route.query.id:res[0].id
			
			let menuJgObj  = {}
			res.forEach(function(e,index){
				menuJgObj[e.id] = index
			})
			this.blockOneTitleIndex = menuJgObj[this.id]
			this.menuJgObj = menuJgObj
			this.getList()
		})
	},
	
  }
};
</script>

<style lang="less" scoped>
	.title-cs{
		display: flex;
		align-items: center;
		img{
			width: 1.1rem;
			height: 1.1rem;
			margin-right:0.6rem ;
		}
		.hyzybzqdimg{
			width: 1.2rem;
			height: 1.25rem;
		}
		span{
			font-size: 1.1rem;
			font-weight: 700;
			color: #333333;
			line-height: 1.1rem;
		}
		margin-bottom: 1.1rem;
	}
	.title-cs-line{
		width: 100%;
		height: 1px;
		background: #EFEFEF;
		border-radius: 0.05rem;
	}
	.content{
		margin-top:1.2rem ;
		font-size: 0.7rem;
	}
	.team-list{
		text-align: left;
	}
	.team-list .team-list-one{
		font-size: 0.7rem;
		font-weight: 700;
		color: #333333;
		line-height: 1rem;
		height: 3.15rem;
		background: #F6F6F6;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding-left:1.2rem  !important;
		margin-bottom: 1px !important;
	}
	.team-list .team-list-one .zhicheng{
		font-size: 0.6rem;
		color: #333333;
		line-height: 0.85rem;
	}
	.text-button{
		width: 7.05rem;
		height: 2.4rem;
		border-radius: 1.2rem;
		border: 0.05rem solid #0B3D8A;
		font-size: 0.8rem;
		font-weight: 600;
		color: #0B3D8A;
		line-height: 1.1rem;
		margin: 1.6rem 0;
		cursor: pointer;
	}
	.text-button img{
		width: 0.9rem;
		height: 0.95rem;
		margin-left: 0.6rem;
	}
	.hyzybzqd{
		margin-bottom: 1.3rem !important;
		.hyzybzqd-cn{
			font-size: 0.9rem;
			color: #014F93;
			line-height: 1.25rem;
			font-weight: 600;
			margin-bottom: 0.2rem;
		}
		.hyzybzqd-en{
			font-size: 0.7rem;
			color: #777777;
			line-height: 1rem;
		}
	}
	.zybiaozhunqingdan{
		font-size: 0.9rem;
		color: #333333;
		line-height: 1.25rem;
		cursor: pointer;
		margin-top: 1.5rem !important;
		img{
			width: 0.6rem;
			height: 0.65rem;
		}
		.hyzybzqd-lable{
			width: 2.4rem;
			height:1.05rem ;
			background: url(@/assets/icons/icon-jaintou.png);
			background-size:100% 100% ;
			background-repeat: no-repeat;
			font-size: 13px;
			color: #FFFFFF;
			text-indent: 5px;
			margin: 0 0.5rem;
		}
		.hyzybzqd-title{
			width: calc(100% - 3rem);
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}
	.zybiaozhunqingdan-two{
		font-size: 0.7rem;
		cursor: pointer;
		color: #666666;
		line-height: 0.9rem;
		margin-top: 1.5rem !important;
		img{
			width: 0.7rem;
			height: 0.75rem;
			margin-right: 0.5rem;
		}
	}
	
	.trainingplan{
	  margin-top: 2.5rem;
	  margin-bottom: 9.7rem;
	  .planbox{
	  // width: 16.65rem;
	  // height: 15rem;
	  padding: 0.5rem !important;
	  margin-bottom: 1.2rem !important;
	  &:hover{
	      cursor: pointer;
	    }
	  .theTopbox{
	    position: relative;
	    .planbox-img{
	      width: 100%;
	      height: 8.6rem;
		  border-top-left-radius: 0.6rem;
		  border-top-right-radius: 0.6rem;
	    }
	    .theTop{
	      border-radius: 0.05rem;
	      position: absolute;
	      left: 0.75rem;
	      top: 0.7rem;
	      width: 2.9rem;
	      height: 1.2rem;
	      display: flex;
	      justify-content: center;
	      align-items: center;
	      background-color: #006AFF;
	      color: #FFFFFF;
	      font-size: 0.7rem;
	      .theTop-star{
	        width: 0.7rem;
	        height: 0.7rem;
	        margin-top: -2px;
	      }
	      .theTop-text{
	        margin-left: 0.2rem;
	      }
	    }
	  }
	  .plancontent{
	    border-bottom-left-radius: 0.6rem;
	    border-bottom-right-radius: 0.6rem;
	    border: 0.05rem solid #D8D8D8;
	    height: 7.8rem;
	    padding-bottom: 16px;
	    display: flex;
	    flex-direction: column;
	    justify-content: space-between;
	    .plantitle{
	      font-size: 0.9rem;
	      font-weight: bold;
	      margin: 0rem 1rem;
	      margin-top: 0.95rem;
	      text-align: left;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
	    }
	    .planstatus{
	      display: flex;
	      justify-content: space-between;
	      align-items: center;
	      padding: 0 1rem;
	      margin-top: 1.8rem;
	      .status-box{
	        .status-text{
	          font-size: 0.8rem;
	          margin-left: 0.4rem;
	          font-weight: bold;
	          &.begin{
	            color: #18CD04;
	          }
	          &.begining{
	            color: #FFAF4A;
	          }
	          &.over{
	            color: #8C8C8C;
	          }
	        }
	      }
	      .plantime-box{
	        display: flex;
	        font-size: 0.7rem;
	        color: #333333;
	        .planbox-line{
	          width: 0.05rem;
	          height: 0.7rem;
	          background: #333333;
	          border-radius: 0.03rem;
	          margin: 0 0.5rem;
	          margin-top: 4px;
	        }
	      }
	    }
	  }
	}
	}
	
	
	@media (max-width: 768px){
		.col-0{
			display: none;
		}
		.pdbt50{
			padding-bottom: 20px;
		}
		.zybiaozhunqingdan{
			font-size: 12px;
		}
		.zybiaozhunqingdan .hyzybzqd-lable{
			font-size: 10px;
			width: 40px;
		}
		.zybiaozhunqingdan .hyzybzqd-title{
			width: calc(100% - 108px);
		}
		.title-cs img{
			width: 15px;
			height: 15px;
		}
		.title-cs span{
			font-size: 14px;
		}
		.content{
			font-size: 12px;
			margin-top: 20px !important;
		}
		
		.trainingplan{
		  .planbox{
		    margin-bottom: 0px !important;
		    // height: 310px;
			  height: auto;
		    .theTopbox{
		    .planbox-img{
		      // height: 180px;
				height: 95px;
		    }
		  }
		  .plancontent{
		    // height: 130px;
		      .plantitle{
		        font-size: 13px;
		        font-weight: bold;
		        text-align: left;
		        margin: 10px;
		      }
		      .planstatus{
		        padding: 0 10px;
				  display: block;
		        margin-top: 0px;
		        .status-box{
		          display: flex;
		          align-items: center;
		          img{
		            width: 14px;
		            height: 14px;
		          }
		          .status-text{
		            font-size: 14px;
		          }
		        }
		        .plantime-box{
		          font-size: 10px;
					height: auto;
		          .planbox-line{
		            margin-top: 2px;
		          }
		        }
		      }
		    }
		  }
		}
	}
</style>