<template>
      <!-- 顶部图片 -->
  <div class="container text-center home-container all">
	  
	<div class="breadcrumbs">
		国建学院 > <router-link to="/courses">精品课程</router-link> > {{info.courseName}}
	</div>
    <div class="play-box" style="padding-top:20px ;">
      <!-- <img src="../assets/traindetail/bg1.png" alt="" class="playimg"> -->
	  <video :src="navListId==''?info.videoPreviewPath:info.webSiteCourseLessonList[navListIndex].videoPath" controls="" controlslist="nodownload" name="media"  type="video/mp4" class="playimg"></video>
	  <div class="catalogue">
	    <div class="catalogue-top">
	      <div class="circle"></div><span class="catalogue-title">课程目录</span>
	    </div>
	    <div class="catalogue-list"> 
	      <div class="catalogue-content" v-for="(item,i) in info.webSiteCourseLessonList" :key="i" @click="navSelect(item.id,i)" :class="navListId == item.id ? 'nav-select' : ''">
	        <div class="content-number">{{ i+1 }} <span>{{ item.theme }}</span> </div>
	        <div class="content-time">{{ item.durationTime }}</div>
	      </div>
	    </div>
	    <div class="catalogue-bottom">
	      <div class="bottom-bar">本课程共 <span>{{info.sumBar}}</span> 小节</div>
	      <div class="bottom-price" v-if="info.isFree">￥<span>{{info.price}}</span></div>
	      <div class="bottom-free" v-else>免费</div>
	      <div class="catalogue-btn catalogue-btn-two" v-if="info.isShowPath==true">已购买</div>
	      <div class="catalogue-btn" @click="purchase()" v-else-if="customerInfo!=null">购买本课程</div>
	      <div class="catalogue-btn" @click="login()" v-else-if="customerInfo==null">登录/注册</div>
		  
	    </div>
	  </div>
    </div>
    
    <div class="train-introduce">
      <div class="train-introduce-one">
		  <div class="introduce-title fx-justify-content">
		    <div><img src="../assets/traindetail/icon1.png" alt=""><span>课程介绍</span></div>
			<div class=" fx-justify-content">
				<!-- <div class="kjxz fx-center cursorpointer" v-if="info.courseDownload!=null" @click="courseDownload(info.courseDownload)">课件下载</div> -->
				<!-- <div class="pxkcgm fx-center cursorpointer" v-if="info.toUrl!=null"  @click="courseDownload(info.toUrl)">培训教材购买</div> -->
				<div class="kjxz fx-center cursorpointer" v-if="info.courseDownload!=null && info.isShowPath==true" @click="courseDownload(info.courseDownload)">课件下载</div>
				<div class="pxkcgm fx-center cursorpointer" v-if="info.toUrl!=null && info.isShowPath==true"  @click="courseDownload(info.toUrl)">教材购买</div>
			</div>
		  </div>
		  <div class="line"></div>
		  <div class="introduce1" v-html="info.synopsis">
		  </div>
	  </div>
      <div class="about-courses">
        <div class="about-title">相关课程</div>
        <div class="about-courses-box" v-for="(item,i) in info.correlationCourse" :key="i"  @click="goToTrainDetail(item.id)">
          <img :src="item.editPicture" alt="">
          <div class="box-content">
            <div class="content-title">{{ item.courseName }}</div>
            <div class="content-timer">共<span>{{item.sumBar}}</span>小节</div>
          </div>
        </div>
      </div>
    </div>
  </div>
	<!-- 微信支付 -->
	<el-dialog
		title="微信支付提示"
		v-model="centerDialogVisible"
		width="380px"
		center
	>
		<div class="text-center">
			<!-- <div>请支付课程订单</div> -->
			<img :src="codeUrl" alt="" class="weixinImg" />
			<el-row class="text-center fx-justify-space-between">
				<el-button type="primary" round @click="ToCancel()"
					>取消支付</el-button
				>
				<el-button type="primary" round @click="ToPersonInfo()"
					>支付成功</el-button
				>
			</el-row>
		</div>
	</el-dialog>
  <!-- <LoginAlert  v-if="isLoginAlertA" :isLoginAlertA="isLoginAlertA"></LoginAlert> -->
</template>

<script>
import LoginAlert from '@/components/loginAlert.vue'
import {CoursesGetWebSiteCourseInfo,TrainingPlanGetWebSiteTrainingPlanData,CreateCourseOrder,QrCodePay} from '@/api/index'
export default {
    name: "TrainetailView",
	components:{LoginAlert},
    data() {
      return {
		customerInfo:{},
		centerDialogVisible: false, //微信支付的弹窗显示
		codeUrl: '', //微信二维码地址
		outTradeNo: '', //商户号
		isLoginAlertA:false,
		navListId:'',
        catalogueList:[
          {
            id:10,
            number:'10',
            title:'建设基本及几大要素',
            time:'12:92'
          },
        ],
        featureList:[
         
          {
            id:5,
            content:'5.专业平台：IT云课APP、PC端 平台展现全国最专、最全的软考项管课程服务。'
          },

        ],
        aboutCoursesList:[
          
          {
            id:3,
            imgurl:'',
            title:'五部门关于严格能效约束推动重点领域节能降',
            bar:123,
            person:28
          },
        ],
		info:{},
        navListIndex: 0,
      }
    },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
					this.customerInfo = JSON.parse(window.localStorage.getItem('customerInfo'));
					this.getCoursesGetWebSiteCourseInfo()
				}
			},
			deep: true,
		},
	},
	mounted() {
		this.id = this.$route.query.id
		this.customerInfo = JSON.parse(window.localStorage.getItem('customerInfo'));
		this.getCoursesGetWebSiteCourseInfo()
	},
    methods:{
		courseDownload(url){
			if(this.customerInfo!=null && this.customerInfo!='' && this.customerInfo!=undefined){
				window.open(url)
			}else{
				this.login()
			}
		},
		// 课程下单 重新购买
		purchase() {
			let role = window.localStorage.getItem('role');
			// 将课程id 传入 购买接口
			CreateCourseOrder({ CourseId: this.id }).then(
				(res) => {
					// code 为 0 购买成功
					if (res.code == 0) {
						// 获取商户号
						this.outTradeNo = res.data.outTradeNo;
						if (this.info.isFree == false) {
							this.$message.success({
								message: '购买成功',
								type: 'success',
							});
							window.location.reload();
						} else {
							this.$message.success({
								message: '下单成功正在唤起微信支付',
								type: 'success',
							});
						}
						//调用微信支付
						this.wxYuPay(this.outTradeNo);
					} else {
						this.$message.error(res.msg);
					}
				}
			);
		},
		// 微信预下单
		wxYuPay(outTradeNo) {
			let role = window.localStorage.getItem('role');
			let data = {
				outTradeNo: outTradeNo,
				description: 'string',
				total: 0,
				notifyUrl: 'string',
				payerClientIp: 'string',
				orderType: 0, // 0是课程订单 1报告订单 2标准订单
			};
			// 商户号 传入微信支付接口
			QrCodePay(data).then((res) => {
				// code 为0 成功 返回支付二维码
				if (res.code == 0) {
					this.codeUrl = res.codeUrl;
					// if (role == 1) {
						// 弹出微信支付窗口
						this.centerDialogVisible = true;
						this.getCoursesGetWebSiteCourseInfo();
					// } else {
						// this.getInfo(this.$route.query.CourseId);
					// }
				}
				// else {
				// 	this.$message.error(res.msg);
				// }
			});
		},
		// 取消订单
		ToCancel() {
			this.$message.success({
				message: '取消成功',
				type: 'success',
			});
			this.centerDialogVisible = false;
			this.getCoursesGetWebSiteCourseInfo();
			// this.getInfo(this.$route.query.CourseId);
		},
		// 支付成功
		ToPersonInfo() {
			// this.$router.push({
			// 	path: '/person/courseInfo',
			// });
			this.centerDialogVisible = false;
			this.getCoursesGetWebSiteCourseInfo();
		},
		goToTrainDetail(id){
		  this.$router.push('/coursesdetail?id='+id)
		},
		login(){
			this.isLoginAlertA = this.isLoginAlertA+1
			this.$emit('isLoginAlertFun',this.isLoginAlertA)
		},
		navSelect(index,i) {
			this.navListIndex = i
			if(this.info.isShowPath==true){
				this.navListId = index;
			}else{
				// this.isLoginAlertA = index+1
				// this.$emit('isLoginAlertFun',this.isLoginAlertA)
			}
		},
		getCoursesGetWebSiteCourseInfo(){
			CoursesGetWebSiteCourseInfo({CourseId:this.id}).then(res=>{//精品课堂列表
				this.info=res
			})
		}
    }
}
</script>

<style lang="less" scoped>
.kjxz{
	font-size: 16px;
	color: #0C2F66;
	line-height: 22px;
	width: 156px;
	height: 46px;
	border-radius: 4px;
	border: 1px solid #0C2F66;
}
.pxkcgm{
	font-size: 16px;
	color: #FFFFFF;
	line-height: 22px;
	width: 156px;
	height: 46px;
	background: #0C2F66;
	border-radius: 4px;
	margin-left: 20px;
	
}
.weixinImg {
	width: 200px;
	height: 200px;
}

.nav-select{
  background-color: #0C2F66;
  color: #FFFFFF;
}
.top-bigbg {
  position: relative;
  height: 25.25rem;
  .bigbg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .bigbg-box {
    position: relative;
    padding-top: 10rem;
    padding-left: 10.2rem;
    color: #ffffff;
    text-align: left;
    .bigbg-text {
      font-size: 2rem;
    }
    .text-eng {
      font-size: 0.8rem;
      letter-spacing: 1px;
    }
  }
}
.all{
  position: relative;
  padding: 0;
  margin-bottom: 6.8rem;
  margin-top: 2rem;
  .play-box{
    display: flex;
    align-items: center;
    // position: relative;
    .playimg{
      // position: relative;
      display: block;
      text-align: left;
	  width:51.4rem;
	  height:30.5rem;
    }
    .play-stop{
      // position: absolute;
      // top: 45%;
      // left: 35%;
      &:hover{
        cursor: pointer;
      }
    }
  }
  .catalogue{
    
    width: 18.6rem;
    height: 30.5rem;
    color: #FFFFFF;
    box-shadow: 0.55rem 0rem 0.95rem 0rem rgba(0,0,0,0.08);
    .catalogue-top{
      display: flex;
      align-items: center;
      margin-left: 1.2rem;
      height: 3.25rem;
      .circle{
        width: 0.4rem;
        height: 0.4rem;
        border-radius: 50%;
        background-color: #0C2F66;
      }
      .catalogue-title{
        color: #333330;
        font-size: 0.9rem;
        font-weight: bold;
        margin-left: 0.45rem;
      }
    }
  }
  .catalogue-list{
    width: 18.7rem;
    height: 23.15rem;
    font-size: 0.7rem;
    color: #666666;
    background-color: #F6F6F6;
    overflow-y: scroll;
    .catalogue-content{
      display: flex;
      flex-direction: column;
      justify-content: center;
      height: 2.65rem;
      text-align: left;
      padding-left: 1.2rem;
      &:hover{
        cursor: pointer;
      }
      .content-time{
        font-size: 0.6rem;
      }
    }
  }
  .catalogue-bottom{
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    text-align: left;
    height: 4.1rem;
    margin-left: 1.2rem;
    .bottom-bar{
      font-size: 0.7rem;
      color: #333333;
      span{
        font-size: 0.8rem;
        color: #0C2F66;
        font-weight: bold;
      }
    }
    .bottom-price{
      color: #FF3350;
      font-size: 0.6rem;
      font-weight: bold;
      span{
        font-size: 1.2rem;
      }
    }
	.bottom-free{
		font-size: 1rem;
		color: #ffaf4a;
	}
    .catalogue-btn{
      position: absolute;
      top: 1rem;
      right: 0.9rem;
      width: 7.8rem;
      height: 2.3rem;
      background-color: #0C2F66;
      font-size: 0.8rem;
      text-align: center;
      line-height: 2.3rem;
      border-radius: 0.2rem;
      &:hover{
        cursor: pointer;
      }
    }
	.catalogue-btn-two{
		background: none;
		color: #0c2f66;
		font-size: 1.2rem;
	}
  }
  .train-introduce{
	display: flex;
    text-align: left;
    margin-top: 2rem;
    justify-content: space-between;
	.train-introduce-one{
		width: 47rem;
	}
	.about-courses{
		width: 20.35rem;
	}
    .introduce-title{
      img{
        width: 1.1rem;
        height: 1.1rem;
        margin-top: -3px;
      }
      span{
        font-size: 1.1rem;
        color: #333333;
        font-weight: bold;
        margin-left: 0.6rem;
      }
    }
    .line{
      width: 47rem;
      height: 0.05rem;
      background-color: #EFEFEF;
      margin-top: 1rem;
      margin-bottom: 1.2rem;
    }
    .introduce1{
		font-size: 0.7rem;
		color: #333333;
		line-height: 1rem;
     
    }
    .introduce2{
      margin-top: 1rem;
      .introduce2-box{
        font-size: 0.8rem;
        font-weight: bold;
        color: #0C2F66;
        display: flex;
        align-items: center;
        .introduce2-title{
          width: 0.3rem;
          height: 0.3rem;
          border-radius: 50%;
          background-color: #0C2F66;
        }
        span{
          margin-left: 0.4rem;
        }
      }
      .introduce2-feature{
        font-size: 0.7rem;
        margin-top: 0.8rem;
      }
    }
    .about-courses{
      // position: absolute;
      // top: 0.5rem;
      // right: 0;
      .about-title{
        font-size: 1.1rem;
        font-weight: bold;
        margin-bottom: 1.15rem;
      }
      .about-courses-box{
        display: flex;
        border-bottom: 1px solid #f5f5f5;
        padding-bottom: 1rem;
        margin-top: 1.1rem;
        &:hover{
          cursor: pointer;
        }
        img{
          width: 8.45rem;
          height: 4.4rem;
        }
        .box-content{
          margin-left: 1rem;
          .content-title{
            font-size: 0.7rem;
            width: 11.3rem;
            height: 2rem;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
          .content-timer{
            margin-top: 1rem;
            font-size: 0.6rem;
            span{
              color: #014F93;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 768px){
  .kjxz,.pxkcgm{
	width: auto;
	height: auto;
	font-size: 14px;
	padding: 5px;
  }
  .top-bigbg {
    height: 200px;
    .bigbg-box {
      position: relative;
      padding-top: 70px !important;
      padding-left: 70px !important;
      .bigbg-text {
        font-size: 25px;
      }
      .text-eng {
        font-size: 12px;
        letter-spacing: 1px;
      }
      .box-line {
        margin-top: 20px;
      }
    }
  }
  
  
  .all{
    position: relative;
    padding: 0;
    margin-bottom: 6.8rem;
    margin-top: 2rem;
    .play-box{
      display: block;
      align-items: center;
      // position: relative;
      .playimg{
        // position: relative;
        display: block;
        text-align: left;
		width: 100%;
		height: auto;
      }
	  video{
		  width: 100%;
	  }
      .play-stop{
        // position: absolute;
        // top: 45%;
        // left: 35%;
        &:hover{
          cursor: pointer;
        }
      }
    }
    .catalogue{
      
      width: 100%;
      height: 30.5rem;
      color: #FFFFFF;
      box-shadow: 0.55rem 0rem 0.95rem 0rem rgba(0,0,0,0.08);
      .catalogue-top{
        display: flex;
        align-items: center;
        margin-left: 1.2rem;
        height: 3.25rem;
        .circle{
          width: 0.4rem;
          height: 0.4rem;
          border-radius: 50%;
          background-color: #0C2F66;
        }
        .catalogue-title{
          color: #333330;
          font-size: 0.9rem;
          font-weight: bold;
          margin-left: 0.45rem;
        }
      }
    }
    .catalogue-list{
      width: auto;
      height: 23.15rem;
      font-size: 0.7rem;
      color: #666666;
      background-color: #F6F6F6;
      overflow-y: scroll;
      .catalogue-content{
        display: flex;
		flex-direction: row;
		justify-content: space-between;
		height: auto;
		text-align: left;
		font-size: 14px;
		padding:15px 20px;
        &:hover{
          cursor: pointer;
        }
        .content-time{
          font-size: 0.6rem;
        }
      }
    }
    .catalogue-bottom{
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;
      height: 4.1rem;
      margin-left: 1.2rem;
      .bottom-bar{
        font-size: 0.7rem;
        color: #333333;
        span{
          font-size: 0.8rem;
          color: #0C2F66;
          font-weight: bold;
        }
      }
      .bottom-price{
        color: #FF3350;
        font-size: 0.6rem;
        font-weight: bold;
        span{
          font-size: 1.2rem;
        }
      }
      .catalogue-btn{
        position: absolute;
        top: 1rem;
        right: 0.9rem;
        width: 7.8rem;
        height: 2.3rem;
        background-color: #0C2F66;
        font-size: 0.8rem;
        text-align: center;
        line-height: 2.3rem;
        border-radius: 0.2rem;
        &:hover{
          cursor: pointer;
        }
      }
	  
	  .catalogue-btn-two{
		background: none;
		color: #0c2f66;
		font-size: 1.2rem;
	  }
    }
    .train-introduce{
	  display: block;
      text-align: left;
      margin-top: 2rem;
      justify-content: space-between;
		.train-introduce-one{
			width: 100%;
		}
      .introduce-title{
        img{
          width: 1.1rem;
          height: 1.1rem;
          margin-top: -3px;
        }
        span{
          font-size: 1.1rem;
          color: #333333;
          font-weight: bold;
          margin-left: 0.6rem;
        }
      }
      .line{
        width: 100%;
        height: 0.05rem;
        background-color: #EFEFEF;
        margin-top: 1rem;
        margin-bottom: 1.2rem;
      }
      .introduce1{
        .introduce1-box{
          font-size: 0.8rem;
          font-weight: bold;
          color: #0C2F66;
          display: flex;
          align-items: center;
          .introduce1-title{
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            background-color: #0C2F66;
          }
          span{
            margin-left: 0.4rem;
          }
        }
        .introduce1-text{
          color: #333333;
          margin-top: 0.8rem;
          font-size: 0.7rem;
		  width: 100%;
        }
      }
      .introduce2{
        margin-top: 1rem;
        .introduce2-box{
          font-size: 0.8rem;
          font-weight: bold;
          color: #0C2F66;
          display: flex;
          align-items: center;
          .introduce2-title{
            width: 0.3rem;
            height: 0.3rem;
            border-radius: 50%;
            background-color: #0C2F66;
          }
          span{
            margin-left: 0.4rem;
          }
        }
        .introduce2-feature{
          font-size: 0.7rem;
          margin-top: 0.8rem;
        }
      }
      .about-courses{
        // position: absolute;
        // top: 0.5rem;
        // right: 0;
		margin-top: 30px;
        .about-title{
          font-size: 1.1rem;
          font-weight: bold;
          margin-bottom: 0px;
        }
        .about-courses-box{
          display: flex;
          border-bottom: 1px solid #f5f5f5;
          padding-bottom: 1rem;
          margin-top: 1.1rem;
          &:hover{
            cursor: pointer;
          }
          img{
            width: 8.45rem;
            height: 4.4rem;
          }
          .box-content{
            margin-left: 1rem;
            .content-title{
              font-size: 0.7rem;
              width: 100%;
              height: 2rem;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .content-timer{
              margin-top: 1rem;
              font-size: 0.6rem;
              span{
                color: #014F93;
              }
            }
          }
        }
      }
    }
  }
}
</style>