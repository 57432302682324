<template>
	<div
		class="inner"
		style="height: 100vh;padding-top: 150px;background: rgb(250 250 250);"
		v-wechat-title="$route.meta.title"
		v-html="info"
	></div>
</template>

<script>
import { GetServicesAndAgreementInfo } from '@/api/index.js';

export default {
	name: 'Agree',
	data() {
		return {
			info: '',
		};
	},
	mounted() {
		this.getInFo();
	},
	methods: {
		getInFo() {
			GetServicesAndAgreementInfo({ ServiceType: this.$route.query.ServiceType }).then((res) => {
				if (res.code == 0) {
					this.info = res.data.content;
				}
			});
		},
	},
};
</script>

<style scoped>
.inner {
	height: 100% !important;
	padding-top: 30px;
}
</style>
