<template>
	<div class="container-list" v-wechat-title="$route.meta.title">
		<div class="container-list-label fx-justify-content">
			<div>课程信息</div>
			<div>费用</div>
			<div>状态</div>
			<div>操作</div>
		</div>
		<div
			class="container-list-content fx-justify-content"
			v-for="item in courseInfo"
			:key="item.courseId"
		>
			<div>{{ item.courseName }}</div>
			<div>{{ item.onlyOrderState==true?item.price:'免费' }}</div>
			<div>{{ item.orderStateCn }}</div>
			<div class="btnColor">
				<div
					@click="
						Topurchase(
							item.billOrderId,
							item.courseId,
							item.butList[0].name
						)
					"
				>
					{{ item.butList[0].name }}
				</div>
				<div
					@click="
						orderState(item.orderMasterId, item.butList[1].name)
					"
				>
					{{ item.butList[1].name }}
				</div>
			</div>
		</div>
	  <!-- 底部分页 -->
	  <div class="page">
		<el-pagination
		  small
		  background
		  layout="prev, pager, next"
		  prev-text="上一页"
		  next-text="下一页"
		  :total="total"
		  :page-size='getListParams.pageSize'
		  class="mt-4"
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"

		/>
	  </div>
	</div>
	<!-- 微信支付 -->
	<el-dialog
		title="微信支付提示"
		v-model="centerDialogVisible"
		width="380px"
		center
	>
		<div class="text-center">
			<img :src="codeUrl" alt="" class="weixinImg" />
			<el-row class="text-center fx-justify-space-around">
				<el-button type="primary" round @click="ToCancel()"
					>取消支付</el-button
				>
				<el-button type="primary" round @click="ToPersonInfo()"
					>支付成功</el-button
				>
			</el-row>
		</div>
	</el-dialog>
</template>

<script>
import {
	GetCustomerOrderList,
	CancelCourseOrder,
	RemoveCourseOrder,
	CreateCourseOrder,
	QrCodePay,
	ContinueQrCodePay,
} from '@/api/index';
export default {
	name: 'courseInfo',
	components: {},
	data() {
		return {
			courseInfo: [], //课程订单信息列表
			centerDialogVisible: false, //微信弹窗是否显示
			outTradeNo: '', //商户单号
			codeUrl: '', //二维码地址
			total:0,
			getListParams:{ currentPage: 1, pageSize: 6 }
		};
		
	},
	mounted() {
		this.getCourseInfo(); //获取课程信息
	},
	methods: {
		// 初始页currentPage、初始每页数据数pagesize和数据data
		handleSizeChange (size) {
			this.getListParams.currentPage = size
			  this.getCourseInfo()
		},
		handleCurrentChange (currentPage) {
		  this.getListParams.currentPage = currentPage
		  this.getCourseInfo()
		},
		// 获取课程订单
		getCourseInfo() {
			this.courseInfo = [];
			GetCustomerOrderList(this.getListParams).then(
				(res) => {
					this.total = res.total
					if (res.code == 0) {
						res.data.map((item) => {
							let buttonPay;
							switch (item.orderState) {
								case 0: //待付款
									buttonPay = [
										{
											type: 1,
											name: '去支付',
										},
										{
											type: 2,
											name: '取消订单',
										},
									];
									break;
								case 1: //已付款
									buttonPay = [
										{
											type: 1,
											name: '支付成功',
										},
										{
											type: 2,
											name: '申请发票',
										},
									];
									break;
								case 2: //已完成
									buttonPay = [
										{
											type: 1,
											name: '支付成功',
										},
										{
											type: 2,
											name: '申请发票',
										},
									];
									break;
								default: //已关闭
									buttonPay = [
										{
											type: 1,
											name: '重新购买',
										},
										{
											type: 2,
											name: '删除订单',
										},
									];
									break;
							}
							item.butList = buttonPay;
							this.courseInfo.push(item);
						});
						//console.log(this.courseInfo, '------this.courseInfo');
					}
				}
			);
		},
		// 支付成功， 去支付 ，重新购买
		Topurchase(billOrderId, courseId, name) {
			// 如果为已支付 点击则进行下单
			if (name == '去支付') {
				this.continuePay(billOrderId); //继续下单
			}
			if (name == '重新购买') {
				this.purchase(courseId);
			}
		},
		// 申请发票 取消订单 删除订单
		orderState(orderMasterId, name) {
			// 如果为取消订单，则点击取消
			if (name == '取消订单') {
				this.cancelOrder(orderMasterId);
			} else if (name == '删除订单') {
				this.deleteOrder(orderMasterId);
			} else if (name == '申请发票') {
				//console.log('申请发票');
			}
		},
		//删除订单
		deleteOrder(orderMasterId) {
			//console.log(orderMasterId, '------OrderMasterId');
			RemoveCourseOrder({ orderMasterId: orderMasterId }).then((res) => {
				if (res.code == 0) {
					this.$message.success({
						message: res.msg,
						type: 'success',
					});
					this.getCourseInfo();
				} else {
					this.$message.error({
						message: res.msg,
					});
					this.getCourseInfo();
				}
			});
		},
		// 取消订单
		cancelOrder(orderMasterId) {
			CancelCourseOrder({ orderMasterId: orderMasterId }).then((res) => {
				if (res.code == 0) {
					this.$message.success({
						message: res.msg,
						type: 'success',
					});
				}
				this.getCourseInfo(); //获取课程订单信息
			});
		},
		// 课程下单 包括重新购买
		purchase(courseId) {
			CreateCourseOrder({ CourseId: courseId }).then((res) => {
				if (res.code == 0) {
					this.outTradeNo = res.data.outTradeNo;

					this.$message.success({
						message: res.msg,
						type: 'success',
					});
					this.wxYuPay(this.outTradeNo);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 继续下单
		continuePay(billOrderId) {
			ContinueQrCodePay({ billOrderId: billOrderId }).then((res) => {
				if (res.code == 0) {
					this.outTradeNo = res.data.outTradeNo;
					this.$message.success({
						message: res.msg,
						type: 'success',
					});
					this.wxYuPay(this.outTradeNo);
				} else {
					this.$message.error(res.msg);
				}
			});
		},
		// 微信预下单
		wxYuPay(outTradeNo) {
			let data = {
				outTradeNo: outTradeNo,
				description: 'string',
				total: 0,
				notifyUrl: 'string',
				payerClientIp: 'string',
				orderType: 0, // 0是课程订单 1报告订单 2标准订单
			};
			QrCodePay(data).then((res) => {
				if (res.code == 0) {
					this.codeUrl = res.codeUrl;
					this.centerDialogVisible = true;
				}
				// else {
				// 	this.$message.error(res.msg);
				// }
			});
		},
		// 微信二维码取消支付 假取消 只是隐藏弹窗
		ToCancel() {
			this.$message.success({
				message: '取消成功',
				type: 'success',
			});
			this.centerDialogVisible = false;
			this.getCourseInfo(); //获取课程订单信息
		},
		// 支付成功
		ToPersonInfo() {
			this.centerDialogVisible = false;
			this.getCourseInfo(); //获取课程订单信息
		},
	},
};
</script>
<style lang="less" scoped>

.page {
	margin: 5.25rem 0;
	display: flex;
	justify-content: center;
	align-items: end;
	::v-deep .el-pagination.is-background .el-pager li {
	  width: 1.1rem;
	  height: 1.1rem;
	  background-color: #ffffff;
	  color: #999999;
	  border: 1px solid #d9d9d9;
	  &.is-active {
		background-color: #0c2f66;
		color: #ffffff;
	  }
	}
	::v-deep .el-pagination.is-background .btn-prev,
	::v-deep .btn-next.is-last {
	  width: 2.8rem;
	  height: 1.5rem;
	  background-color: #0c2f66;
	  color: #ffffff;
	  &:hover {
		background-color: #ffffff;
		color: #0c2f66;
		border: 1px solid #0c2f66;
	  }
	}
	::v-deep .el-pagination.is-background .btn-prev {
	  margin-right: 1rem;
	}
	::v-deep .btn-next.is-last {
	  margin-left: 1rem;
	}
}
.weixinImg {
	width: 200px;
	height: 200px;
}
.container-list-label {
	padding: 10px 0;
	background-color: #e5e5e5;
}
.container-list-label div {
	font-weight: bold;
}
.container-list-label diV:nth-child(1) {
	flex: 2;
	text-align: center;
}
.container-list-label diV:nth-child(2) {
	flex: 1;
	text-align: center;
}
.container-list-label diV:nth-child(3) {
	flex: 1;
	text-align: center;
}
.container-list-label diV:nth-child(4) {
	flex: 1;
	text-align: center;
}

.container-list-content {
	padding: 10px 0;
	border-bottom: 1px solid;
	margin: 0 auto;
	margin-bottom: 12px;
}
.container-list-content diV:nth-child(1) {
	flex: 1;
	text-align: center;
}
.container-list-content div:nth-child(1) {
	flex: 2;
	text-align: center;
}
.container-list-content div:nth-child(2) {
	flex: 1;
	text-align: center;
}
.container-list-content div:nth-child(3) {
	flex: 1;
	text-align: center;
}
.container-list-content div:nth-child(4) {
	flex: 1;
	text-align: center;
}
.btnColor {
	text-align: center;
}
.btnColor div {
	cursor: pointer;
}
.btnColor div:nth-child(1) {
	width: 120px;
	padding: 5px 10px;
	border-radius: 12px;
	background-color: #0b3d8a;
	color: #fff;
	margin: 0 auto;
	margin-bottom: 10px;
}
.btnColor div:nth-child(2) {
	width: 120px;
	padding: 5px 10px;
	border-radius: 12px;
	background-color: #a6a6a6;
	color: #fff;
	margin: 0 auto;
}
@media (min-width: 320px) and (max-width: 768px) {
  .container-list-label diV{
    font-size: 13px;
  }
	.container-list-content div:nth-child(1),
	.container-list-content div:nth-child(2),
	.container-list-content div:nth-child(3) {
		font-size: 12px;
	}
	.btnColor div:nth-child(1),
	.btnColor div:nth-child(2) {
		width: 35px;
		padding: 2px 0;
	}
	.btnColor div:nth-child(1), .btnColor div:nth-child(2){
		width: 76px;
	}
}
</style>
