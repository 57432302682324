<template>
	 <div class="header-search fx-center mb-bt-25">
		<div class="header-search-block">
			 <div class="header-search-block-one fx-justify-space-around mb-bt-065">
				 <div class="one-left fx-center"><label>按企业查询：</label></div>
				 <div class="one-center "><input type="text" placeholder="企业名称" v-model="qy"></div>
				 <div class="one-right"><button @click="qiyemingcheng()">查 询</button><button @click="qiyemingchengcz()">重 置</button></div>
			 </div>
			<div style="color: #ef0f0f;font-weight: 700;" v-show="tishi!=''">{{tishi}}</div>
			 <div class="header-search-block-one fx-justify-space-around mb-bt-05">
				 <div class="one-left fx-center"><label>按编号查询：</label></div>
				 <div class="one-center "><input type="text" placeholder="证书编号" v-model="number"></div>
				 <div class="one-right"><button @click="zhangshubianhao()">查 询</button><button @click="zhangshubianhaocz()">重 置</button></div>
			 </div>
			 <div class="header-search-block-one  ">
				 <!-- <div class="one-left "><label></label></div> -->
				 <div class="one-center mb-lf-557"><img src="@/assets/icons/icon-gantanhao.png"/><label>温馨提示：本查询为精确查询，请输入完整企业名称或完整证书编号</label></div>
				
				<!-- <div class="one-right"><label></label></div> -->
			 </div>
		 </div>
	 </div>
	 
	 <div class="zhengshu-list">
	 	<div class="zhengshu-list-block fx-justify-space-between mb-bt-25" v-for="(item,index) in list">
			<div class="zhengshu-list-block-left cursorpointer" @click="openAlerttwo(index)">
					<div class="zhengshu-one-row">
						<div class="row-one">委托人名称</div>
						<div class="row-two">{{item.client}}</div>
						<div class="row-three">证书编号</div>
						<div class="row-four">{{item.zhengshu_num}}</div>
					</div>
					<div class="zhengshu-one-row">
						<div class="row-one">产品名称</div>
						<div class="row-two">{{item.product}}</div>
						<div class="row-three">标识</div>
						<div class="row-four">{{item.biaoshi}}</div>
					</div>
					<div class="zhengshu-one-row">
						<div class="row-one">证书到期日期</div>
						<div class="row-two">{{item.yx_date2}}</div>
						<div class="row-three">证书状态</div>
						<div class="row-four">
							<button class="button-youxiao">{{zsStatus[item.status]}}</button>
						</div>
					</div>
			</div>
			<div class="zhengshu-list-block-right" v-if="item.certificate_path!=undefined && item.certificate_path!=''">
				<!-- <img :src="item.certificate_path"/> -->
				<img :src="item.certificate_path" @click="imgamplify"/>
			</div>
		</div>
	 </div>
	 <div class="page">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div>
	 <div  class="mask-zhengshu" v-if="isimgamplify">
		<img src="../../assets/icons/icon-close-two.png" class="icon-close cursorpointer" @click="closeAlerttwo" style="position: absolute;top: 2%;right: 2%;width: 50px;"/>
		<img :src="imgamplifyurl"/>
	 </div>
	 <div class="mask-zhengshu" v-if="isAlertMasktwo">
		 <div class="mask-zhengshu-content">
			 <div class="mask-zhengshu-content-header">
				 <img src="../../assets/icons/icon-close-blue.png" class="icon-close cursorpointer" @click="closeAlerttwo"/>
			 </div>
			 <div class="mask-zhengshu-content-content">
				<div class="zhengshu-one-row">
					<div class="row-one">证书编号</div>
					<div class="row-two">{{info.zhengshu_num}}</div>
					<div class="row-three">证书状态</div>
					<div class="row-four"><button class="button-youxiao">{{zsStatus[info.status]}}</button></div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">委托人名称</div>
					<div class="row-two">{{info.client}}</div>
					<div class="row-three">委托人注册地址</div>
					<div class="row-four">{{info.client_address}}</div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">制造商名称</div>
					<div class="row-two">{{info.manufacturer}}</div>
					<div class="row-three">制造商注册地址</div>
					<div class="row-four">{{info.manufacturer_address}}</div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">生产企业名称</div>
					<div class="row-two">{{info.producer}}</div>
					<div class="row-three">生产地址</div>
					<div class="row-four">{{info.producer_address}}</div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">产品名称</div>
					<div class="row-two">{{info.product}}</div>
					<div class="row-three">标识</div>
					<div class="row-four">{{info.biaoshi}}</div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">初次发证日期</div>
					<div class="row-two">{{info.fz_date}}</div>
					<div class="row-three">本次变更日期</div>
					<div class="row-four">{{info.bg_date}}</div>
				</div>
				<div class="zhengshu-one-row">
					<div class="row-one">本证书有效期起始</div>
					<div class="row-two">{{info.yx_date1}}</div>
					<div class="row-three">本证书有效期终止</div>
					<div class="row-four">{{info.yx_date2}}</div>
				</div>
			 </div>
		 </div>
	 </div>
</template>

<script>
import {ProjectGreenjcCertificateList,ProjectGreenjcCertificate} from '@/api/index'
import searchIndustry from '@/components/searchIndustry.vue' 
export default {
  name: "PolicyDetailView",
  components:{
	searchIndustry
  },
  data() {
    return {
		list:[],
		info:{},
		zsStatus:{
			1:'有效',
			2:'暂停',
			3:'撤销',
			4:'注销',
		},
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 6,
		  "sysContantId":''
		},
		total:0,
		isAlertMasktwo:false,
		qy:'',
		number:'',
		tishi:'',
		isimgamplify:false,
		imgamplifyurl:''
    };
  },
  props: {
    id: String,
  },
  mounted() {
	  this.getProjectGreenjcCertificateList()
  },
  methods:{
	imgamplify(url){
		this.imgamplifyurl = url
		this.isimgamplify = true
	},
	qiyemingchengcz(){
		this.qy = ''
		this.tishi = ''
		this.getProjectGreenjcCertificateList()
	},
	zhangshubianhaocz(){
		this.number = ''
		this.tishi = ''
		this.getProjectGreenjcCertificateList()
	},
	zhangshubianhao(){
		if(this.number==''){
			this.tishiqy = '证书编号不能为空'
			return
		}
		this.tishiqy = ''
		this.getProjectGreenjcCertificate({number:this.number})
	},
	qiyemingcheng(){
		if(this.qy==''){
			this.tishi = '企业名称不能为空'
			return
		}
		this.tishi = ''
		this.getProjectGreenjcCertificate({qy:this.qy })
		
	},
	getProjectGreenjcCertificateList(){
		let ProjectGreenjcCnToken = window.localStorage.getItem('ProjectGreenjcCnToken')
		ProjectGreenjcCertificateList({token:ProjectGreenjcCnToken}).then(res=>{
			//console.log(res,'=============ProjectGreenjcCertificateProjectGreenjcCertificateProjectGreenjcCertificate')
			this.list = res.data
		})
	},
	getProjectGreenjcCertificate(searchWhere){
		let ProjectGreenjcCnToken = window.localStorage.getItem('ProjectGreenjcCnToken')
		ProjectGreenjcCertificate(searchWhere).then(res=>{
			//console.log(res,'=============ProjectGreenjcCertificateProjectGreenjcCertificateProjectGreenjcCertificate')
			this.list = res.data
		})
	},
	closeAlerttwo(){
		this.isAlertMasktwo = false
		this.isimgamplify = false
	},
	openAlerttwo(index){
		this.info = this.list[index]
		this.isAlertMasktwo = true
	},
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.queryParma.currentPage = size
		this.getProjectGreenjcCertificateList()
	},
	handleCurrentChange (currentPage) {
		this.queryParma.currentPage = currentPage
		this.getProjectGreenjcCertificateList()
	},
	searchFun(val){
		//console.log(val,'===============w333')
		this.queryParma.sysContantId = val
		this.getProjectGreenjcCertificateList()
	},
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/datadetail?id='+item.id)
		}
	},
  }
};
</script>

<style lang="less" scoped>
	.header-search{
		background: #F4F4F4;
		height: 13.25rem;
		.header-search-block{
			.mb-bt-065{
				margin-bottom: 0.65rem;
			}
			.mb-bt-05{
				margin-bottom: 0.35rem;
			}
			.mb-lf-557{
				margin-left: 5.57rem !important;
			}
			
			.header-search-block-one{
				.one-left{
					label{
						width: 5.35rem;
						font-size: 0.9rem;
						font-weight: 600;
						color: #141414;
						line-height: 1.4rem;
					}
				}
				.one-right{
					button{
						width: 4.8rem;
						height: 2.8rem;
						background: #0C2F66;
						font-size: 0.8rem;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 1.1rem;
					}
					button:first-child{
						margin-right: 10px;
					}
					label{
						width: 7.8rem;
					}
				}
				.one-center{
					margin-left: 0.4rem;
					margin-right: 1.2rem;
					width: 25rem;
					img{
						width: 0.9rem;
						height: 0.9rem;
						margin-right: 0.35rem;
					}
					label{
						font-size: 0.8rem;
						color: #555555;
						line-height: 1.1rem;
					}
					input {
						width: 25rem;
						height: 2.75rem;
						border-radius: 0.3rem;
						background: rgba(255,255,255,0.45);
						border: 0.06rem solid #777777;
						font-size: 0.88rem;
						color: #141414;
						line-height: 1.1rem;
						letter-spacing: 1px;
						text-indent: 1.6rem;
					}
					input:focus{
						outline: none;
					}
					
					input::-webkit-input-placeholder {
						font-size: 0.8rem;
						font-size: 0.88rem;
						color: #141414;
						line-height: 1.1rem;
						letter-spacing: 1px;
					}
				}
			}
		}
	}
	
	.zhengshu-list{
		.zhengshu-list-block{
			.zhengshu-list-block-left{
				width: 44.5rem;
				border: 0.05rem solid #EFEFEF;
				.zhengshu-one-row{
					display: flex;
					height: 3.8rem;
					font-size: 0.8rem;
					>div{
						border: 0.05rem solid #EFEFEF;
						display: flex;
						align-items: center;
					}
					.row-one{
						text-align: center;
						background-color: #FAFAFA;
						width: 6.43rem;
						font-weight: 700;
						justify-content: center;
					}
					.row-two{
						width: 13.5rem;
						padding-left: 1rem;
					}
					.row-three{
						text-align: center;
						background-color: #FAFAFA;
						width: 6.43rem;
						font-weight: 700;
						justify-content: center;
					}
					.row-four{
						width: 18.05rem;
						padding-left: 1rem;
					}
					button{
						width: 5.15rem;
						height: 2.1rem;
						background: #A1A1A1;
						border: none;
						border-radius: 0.2rem;
						font-size: 0.8rem;
						color: #FFFFFF;
						line-height: 1.1rem;
					}
					.button-youxiao{
						background: #0C2F66;
					}
				}
			}
			.zhengshu-list-block-right{
				width: 8.15rem;
				height: 11.43rem;
			}
		}
	}
	.mb-bt-25{
		margin-bottom: 2.5rem;
	}
	
	.mask-zhengshu{
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.mask-zhengshu .mask-zhengshu-content{
		width: 57.5rem;
		height: 34.83rem;
		background: #FFFFFF;
		border-radius: 0.4rem;
		padding: 1.5rem;
		.icon-close{
			width: 1.3rem;
			height: 1.3rem;
		}
		.mask-zhengshu-content-header{
			text-align: right;
		}
		.mask-zhengshu-content-content{
			margin: 2.7rem;
			.zhengshu-one-row{
				display: flex;
				height: 3.6rem;
				font-size: 0.8rem;
				>div{
					border: 0.05rem solid #EFEFEF;
					display: flex;
					align-items: center;
				}
				.row-one{
					text-align: center;
					background-color: #FAFAFA;
					width: 7.8rem;
					font-weight: 700;
					justify-content: center;
				}
				.row-two{
					width: 14.7rem;
					padding-left: 1rem;
				}
				.row-three{
					text-align: center;
					background-color: #FAFAFA;
					width: 7.8rem;
					font-weight: 700;
					justify-content: center;
				}
				.row-four{
					width: 18.45rem;
						padding-left: 1rem;
					button{
						width: 5.15rem;
						height: 2.1rem;
						background: #A1A1A1;
						border: none;
						border-radius: 0.2rem;
						font-size: 0.8rem;
						color: #FFFFFF;
						line-height: 1.1rem;
					}
					.button-youxiao{
						background: #0C2F66;
					}
				}
			}
		}
		
	}
	/**********************************/
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}

	@media (max-width: 768px){
		.header-search .header-search-block .header-search-block-one .one-left{
			display: none;
		}
		.header-search .header-search-block .header-search-block-one .one-center{
			width: 100%;
			margin-left: 0;
			margin-right: 20px;
		}
		.header-search .header-search-block .header-search-block-one .one-center label{
			font-size: 10px;
		}
		
		.header-search .header-search-block .header-search-block-one .one-center input{
			width: 100%;
			height: 40px;
			font-size: 11px;
		}
		.header-search .header-search-block .header-search-block-one .one-right button{
			width: 75px;
			height: 40px;
			font-size: 10px;
		}
		.header-search{
			padding: 15px;
			height: auto;
		}
		.header-search .header-search-block .mb-lf-557{
			margin-left: 0 !important;
			display: flex;
		}
		.mb-bt-25{
			margin-bottom: 20px;
		}
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left{
			width: 100%;
		}
		
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row .row-one,.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row>div{
			height: 35px;
		    // line-height: 35px;
		    font-size: 11px;
		}
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row{
			height: auto;
			line-height: 50px;
			flex-wrap: wrap;
		}
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row .row-one,.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row .row-three,.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row .row-one,.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row .row-three{
			width: 30%;
		}
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row .row-two,.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row .row-four,.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row .row-two,.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row .row-four{
			width: 70%;
		}
		.zhengshu-list .zhengshu-list-block .zhengshu-list-block-left .zhengshu-one-row button,.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row .row-four button{
			width: auto;
			height: auto;
			font-size: 10px;
		}
		.mask-zhengshu .mask-zhengshu-content{
			width: 98%;
			padding: 15px;
			height: auto;
			padding-bottom: 30px;
		}
		.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content{
			margin: 0;
		}
		.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row{
			height: auto;
			font-size:11px;
			flex-wrap: wrap;
		}
		.mask-zhengshu .mask-zhengshu-content .mask-zhengshu-content-content .zhengshu-one-row>div{
			min-height: 35px;
			// line-height: 35px;
		}
		.one-right{
			display: flex;
		}
		
		.header-search .header-search-block .header-search-block-one .one-center input::-webkit-input-placeholder {
			font-size: 12px;
			color: #141414;
			line-height: 1.1rem;
			letter-spacing: 1px;
		}
	}
</style>