<template>
  <router-view/>
</template>

<style>
@font-face {
	font-family: 'AlibabaPuHuiTi';
	src: url('@/assets/AlibabaPuHuiTi-3-55-Regular.woff');
}
@import url('assets/css/style.css');
@import url('assets/css/animation.css');
@import url('assets/css/grid-self.css');


</style>
