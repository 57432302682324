<template>
  <div class="top">
	<div class="title" v-if="pageType!=1">
	  <img src="@/assets/policy/编组.png" alt="" />
	  <span>行业:</span>
	</div>
	<div class="title-list">
	  <ul>
		<li
		  v-for="(item, index) in titleList"
		  :key="index"
		  :class="navListIndex == index ? 'nav-select' : ''"
		  @click="navSelect(index)"
		>
		  {{ item.name }}
		</li>
	  </ul>
	</div>
  </div>
</template>

<script>
	
import {FindConstantBySysContantId} from '@/api/index'
export default {
  name: 'Agree',
  components: {},
  props: {
    ArticleTypeId: String,
	pageType:Number
  },
  data() {
  	return {
		centerDialogVisible: false,
		titleList:[],
		navListIndex:0
	}
  },
  mounted() {
	  this.getFindConstantBySysContantId()
  },
  methods: {
	navSelect(index){
	  this.navListIndex = index
	  this.$emit('searchFun',this.titleList[index].id)
	},
	getFindConstantBySysContantId(){
		FindConstantBySysContantId({ArticleTypeId:this.ArticleTypeId}).then(res=>{
			res.unshift({
				id:'',
				name:'全部'
			})
			this.titleList = res
		})
	}
	  
  }
};
</script>

<style lang="less" scoped>
	
	.top {
	  text-align: left;
	  display: flex;
	  font-size: 0.7rem;
	  margin-bottom: 1rem;
	  height: 48px;
	  justify-content: left;
	  align-items: center;
	  
	  .title {
			min-width: 60px;
	    img {
	      width: 14px;
	      height: 14px;
	      margin-top: -2px;
	    }
	    span {
	      margin-left: 0.4rem;
	    }
	  }
	  .title-list {
	    ul {
	      display: flex;
			flex-wrap: wrap;
			margin-bottom: 0rem ;
			padding-left: 0rem;
	      li {
			  margin-bottom: 5px;
	        list-style-type: none;
	        margin-left: 0.5rem;
	        height: 1.4rem;
	        line-height: 1.4rem;
	        text-align: center;
	        border-radius: 0.2rem;
	        border: 0.05rem solid #dfe3e5;
	        padding: 0 0.55rem;
	        &:hover {
	          cursor: pointer;
	        }
	      }
	    }
	  }
	}
	
	.nav-select{
	    border: 0.05rem solid #014f93 !important;
	    color: #014f93;
	}
	
	@media (max-width: 768px){
		.top{
			height: auto;
			align-items: flex-start;
			margin-top: 10px;
		}
	}
</style>
