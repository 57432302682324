<template>
	<div class="tab-title-block" v-if="titleList">
		<span v-for="(item, index) in titleList" :key="index"
			:class="titleListIndex == index ? 'tab-title-block-select' : ''" @click="blockTabone(item,index)">{{ item.name }}
			<div class="tab-title-block-select-line"></div>
		</span>
	</div>
	 <div class="new-list  text-left">
		<!-- <div class="new-content" v-if="titleListIndex==0">
			 <div v-html="info.data"></div>
		 </div> -->
		 
		 <div class="new-content tab text-center" v-if="titleListIndex==0">
		 			<div class="row tabth">
		 				<div class="col-6 text-left">标题</div>
		 				<div class="col-3">创建时间</div>
		 				<div class="col-3">操作</div>
		 			</div>
		 			<div class="row tabtd" v-for="(item,index) in info">
		 				<div class="col-6 text-left">{{item.title}}</div>
		 				<div class="col-3">{{item.createTime.split('T')[0]}}</div>
		 				<div class="col-3">
		 					<span class="cursorpointer see-info" @click="gotoDetail(item)">查看详细</span>
		 				</div>
		 			</div>
		 </div>
		 <div class="new-content tab text-center" v-if="titleListIndex==1">
			<div class="row tabth">
				<div class="col-6 text-left">申请表标题</div>
				<div class="col-3">上传时间</div>
				<div class="col-3">下载申请表</div>
			</div>
			<div class="row tabtd" v-for="(item,index) in list">
				<div class="col-6 text-left">{{item.title}}</div>
				<div class="col-3">{{item.createTime.split('T')[0]}}</div>
				<div class="col-3">
					<img src="../../assets/icons/icon-down-file.png" class="downFile cursorpointer" @click="downFile(item.toUrl)"/>
				</div>
			</div>
		 </div>
		 
	 </div>
	 
</template>

<script>
import {GetRengZhengZiYuan,GetShenQingBiaoApiData,FindConstantBySysContantId} from '@/api/index'
export default {
  name: "PolicyDetailView",
  data() {
    return {
		list:[],
		info:{},
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 100,
		  "sysContantId": ""
		},
		total:0,
		titleList:[],
		titleListIndex:0
    };
  },
  props: {
    id: String,
  },
  mounted() {
	  this.getFindConstantBySysContantId()
	  this.getList()
  },
  methods:{
	downFile(url){
	  window.open(url)
	},
	blockTabone(item,index) {
		this.titleListIndex = index
	},
	getList(){
		this.getGetRengZhengZiYuan()
		this.getGetShenQingBiaoApiData()
	},
	getFindConstantBySysContantId(){
		FindConstantBySysContantId({ArticleTypeId:this.id}).then(res=>{
			this.titleList = res
		})
	},
	getGetRengZhengZiYuan(){
		GetRengZhengZiYuan({}).then(res=>{
			this.info = res.data
		})
	},
	getGetShenQingBiaoApiData(){
		GetShenQingBiaoApiData(this.queryParma).then(res=>{
			if(res.code == 0){
				this.list = res.data
			}
			this.total = res.total
		})
	},
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/resourcecentredetail?id='+item.id+'&pid='+this.id+'&titlename=认证资源&titlenametwo=认证实施规则')
		}
	}
  }
};
</script>

<style lang="less" scoped>
	.see-info{
		color: #0C2F66;
	}
	.tab{
		.row{
			padding:0.95rem 1.35rem;
		}
		.tabth{
		    background: #FAFAFA;
			font-size: 1rem;
			font-weight: 700;
			color: #141414;
			line-height: 1.3rem;
		}
		.tabtd{
			font-size: 1rem;
			color: #333333;
			line-height: 1.3rem;
			border-bottom: 1px solid  #EFEFEF;
		}
		.downFile{
			width: 0.72rem;
			height: 0.72rem;
		}
	}
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}
	@media (max-width: 768px){
		.tab-title-block{
			padding-top: 0px;
		}
		.tab .tabtd{
			font-size: 11px;
		}
		.tab .tabth{
			font-size: 14px;
		}
	// 	.new-list{
	// 		padding:0 10px;
	// 	}
	}
</style>