<template>
	<el-container v-wechat-title="$route.meta.title">
		<el-form ref="formUser" :model="formUser" label-width="150px">
			<el-form-item label="*发票抬头" >
				<el-input
					v-model="formUser.invoiceTitle"
					placeholder="请输入发票抬头"
				></el-input>
			</el-form-item>
			<el-form-item label="*统一社会信用代码" label-width="150px">
				<el-input
					v-model="formUser.creditCode"
					placeholder="请输入统一社会信用代码"
				></el-input>
			</el-form-item>
			<el-form-item label="开户银行：" label-width="150px">
				<el-input
					v-model="formUser.depositBank"
					placeholder="请输入开户银行"
				></el-input>
			</el-form-item>
			<el-form-item label="银行账号：" label-width="150px">
				<el-input
					v-model="formUser.creditCardNumbers"
					placeholder="请输入银行账号"
				></el-input>
			</el-form-item>
			<el-form-item label="工商注册地址：" label-width="150px">
				<el-input
					v-model="formUser.businessRegisteredAddress"
					placeholder="请输入工商注册地址"
				></el-input>
			</el-form-item>
			<el-form-item label="注册电话：" label-width="150px">
				<el-input
					v-model="formUser.registrationPhone"
					placeholder="请输入注册电话"
				></el-input>
			</el-form-item>
			<el-form-item label="发票收件人：" label-width="150px">
				<el-input
					v-model="formUser.invoiceSender"
					placeholder="请输入发票收件人"
				></el-input>
			</el-form-item>
			<el-form-item label="发票收件人联系方式：" label-width="150px">
				<el-input
					v-model="formUser.invoiceSenderPhone"
					placeholder="请输入发票收件人联系方式"
				></el-input>
			</el-form-item>
			<el-form-item label="发票收件地址：" label-width="150px">
				<el-input
					v-model="formUser.invoiceSenderAddress"
					placeholder="请输入发票邮件地址"
				></el-input>
			</el-form-item>
		</el-form>
		<div class="text-center">
			<el-button
				class="submitBtn"
				type="primary"
				@click="formSubmit('formUser')"
				>保存</el-button
			>
		</div>
	</el-container>
</template>

<script>
import { CreateOrEditInvoice, GetInvoiceInfo } from '@/api/index';
export default {
	name: 'invoice',
	components: {},
	data() {
		return {
			formUser: {
				Id: '1',
				invoiceTitle: '', //发票抬头
				creditCode: '', //统一社会信用代码：
				depositBank: '', //开户银行
				creditCardNumbers: '', //银行卡号
				businessRegisteredAddress: '', //工商注册地址
				registrationPhone: '', //注册电话
				invoiceSender: '', //发票收件人
				invoiceSenderPhone: '', //发票收件人电话
				invoiceSenderAddress: '', //发票邮件地址
			},
			rules: {
				ID: [
					{
						required: true,
						pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
						message: '请输入正确的身份证号',
						trigger: 'blur',
					},
				],
				phone: [
					{
						required: true,
						pattern:
							/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					},
				],
				email: [
					{
						required: true,
						pattern:
							/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						message: '请输入正确的邮箱',
						trigger: 'blur',
					},
				],
			},
		};
	},
	mounted() {
		// console.log(localStorage.getItem('token'), '-------------------token');
		this.getUserInfo(); //获取个人信息
	},
	methods: {
		// 获取个人信息
		getUserInfo() {
			GetInvoiceInfo().then((res) => {
				if (res.code == 0) {
					this.formUser.invoiceTitle = res.data.invoiceTitle;
					this.formUser.creditCode = res.data.creditCode;
					this.formUser.depositBank = res.data.depositBank;
					this.formUser.businessRegisteredAddress =
						res.data.businessRegisteredAddress;
					this.formUser.registrationPhone = res.data.registrationPhone;
					this.formUser.invoiceSender = res.data.invoiceSender;
					this.formUser.invoiceSenderPhone = res.data.invoiceSenderPhone;
					this.formUser.invoiceSenderAddress =
						res.data.invoiceSenderAddress;
					this.formUser.creditCardNumbers =
						res.data.creditCardNumbers;
						
				}
				// this.$forceUpdate();
				// console.log(res, '-------------个人信息');
			});
		},
		// 保存个人信息 更新
		formSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					CreateOrEditInvoice(this.formUser).then((res) => {
						if (res.code == 0) {
							this.$message.success({
								message: res.msg,
							});
							this.getUserInfo();
						} else {
							this.$messgae.error(res.msg);
						}
						// console.log(res);
					});
				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
	},
};
</script>

<style scoped>
	.el-container{
		display: block;
	}
.submitBtn {
	width: 200px;
	height: 50px;
	border-radius: 12px;
	color: #fff;
	background-color: #0d2f66;
}
::v-deep(.el-form-item__label) {
	height: 52px;
	line-height: 52px;
}
::v-deep(.el-input){
height: 100%;
}
@media (min-width: 320px) and (max-width: 768px){
	
  ::v-deep(.el-form-item__label){
    width: 100px !important;
    font-size: 12px;
	line-height: 25px !important;
    align-items: center;
    text-align: right;
	margin-right: 10px;
  }
  ::v-deep(.el-form-item){
    margin-bottom: -2px !important;
  }
  ::v-deep(.el-input__wrapper){
    height: 35px;
  }
  .submitBtn{
    width: 90px;
    height: 40px;
  }
  
}
</style>
