<template>
  <!-- 顶部图片 -->
  <div class="container text-center home-container">
    <!-- 培训计划、精品课程 -->
    <div class="container text-center home-container">
      <div class="tab-title-block selectplan">
        <span
          v-for="(item, index) in titleList"
          :key="index"
          class="plannav"
          :class="titleIndex == index ? 'tab-title-block-select' : ''"
          @click="chooseTitleIndex(index)"
          >{{ item.name }}
          <div class="tab-title-block-select-line"></div>
        </span>
      </div>
    </div>

    <!-- 行业、月份、状态导航 -->
    <div class="navigation-content first">
      <div class="navigation "  v-for="(sun,i) in trainingPlanSerchList">
        <div class="img-box">
          <img :src="sun.icon" alt="" />
          <span>{{sun.name}}:</span>
        </div>
        <div class="nav-titlelist">
          <ul>
            <li
              v-for="(item, index) in sun.labelist"
              :key="index"
              :class="sun.selecid == item.id ? 'nav-select' : ''"
              @click="tradeSelect(sun.keyName,i,item.id)"
            >
              {{ item.name }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- 计划列表 -->
    <div class="grid-2 grid-md-3  grid-gl-4 trainingplan">
      <div v-for="(  item, index  ) in trainingPlanList " :key="index" class="planbox" @click="goToTrainDetail(item.id)">
          <div class="theTopbox">
            <img :src="item.coursePicturlUrl" class="planbox-img"/>
            <div class="theTop" v-if="item.isTop==1">
              <img src="../assets/public/star.png" alt="" class="theTop-star">
              <div class="theTop-text">置顶</div>
            </div>
          </div>
          <div class="plancontent">
            <div class="plantitle">{{ item.courseName }}</div>
            <div class="planstatus">
              <div class="bottom-price">
				<div v-if="item.isFree">
				  <span class="icon-money">￥</span
				  ><span class="price">{{ item.price }}</span>
				</div>
				<span class="free" v-else>免费</span>
			  </div>
              <div class="plantime-box">
                <span>{{ item.releaseTime.split('T')[0]}}</span>
               <!-- <div class="planbox-line"></div>
                <span>{{ item.durationTime }}</span> -->
              </div>
            </div>
          </div>
      </div>
    </div>
  </div>
  <!-- 底部分页 -->
  <div class="page">
	<el-pagination
	  small
	  background
	  layout="prev, pager, next"
	  prev-text="上一页"
	  next-text="下一页"
	  :total="total"
	  :page-size='trainingPlanSerch.pageSize'
	  class="mt-4"
	  @size-change="handleSizeChange"
	  @current-change="handleCurrentChange"

	/>
  </div>
</template>

<script>
import {TrainingPlanGetNavTagList,TrainingPlanGetWebSiteTrainingPlanData,CoursesGetWebSiteCourseList,CoursesGetNavTagData} from '@/api/index'
export default {
  name: "TrainingView",
  data() {
    return {
	  total:0,
      // 培训计划、精品课程
      titleList: [
        {
          id: '3',
          name: "培训计划",
        },
        {
          id: '6',
          name: "精品课程",
        },
      ],
      titleIndex: 1,
      tradeIndex: 0,
      monthIndex:0,
      statusIndex:0,
	  BoutiqueList:[],
	  BoutiqueSerchList:[],
	  trainingPlanList:[],
	  trainingPlanSerchList:[],
	  BoutiqueSerch:{
		"currentPage": 1,
		"pageSize": 8,
		"articleTypeId":'6',
		"sysContanstIdInput":'',
		"timeTypeInput":'',
		"stateInput":'',
	  },
	  trainingPlanSerch:{
		"currentPage": 1,
		"pageSize": 8,
		"articleTypeId":'3',
		"sysContanstIdInput":'',
		"timeTypeInput":'',
		"stateInput":'',
	  },
	  serchData:{
		'行业':{
			name:'sysContanstIdInput',
			icon:require('@/assets/icons/icon-hangye.png')
		},
		'领域':{
			name:'fieldIdInput',
			icon:require('@/assets/icons/icon-hangye.png')
		},
		'费用':{
			name:'stateInput',
			icon:require('@/assets/icons/month-ico.png')
		},
		'时间':{
			name:'timeTypeInput',
			icon:require('@/assets/icons/month-ico.png')
		},
	  }
    };
  },
  mounted() {
  	// this.getTrainingPlanGetNavTagList()
  	this.getCoursesGetNavTagData()
	
  	this.getCoursesGetWebSiteCourseList()
  	// this.getTrainingPlanGetWebSiteTrainingPlanData()
  },
  methods: {
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.trainingPlanSerch.currentPage = size
		  this.getCoursesGetWebSiteCourseList()
	},
	handleCurrentChange (currentPage) {
	  this.trainingPlanSerch.currentPage = currentPage
	  this.getCoursesGetWebSiteCourseList()
	},
    chooseTitleIndex(index) {
      this.titleIndex = index;
      if(index==0){
        this.$router.push("/training");
      }
    },
    tradeSelect(index,i,id) {
      this.trainingPlanSerchList[i].selecid = id
	  if(this.titleIndex == 0){
		  this.trainingPlanSerch[index] = id
	  }else{
		  this.BoutiqueSerch[index] = id
	  }
	  this.getCoursesGetWebSiteCourseList()
    },
	getCoursesGetWebSiteCourseList(){
		CoursesGetWebSiteCourseList(this.BoutiqueSerch).then(res=>{//精品课堂列表
			if(res.code == 0 && res.data.length>0){
				this.trainingPlanList = res.data
			}else{
				this.trainingPlanList = []
			}
			this.total = res.total
		})
	},
	getTrainingPlanGetNavTagList(){
		let that = this
		TrainingPlanGetNavTagList({ArticleTypeId:3}).then(res=>{//培训计划筛选条件列表
			let obj = res
			// if(res.code == 0 && res.data.length>0){
				// this.trainingPlanSerchList=res
				if(obj.length>0){
					obj.map(function(e){
						e.selecid = e.labelist[0].id
						e.keyName = that.serchData[e.name].name
						e.icon = that.serchData[e.name].icon
					})
				}
				this.trainingPlanSerchList=obj
			// }
		})
	},
	getCoursesGetNavTagData(){
		let that = this
		CoursesGetNavTagData({ArticleTypeId:6}).then(res=>{//精品课堂筛选条件列表
			
			let obj = res.data
			if(res.code == 0 && res.data.length>0){
				// this.trainingPlanSerchList=res
				if(obj.length>0){
					obj.map(function(e){
						e.selecid = e.labelist[0].id
						e.keyName = that.serchData[e.name].name
						e.icon = that.serchData[e.name].icon
					})
				}
				this.trainingPlanSerchList=obj
			}
		})
	},
	getTrainingPlanGetWebSiteTrainingPlanData(){
		TrainingPlanGetWebSiteTrainingPlanData(this.trainingPlanSerch).then(res=>{//培训计划列表
			if(res.code == 0 && res.data.length>0){
				this.trainingPlanList = res.data
			}else{
				this.trainingPlanList = []
			}
			this.total = res.total
		})
	},
    goToTrainDetail(id){
      this.$router.push('/coursesdetail?id='+id)
    }
  },
};
</script>

<style lang="less" scoped>
	.bottom-price {
	  color: #014f93;
	  .free {
		font-size: 1rem;
		color: #ffaf4a;
	  }
	  .icon-money {
		font-size: 0.9rem;
	  }
	  .price {
		font-size: 1.3rem;
		font-weight: bold;
	  }
	}
	.bottom-time {
	  font-size: 0.7rem;
	  display: flex;
	  .bottom-line {
		width: 0.05rem;
		height: 0.7rem;
		background: #333333;
		border-radius: 0.03rem;
		margin: 0 0.5rem;
		margin-top: 4px;
	  }
	}
  .page {
	margin: 5.25rem 0;
	display: flex;
	justify-content: center;
	align-items: end;
	::v-deep .el-pagination.is-background .el-pager li {
	  width: 1.1rem;
	  height: 1.1rem;
	  background-color: #ffffff;
	  color: #999999;
	  border: 1px solid #d9d9d9;
	  &.is-active {
		background-color: #0c2f66;
		color: #ffffff;
	  }
	}
	::v-deep .el-pagination.is-background .btn-prev,
	::v-deep .btn-next.is-last {
	  width: 2.8rem;
	  height: 1.5rem;
	  background-color: #0c2f66;
	  color: #ffffff;
	  &:hover {
		background-color: #ffffff;
		color: #0c2f66;
		border: 1px solid #0c2f66;
	  }
	}
	::v-deep .el-pagination.is-background .btn-prev {
	  margin-right: 1rem;
	}
	::v-deep .btn-next.is-last {
	  margin-left: 1rem;
	}
  }

.top-bigbg {
  position: relative;
  height: 25.25rem;
  .bigbg-img {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  .bigbg-box {
    position: relative;
    padding-top: 10rem;
    padding-left: 10.2rem;
    color: #ffffff;
    text-align: left;
    .bigbg-text {
      font-size: 3rem;
	  font-weight: 700;
    }
    .text-eng {
      font-size: 1.1rem;
      letter-spacing: 3px;
    }
  }
}
.plannav {
  padding: 0 0.6rem 0.75rem 0.6rem !important;
  margin-right: 3rem;
}
.nav-select {
  border: 0.05rem solid #014f93 !important;
  color: #014f93;
}
.selectplan{
  margin-top: 1.65rem;
}
.navigation-content {
  .navigation {
    margin-bottom: 1.1rem;
    // text-align: left;
    display: flex;
    font-size: 0.7rem;
    // height: 2rem;
    justify-content: left;
    align-items: center;
    .img-box {
      img {
        width: 14px;
        height: 14px;
        margin-top: -2px;
      }
      span {
        margin-left: 0.4rem;
		font-weight: 700;
      }
    }
    .nav-titlelist {
      height: 1.4rem;
      ul {
        display: flex;
        padding-left: 0.3rem !important;
        li {
          list-style-type: none;
          margin-left: 0.5rem;
          height: 1.4rem;
          line-height: 1.4rem;
          text-align: center;
          border-radius: 0.2rem;
          border: 0.05rem solid #dfe3e5;
          padding: 0 0.55rem;
          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.trainingplan{
  margin-top: 2.5rem;
  margin-bottom: 9.7rem;
  .planbox{
  // width: 16.65rem;
  // height: 15rem;
  // padding: 0.5rem !important;
  margin-bottom: 1.2rem !important;
  &:hover{
      cursor: pointer;
    }
  .theTopbox{
    position: relative;
    .planbox-img{
      width: 100%;
      height: 8.6rem;
	  border-top-left-radius: 0.6rem;
	  border-top-right-radius: 0.6rem;
    }
    .theTop{
      border-radius: 0.05rem;
      position: absolute;
      left: 0.75rem;
      top: 0.7rem;
      width: 2.9rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #006AFF;
      color: #FFFFFF;
      font-size: 0.7rem;
      .theTop-star{
        width: 0.7rem;
        height: 0.7rem;
        margin-top: -2px;
      }
      .theTop-text{
        margin-left: 0.2rem;
      }
    }
  }
  .plancontent{
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    border: 0.05rem solid #D8D8D8;
    height: 6rem;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .plantitle{
      font-size: 0.9rem;
      font-weight: bold;
      margin: 0rem 1rem;
      margin-top: 0.95rem;
      text-align: left;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  display: -webkit-box;
	  -webkit-line-clamp: 2;
	  -webkit-box-orient: vertical;
    }
    .planstatus{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      margin-top: 0rem;
      .status-box{
        .status-text{
          font-size: 0.8rem;
          margin-left: 0.4rem;
          font-weight: bold;
          &.begin{
            color: #18CD04;
          }
          &.begining{
            color: #FFAF4A;
          }
          &.over{
            color: #8C8C8C;
          }
        }
      }
      .plantime-box{
        display: flex;
        font-size: 0.7rem;
        color: #333333;
        .planbox-line{
          width: 0.05rem;
          height: 0.7rem;
          background: #333333;
          border-radius: 0.03rem;
          margin: 0 0.5rem;
          margin-top: 4px;
        }
      }
    }
  }
}
}
@media (max-width: 768px) {
  .selectplan {
    margin-top: 15px;
  }
  .trainingplan{
	  margin-top: 20px;
  }
  .top-bigbg {
    height: 200px;
    .bigbg-box {
      position: relative;
      padding-top: 70px !important;
      padding-left: 70px !important;
      .bigbg-text {
        font-size: 25px;
      }
      .text-eng {
        font-size: 20px;
        letter-spacing: 1px;
      }
      .box-line {
        margin-top: 20px;
      }
    }
  }
  .plannav{
    margin-right: 10px;
  }
  .navigation-content .navigation .nav-titlelist ul{
	  
  }
 
 .navigation-content{
 	  margin-bottom: 15px;
   .navigation{
 		align-items: flex-start;
 	  margin-bottom: 10px;
     .img-box{
     width: 60px;
     display: flex;
     align-items: center;
 	  margin-bottom: 0;
     img{
 
     }
     span{
       width: 40px;
       margin-left: 5px;
     }
     }
   .nav-titlelist{
     align-items: center;
 	  height: auto;
     ul{
 		flex-wrap: wrap;
 		padding-left: 0rem !important;
 		margin-bottom: 0rem;
       li{
         text-align: center;
         display: block;
         white-space: nowrap;
 		  margin-bottom: 5px;
       }
     }
   }
   }
 }
  .trainingplan{
    .planbox{
      margin-bottom: 0px !important;
      // height: 310px;
	  height: auto;
      .theTopbox{
      .planbox-img{
        // height: 180px;
		height: 95px;
      }
    }
	.bottom-price{
		text-align: left;
	}
    .plancontent{
      height: auto;
        .plantitle{
          font-size: 16px;
          font-weight: bold;
          text-align: left;
          margin: 10px;
		  overflow: hidden;
		  text-overflow: ellipsis;
		  display: -webkit-box;
		  -webkit-line-clamp: 2;
		  -webkit-box-orient: vertical;
		  display: block;
        }
        .planstatus{
          padding: 0 10px;
		  display: block;
          margin-top: 0px;
          .status-box{
            display: flex;
            align-items: center;
            img{
              width: 14px;
              height: 14px;
            }
            .status-text{
              font-size: 14px;
            }
          }
          .plantime-box{
            font-size: 10px;
			height: auto;
            .planbox-line{
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
</style>