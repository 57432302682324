<template>
	<el-form
		ref="formUser"
		:model="formUser"
		label-width="100px"
	>
		<el-form-item label="企业名称" placeholder="请输入企业名称">
			<el-input v-model="formUser.name"></el-input>
		</el-form-item>
		<el-form-item label="手机号码" label-width="100px">
			<el-input
				:disabled="true"
				v-model="formUser.phone"
				placeholder="请输入手机号"
			></el-input>
		</el-form-item>
		<el-form-item label="身份证号码" label-width="100px">
			<el-input
				v-model="formUser.cardId"
				placeholder="请输入身份证号"
			></el-input>
		</el-form-item>
	<!-- 	<el-form-item label="公司名称" label-width="100px">
			<el-input
				v-model="formUser.companyName"
				placeholder="公司名称"
			></el-input>
		</el-form-item> -->
		<el-form-item label="职位" label-width="100px">
			<el-input
				v-model="formUser.position"
				placeholder="职位"
			></el-input>
		</el-form-item>
		<el-form-item label="邮箱" label-width="100px">
			<el-input
				v-model="formUser.email"
				placeholder="邮箱"
			></el-input>
		</el-form-item>
	</el-form>
	<div class="text-center">
		<el-button
			class="submitBtn"
			type="primary"
			@click="formSubmit('formUser')"
			>保存</el-button
		>
	</div>
</template>

<script>
import { GetCuostomereInfo, CreateAndEditInfo } from '@/api/index';
export default {
	name: 'personInfo',
	components: {},
	data() {
		return {
			formUser: {
				Id: '',
				name: '',
				phone: '',
				cardId: '',
				email: '',
				position: '',
				headImage:''
			},
			rules: {
				ID: [
					{
						required: true,
						pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/,
						message: '请输入正确的身份证号',
						trigger: 'blur',
					},
				],
				phone: [
					{
						required: true,
						pattern:
							/^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
						message: '请输入正确的手机号',
						trigger: 'blur',
					},
				],
				email: [
					{
						required: true,
						pattern:
							/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
						message: '请输入正确的邮箱',
						trigger: 'blur',
					},
				],
			},
		};
	},
	mounted() {
		this.getUserInfo(); //获取个人信息
	},
	methods: {
		// 获取个人信息
		getUserInfo(typeUrl) {
			GetCuostomereInfo().then((res) => {
				if(typeUrl==1){
					
					window.localStorage.setItem(
						'customerInfo',
						JSON.stringify(res)
					);
					var randomInt = Math.floor(Math.random()*101)
					this.$router.push("/personalcenter?id=0&type=1&sjs="+randomInt);
				}

				this.formUser.Id = res.id;
				this.formUser.name = res.name;
				this.formUser.phone = res.phone;
				this.formUser.cardId = res.cardId;
				this.formUser.position = res.position;
				this.formUser.email = res.email;
			});
		},
		// 保存个人信息 更新
		formSubmit(formName) {
			this.$refs[formName].validate((valid) => {
				if (valid) {
					CreateAndEditInfo(this.formUser).then((res) => {
						if (res.code == 0) {
							this.$message.success({
								message: res.msg,
							});
							this.getUserInfo(1);
						} else {
							this.$messgae.error(res.msg);
						}
						// console.log(res);
					});
				} else {
					// console.log('error submit!!');
					return false;
				}
			});
		},
	},
};
</script>

<style scoped>
.submitBtn {
	width: 200px;
	height: 50px;
	border-radius: 12px;
	color: #fff;
	background-color: #0d2f66;
}
::v-deep(.el-form-item__label) {
	height: 52px;
	line-height: 52px;
}
::v-deep(.el-input){
	height: 100%;
}
@media (min-width: 320px) and (max-width: 768px) {
	::v-deep(.el-form-item__label) {
		width: 72px !important;
		font-size: 12px;
		padding-right: 10px;
	}
  ::v-deep(.el-form-item){
    margin-bottom: -2px !important;
  }
	::v-deep(.el-input) {
		width: 100%;
	}
	::v-deep(.el-input__wrapper) {
		height: 35px;
	}
	.submitBtn {
		width: 90px;
		height: 40px;
	}
}
</style>
