<template>
  
  <div class="container container-fluid fx-justify-space-between mgtop215 pdbt50 ">
	 <div class="block-left20">
		 <div  class="nav-menu nav-menu-one fx-center cursorpointer">
			<div class="nav-menu-name"> <span>{{customerInfo.name}} 您好</span> <div class="logout fx-center" @click="logout()">退出</div> </div>
			<div class="nav-menu-one-line"> </div>
		 </div>
		 <div v-for="(item,index) in blockOneList" class="nav-menu fx-center cursorpointer" :class="item.id==id?'nav-menu-sel':''" @click="navTab(item.id)">{{item.name}}</div>
		 <div class="nav-bt">
			  <div class="logout fx-center" @click="logout()">退出</div>
		 </div>
	 </div>
	 <div class="block-right78">
		<div v-show="id==0">
			<BasicInfo></BasicInfo> 
		</div>
		<div v-show="id==1">
			<CoursesList></CoursesList> 
		</div>
		<div v-show="id==2">
			<DataReport></DataReport> 
		</div>
		<div v-show="id==3">
			<ZhengshuGongshiJIChaxun></ZhengshuGongshiJIChaxun> 
		</div>
		<div v-show="id==4">
			<ProjectManage></ProjectManage> 
		</div>
		
		<div v-show="id==5">
			<Invoice></Invoice> 
		</div>
	 </div>
  </div>
</template>

<script>

import {FindConstantBySysContantId,GetAboutUs} from '@/api/index'
import BasicInfo from './personal/BasicInfoView.vue' //基础信息
import CoursesList from './personal/CoursesListView.vue' //我的课程
import DataReport from './personal/DataReportView.vue' //我的数据报告
import Invoice from './personal/InvoiceView.vue' //发票信息
import ZhengshuGongshiJIChaxun from './personal/ZhengshuGongshiJIChaxunView.vue' //我的证书
import ProjectManage from './personal/ProjectManageView.vue' //我的项目管理

export default {
  name: "PersonalCenterView",
  components:{
	  BasicInfo,
	  CoursesList,
	  DataReport,
	  Invoice,
	  ZhengshuGongshiJIChaxun,
	  ProjectManage
  },
  data() {
    return {
		blockOneList:[
			{id:0,name:'基本信息'},
			{id:1,name:'我的课程'},
			{id:2,name:'我的数据报告'},
			{id:3,name:'我的证书'},
			{id:4,name:'我的项目管理'},
			{id:5,name:'发票信息'},
		],
		id:'',
		menuJgObj:{},
		customerInfo:{}
    };
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.customerInfo = JSON.parse(window.localStorage.getItem('customerInfo'));
					this.id = to.query.id
				}else{
					this.id=0
				}
			},
			deep: true,
		},
	},
  mounted() {
	  this.id = this.$route.query.id==undefined?0:this.$route.query.id
	  this.customerInfo = JSON.parse(window.localStorage.getItem('customerInfo'));
  },
  methods:{
	navTab(id){
		this.id = id
	},  
	logout(){
		localStorage.clear()
		this.$router.push('/');
	}
  }
};
</script>

<style lang="less" scoped>
	.block-left20{
		width: 20%;
		background: #EFF1F6;
	}
	.block-right78{
		width: 78%;
	}
	.nav-menu-name .logout{
		display: none;
	}
	.logout{
		width: 7.6rem;
		height: 2.3rem;
		background: #0C2F66;
		border-radius: 0.35rem;
		font-size: 0.8rem;
		color: #FFFFFF;
		line-height: 1.3rem;
		margin: 0 auto;
		margin-top: 2.5rem;
	}
	.nav-bt{
		height: 30vh
	}
	.nav-menu{
		height: 3rem;
		font-size: 0.8rem;
		color: #333333;
		line-height: 1.3rem;
		background: #EFF1F6;
		border-radius: 0.1rem;
		// margin-bottom: 0.7rem;
	}
	.nav-menu-one{
		display: block;
		height: 4.3rem;
		font-size: 1.1rem;
		font-weight: 600;
		color: #333333;
		line-height: 1.3rem;
		.nav-menu-name{
			height: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			border-bottom: 1px solid #D6D8DC;
		}
	}
	.nav-menu-sel{
		background: #0C2F66;
		border-radius: 0.1rem;
		color: #FFFFFF;
	}
	
	@media (max-width: 768px){
		.nav-menu-name .logout{
			display: flex;
			margin: 0;
			width: auto;
			height: auto;
			padding: 3px 10px;
		}
		.fx-justify-space-between{
			display: block;
		}
		.block-left20{
			width: 100%;
			display:flex;
			flex-wrap:wrap;
			background: none;
			margin-bottom:10px;
		}
		.nav-menu{
			font-size: 13px;
			width: auto;
			height: auto;
			padding: 3px 5px;
			margin-right: 8px;
			margin-bottom: 5px;
		}
		.nav-bt{
			display: none;
		}
		.block-right78{
			width: 100%;
		}
		.nav-menu-one,.nav-menu-one .nav-menu-name{
			width: 100%;
			text-align: left;
			display: block;
			background: none;
			border-bottom:0px;
			font-size:18px;
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
		.nav-menu-one{
			margin-bottom:10px;
		}
		.nav-menu-one-line{
			display: none;
		}
	}
</style>