<template>
	<div class="tab-title-block" v-if="titleList">
		<span v-for="(item, index) in titleList" :key="index"
			:class="titleListIndex == index ? 'tab-title-block-select' : ''" @click="blockTabone(item,index)">{{ item.name }}
			<div class="tab-title-block-select-line"></div>
		</span>
	</div>
	 <div class="new-list text-left">
		 <div class="new-one cursorpointer" v-for="(item,index) in list" @click="gotoDetail(item)">
			 <div class="new-title">{{item.title}}</div>
			 <div class="new-content">
				 <div v-html="item.synopsis"></div>
			 </div>
			 <div class="new-bottom fx">
				 <div class="new-span-block">
					 <img src="@/assets/icons/icon-time.png" class="new-icon-time"/>
					 <span>{{item.releaseTime.split('T')[0]}}</span>
				 </div>
				 <div class="new-span-block">
					 <span>来源：{{item.source}}</span>
				 </div>
			 </div>
		 </div>
	 </div>
	 <div class="page" v-if="pageShow==true">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div>
</template>

<script>
import {GetFaLvFaGuiApiData,FindConstantBySysContantId} from '@/api/index'
export default {
  name: "PolicyDetailView",
  data() {
    return {
		list:[],
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 5,
		  "sysContantId": ""
		},
		total:0,
		titleList:[],
		titleListIndex:0,
		pageShow:false
    };
  },
  props: {
    id: String,
  },
  mounted() {
	this.getFindConstantBySysContantId()
  	// this.getGetFaLvFaGuiApiData()
  },
  methods:{
	  
	blockTabone(item,index) {
		this.titleListIndex = index
		this.queryParma.currentPage = 1
		this.list = []
		this.pageShow = false
		this.getGetFaLvFaGuiApiData()
	},
	getList(){
		this.getGetRengZhengZiYuan()
		this.getGetShenQingBiaoApiData()
	},
	getFindConstantBySysContantId(){
		FindConstantBySysContantId({ArticleTypeId:this.id}).then(res=>{
			this.titleList = res
			this.getGetFaLvFaGuiApiData()
		})
	},
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.queryParma.currentPage = size
		this.getGetFaLvFaGuiApiData()
	},
	handleCurrentChange (currentPage) {
		this.queryParma.currentPage = currentPage
		this.getGetFaLvFaGuiApiData()
	},
	getGetFaLvFaGuiApiData(){
		this.queryParma.sysContantId = this.titleList[this.titleListIndex].id
		
		GetFaLvFaGuiApiData(this.queryParma).then(res=>{
			this.pageShow = true
			if(res.code == 0){
				this.list = res.data
			}
			this.total = res.total
		})
	},
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/resourcecentredetail?id='+item.id+'&pid='+this.id+'&titlename=法律法规')
		}
	}
  }
};
</script>

<style lang="less" scoped>
	
	.new-one{
		padding-bottom: 1.65rem;
		border-bottom: 1px solid #EFEFEF;
		margin-bottom: 1.5rem;
		.new-title{
			font-size: 1.1rem;
			font-weight: 700;
			color: #333333;
			line-height: 1.1rem;
		}
		.new-content{
			font-size: 0.7rem;
			color: #999999;
			line-height: 0.9rem;
			margin-top: 1.1rem;
			margin-bottom: 0.45rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		.new-bottom{
			.new-span-block{
				margin-right: 2rem;
				.new-icon-time{
					width: 0.7rem;
					height: 0.7rem;
					margin-right: 0.5rem;
				}
				span{
					font-size: 0.7rem;
					color: #666666;
					line-height: 0.9rem;
				}
			}
		}
	}
	
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}

	
	@media (max-width: 768px){
		.new-list{
			padding-top: 10px;
		}
		.new-one .new-title{
			font-size: 14px;
		}
		.new-one .new-content{
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}
		.new-one .new-bottom .new-span-block span{
			font-size: 10px;
		}
	}
</style>