<template>
	
	<div class="container text-center home-container all searchContainer">
		<div class="breadcrumbs">
			搜索结果
		</div>
		<div class="container-search fx-justify-space-between">
			<div class="container-search-left">搜索词：{{ KeyValue }}</div>
			<div class="container-search-count">共有 {{ count }} 条 搜索结果</div>
		</div>
		<div class="tab-title-block"  v-if="info.hangyeData!=undefined && info.hangyeData.length>0">
			<span class="tab-title-block-select apply">行业<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="grid-2 grid-md-3  grid-gl-4 "  v-if="info.hangyeData!=undefined && info.hangyeData.length>0">
			<div class="cursorpointer" v-for="(item,index) in info.hangyeData" v-bind:key="index" @click="tiaozhuan(item,'/industrydetail?id='+item.id)">
				<div class="bock-one">
					<img :src="item.tailorPictureUrl">
					<div class="bock-mode">
						<div class="mode-cn">{{item.title}}</div>
						<div class="mode-en">{{item.enName}}</div>
						<div class="mode-info">查看详细</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="tab-title-block"  v-if="info.fuWuYuJieJueFangAn!=undefined && info.fuWuYuJieJueFangAn.length>0">
			<span class="tab-title-block-select apply">服务与解决方案<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="grid-2 grid-md-3  grid-gl-4"  v-if="info.fuWuYuJieJueFangAn!=undefined && info.fuWuYuJieJueFangAn.length>0">
			<div class="cursorpointer" v-for="(item,index) in info.fuWuYuJieJueFangAn" v-bind:key="index"  @click="tiaozhuan(item,'/servicedetail?id='+item.id)">
				<div class="bock-one">
					<img :src="item.tailorPictureUrl">
					<div class="bock-mode">
						<div class="mode-cn">{{item.title}}</div>
						<div class="mode-en">{{item.enName}}</div>
						<div class="mode-info">查看详细</div>
					</div>
				</div>
			</div>
		</div>
		
		<div class="tab-title-block"  v-if="info.serchBiaoZhun!=undefined && info.serchBiaoZhun.length>0">
			<span class="tab-title-block-select apply">标准<div class="tab-title-block-select-line"></div></span>
		</div>
		
		<div class="grid-2 grid-md-3 grid-gl-5 tab-title"  v-if="info.serchBiaoZhun!=undefined && info.serchBiaoZhun.length>0">
			<div v-for="(item, index) in info.serchBiaoZhun" :key="index" class="industry cursorpointer"  style="overflow: hidden;border-radius: 8px;"   @click="tiaozhuan(item,'/standard?id='+item.articleTypeId+'&type=1')">
				<div style="overflow: hidden; width: 100%;border-radius: 8px;">
					<img :src="item.picture" class="img-hover" />
				</div>
				<div class="tab-title-content" style="padding-top: 1rem;border-radius: 8px;">
					<div class="tab-title-title">
						<div class="tab-title-title-line"></div>{{ item.name }}
					</div>
					<div class="tab-title-titleen">{{ item.enName }}</div>
					<!-- <div class="tab-title-desc">{{ item.desc }}<span>了解详情</span></div> -->
				</div>
			</div>
		</div>
		
		<div class="tab-title-block"  v-if="info.trainingPlanData!=undefined && info.trainingPlanData.length>0">
			<span class="tab-title-block-select apply">培训计划<div class="tab-title-block-select-line"></div></span>
		</div>
		
		  <div class="grid-2 grid-md-3  grid-gl-4 trainingplan"  v-if="info.trainingPlanData!=undefined && info.trainingPlanData.length>0">
		    <div v-for="(  item, index  ) in info.trainingPlanData " :key="index" class="planbox cursorpointer"  @click="tiaozhuan(item,'/trainingdetail?id='+item.id)">
		        <div class="theTopbox">
		          <img :src="item.picturlUrlCaijian" class="planbox-img"/>
		          <div class="theTop" v-if="item.topPostion==1">
		            <img src="../assets/public/star.png" alt="" class="theTop-star">
		            <div class="theTop-text">置顶</div>
		          </div>
		        </div>
		        <div class="plancontent">
		          <div class="plantitle">{{ item.trainingPlanName }}</div>
		          <div class="planstatus">
		            <div class="status-box" v-if="item.stopCnName=='报名中'">
		              <img src="../assets/training/begin.png" alt="">
		              <span class="status-text begin">报名中</span>
		            </div>
		            <div class="status-box" v-if="item.stopCnName=='进行中'">
		              <img src="../assets/training/begining.png" alt="">
		              <span class="status-text begining">进行中</span>
		            </div>
		            <div class="status-box" v-if="item.stopCnName=='已结束'">
		              <img src="../assets/training/begining.png" alt="">
		              <span class="status-text over">已结束</span>
		            </div>
		            <div class="plantime-box">
		              <span>{{ item.startTimeCn }}</span>
		              <div class="planbox-line"></div>
		              <span>{{ item.city }}</span>
		            </div>
		          </div>
		        </div>
		    </div>
		</div>
		
		
		<div class="tab-title-block"  v-if="info.courseData!=undefined && info.courseData.length>0">
			<span class="tab-title-block-select apply">精品课堂<div class="tab-title-block-select-line"></div></span>
		</div>
		
		  <div class="grid-2 grid-md-3  grid-gl-4 trainingplan" v-if="info.courseData!=undefined && info.courseData.length>0">
		    <div v-for="(  item, index  ) in info.courseData " :key="index" class="planbox cursorpointer"  @click="tiaozhuan(item,'/coursesdetail?id='+item.id)">
		        <div class="theTopbox">
		          <img :src="item.coursePicturlUrl" class="planbox-img"/>
		          <div class="theTop" v-if="item.topPostion">
		            <img src="../assets/public/star.png" alt="" class="theTop-star">
		            <div class="theTop-text">置顶</div>
		          </div>
		        </div>
		        <div class="plancontent">
		          <div class="plantitle">{{ item.courseName }}</div>
		          <div class="planstatus">
		            <div class="bottom-price">
		        				<div v-if="item.isFree">
		        				  <span class="icon-money">￥</span
		        				  ><span class="price">{{ item.price }}</span>
		        				</div>
		        				<span class="free" v-else>免费</span>
		        			  </div>
		            <div class="plantime-box">
		              <span>{{ item.releaseTime.split('T')[0]}}</span>
		             <!-- <div class="planbox-line"></div>
		              <span>{{ item.durationTime }}</span> -->
		            </div>
		          </div>
		        </div>
		    </div>
		</div>
		.
		<div class="tab-title-block" v-if="info.faLvFaGui!=undefined && info.faLvFaGui.length>0">
			<span class="tab-title-block-select apply">法律法规<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="new-list-flfg text-left" v-if="info.faLvFaGui!=undefined && info.faLvFaGui.length>0">
			 <div class="new-one cursorpointer" v-for="(item,index) in info.faLvFaGui"  @click="tiaozhuan(item,'/resourcecentredetail?id='+item.id+'&type=1')">
				 <div class="new-title">{{item.title}}</div>
				 <div class="new-content">
					 <div v-html="item.synopsis"></div>
				 </div>
				 <div class="new-bottom fx">
					 <div class="new-span-block">
						 <img src="@/assets/icons/icon-time.png" class="new-icon-time"/>
						 <span>{{item.releaseTime.split('T')[0]}}</span>
					 </div>
					 <div class="new-span-block">
						 <span>来源：{{item.source}}</span>
					 </div>
				 </div>
			 </div>
		</div>
		
		<div class="tab-title-block" v-if="info.shujuYuBaoGao!=undefined && info.shujuYuBaoGao.length>0">
			<span class="tab-title-block-select apply">数据与报告<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="block-list text-left row" v-if="info.shujuYuBaoGao!=undefined && info.shujuYuBaoGao.length>0">
		 <!-- 头部导航区 -->
			 <div class="block-one col-12 col-md-4 cursorpointer" v-for="(item,index) in info.shujuYuBaoGao"  @click="tiaozhuan(item,'/datadetail?id='+item.id+'&type=1')">
				 <div class="block-one-sun">
					 <div>
						 <div class="block-title">{{item.title}}</div>
						 <div class="block-content">
							 <div v-html="item.synopsis"></div>
						 </div>
					 </div>
					 <div>
						 <div class="block-line"></div>
						 <div class="block-bottom fx">
							 <div class="block-span-block">
								  <div class="block-span-block-lable fx-center">{{item.source}}</div>
								 <span>{{item.releaseTime.split('T')[0]}}</span>
							 </div>
							 <div class="block-span-block">
								 <div class="block-span-block-button fx-center"  v-if="item.isFree"><span>￥</span>{{item.money}}</div>
								 <div class="block-span-block-button fx-center"  v-else>免费</div>
							 </div>
						 </div>
					 </div>
				 </div>
			 </div>
		</div>
		
		<div class="tab-title-block" v-if="info.heZuoAnli!=undefined && info.heZuoAnli.length>0">
			<span class="tab-title-block-select apply">合作案例<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="block-list-hzal text-left row" v-if="info.heZuoAnli!=undefined && info.heZuoAnli.length>0">
		 <!-- 头部导航区 -->
			 <div class="block-one col-12 col-md-6 cursorpointer" v-for="(item,index) in info.heZuoAnli"   @click="tiaozhuan(item,'/resourcecentredetail?id='+item.id+'&type=1' )">
				 <div class="block-one-sun">
					 <div class="block-head-img">
						<img :src="item.tailorPictureUrl"/>
					 </div> 
					 <div  class="block-btcontent">
						 <div>
							 <div class="block-title">{{item.title}}</div>
							 <div class="block-content">
								 <div v-html="item.synopsis"></div>
							 </div>
						 </div>
						 <div class="block-bottom fx">
							 <div class="block-span-block fx">
								 <div class="block-span-block-button fx-center" v-for="(item,index) in catalogueNamesqg(item.catalogueNames)">{{item}}</div>
							 </div>
							 <div class="block-span-block">
								 <span>{{item.sysContantName}}</span>
							 </div>
						 </div>
					 </div>
				 </div>
			 </div>
		</div>
		
		
		<div class="tab-title-block" v-if="info.zhengCeZiXun!=undefined && info.zhengCeZiXun.length>0">
			<span class="tab-title-block-select apply">新闻资讯<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="news-content" v-if="info.zhengCeZiXun!=undefined && info.zhengCeZiXun.length>0">
		  <div
		    v-for="(item1, i1) in info.zhengCeZiXun"
		    :key="i1"
		    class="content-box cursorpointer"
		    @click="tiaozhuan(item1,'/policydetail?id='+item1.id)"
		  >
		    <div class="news-content-box">
		      <img :src="item1.tailorPictureUrl" class="content-box-img" />
		      <div class="img-top" v-if="item1.isTop == 1">
		        <img src="../assets/public/star.png" class="star" />
		        <div class="img-top-text">置顶</div>
		      </div>
		    </div>
		    <div class="content-text">
		      <span class="text-title">{{ item1.title }}</span>
		      <div class="text">{{ item1.synopsis }}</div>
		      <div class="from">
		        <div class="from-time">
		          <img
		            src="../assets/policy/icon-time.png"
		            class="time-img"
		          /><span class="time">{{ item1.createTime.split('T')[0] }}</span>
		        </div>
		        <div class="from-text">来源：{{ item1.source }}</div>
		      </div>
		    </div>
		  </div>
		</div>
		
		<!-- courseData -->
	</div>
	
</template>

<script>
import { GetOverallSituationData } from '@/api/index';
export default {
	name: 'search',
	data() {
		return {
			info: {},
			KeyValue: '',
			count: '',
		};
	},
	mounted() {
		this.KeyValue = this.$route.query.keyword;
		this.getSearchInfo(this.$route.query.keyword);
	},
	methods: {
		catalogueNamesqg(val){
		  return val==''?[]:val.split(',')
		},
		goToTrainDetail(url){
		  this.$router.push(url)
		},
		getSearchInfo(KeyValue) {
			GetOverallSituationData({ KeyValue: KeyValue }).then((res) => {
				if (res.code == 0) {
					this.count = res.count;
					this.info = res.data;
				}
			});
		},
		strReplace(str) {
			// console.log(str,'===========strstrstr================',this.KeyValue)
			return str == null ? '' : str.replace(this.KeyValue, '<span style="color:red">' + this.KeyValue + '</span>')
		},
	
		tiaozhuan( item, url) {
			if (item.isWaibuUrl == true) {
				window.open(item.toUrl, '_target');
			} else {
				this.$router.push(url);
			}
		},
	},
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(newVal, oldVal) {
				//判断newVal有没有值监听路由变化
				if (newVal != oldVal) {
					location.reload();
				}
			},
			deep: true,
		},
	},
};
</script>

<style lang="less" scoped>
	
.searchContainer{
	margin-top: 7rem;
}
.container-search{
	margin-top: 20px;
	background-color: #e7f0ff;
	padding: 24px;
	font-size: 20px;
	color: #383838;
	margin-bottom: 20px;
}
/**********************合作案例开始******************/

	.block-list-hzal{
		
		.block-one:nth-child(2n+1) .block-one-sun{
			margin-right:1.55rem ;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-left:1.5rem ;
		}
		.block-one{
			.block-one-sun{
				background: #FFFFFF;
				.block-btcontent{
					
					border-radius: 0.2rem;
					margin-bottom: 1.15rem;
					height: 5.7rem;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
				}
				.block-title{
					font-size: 0.9rem;
					font-weight: 700;
					color: #333333;
					line-height: 0.9rem;
					margin-bottom: 1rem;
				}
				.block-head-img img{
					width: 100%;
					height: 9.5rem;
					margin-bottom: 1rem;
				}
				.block-content{
					font-size: 0.7rem;
					color: #666666;
					line-height: 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 2;
					-webkit-box-orient: vertical;
				}
				.block-bottom{
					display: flex;
					justify-content: space-between;
					.block-span-block{
						
					}
					span{
						font-size: 0.8rem;
						color: #0C2F66;
						line-height: 1.1rem;
					}
					.block-span-block-lable{
						font-size: 0.8rem;
						color: #0C2F66;
						line-height: 1.1rem;
					}
					.block-span-block-button{
						background: #0C2F66;
						border-radius: 0.15rem;
						font-size: 0.6rem;
						color: #FFFFFF;
						line-height: 0.85rem;
						padding: 2px 5px;
						margin-right: 5px;
						span{
							font-size: 0.7rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 1rem;
						}
					}
				}
			}
		}
	}
	@media (max-width: 768px){
		
		// .block-list{
		// 	padding:0 10px;
		// }
		.block-list-hzal .block-one:nth-child(2n+1) .block-one-sun{
			margin-right:0rem !important;
		}
		.block-list-hzal .block-one:nth-child(3n+2) .block-one-sun{
			margin-left:0rem !important;
		}
	}
/**********************合作案例结束******************/
/**********************数据与报告********************/

	.block-list{
		
		.block-one:nth-child(3n+1) .block-one-sun{
			margin-right:1.43rem ;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-right:0.71rem ;
			margin-left:0.71rem ;
		}
		.block-one:nth-child(3n+3) .block-one-sun{
			margin-left:1.43rem ;
		}
		.block-one{
			.block-one-sun{
				background: #FFFFFF;
				box-shadow: 0rem 0rem 0.75rem -0.05rem rgba(0,0,0,0.06);
				border-radius: 0.2rem;
				padding: 1rem;
				margin-bottom: 1.15rem;
				height: 12.1rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.block-title{
					font-size: 0.9rem;
					font-weight: 700;
					color: #333333;
					line-height: 0.9rem;
					margin-bottom: 1rem;
				}
				.block-content{
					font-size: 0.7rem;
					color: #666666;
					line-height: 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
				}
				.block-line{
					height: 0.05rem;
					background: #F1F1F1;
					border-radius: 0.08rem;
					// margin-top: 1.15rem;
					margin-bottom: 0.85rem;
				}
				.block-bottom{
					display: flex;
					justify-content: space-between;
					.block-span-block{
						
					}
					span{
						font-size: 0.6rem;
						color: #C4C4C4;
						line-height: 0.6rem;
					}
					.block-span-block-lable{
						padding: 4px 8px;
						border-radius: 0.1rem;
						border: 0.05rem solid #014F93;
						font-size: 0.6rem;
						color: #014F93;
						line-height: 0.85rem;
					}
					.block-span-block-button{
						width: 6.1rem;
						height: 2.2rem;
						background: #0C2F66;
						border-radius: 0.1rem;
						font-size: 0.9rem;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 1rem;
						span{
							font-size: 0.7rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 1rem;
						}
					}
				}
			}
		}
	}
	@media (max-width: 768px){
		
		.block-one:nth-child(3n+1) .block-one-sun{
			margin-right:0rem !important;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-right:0rem !important;
			margin-left:0rem !important;
		}
		.block-one:nth-child(3n+3) .block-one-sun{
			margin-right:0rem !important;
			margin-left:0rem !important;
		}
		// .block-list{
		// 	padding:0 10px;
		// }
	}
/**********************数据与报告结束****************/
/**********************行业*************************/
	.mg-tp-content{
		margin-top: 2rem;
		min-height: 1000px;
	}
	.bock-one{
		// margin: 6.5px;
		margin-bottom: 2rem;
	}
	.bock-one img{
		width: 100%;
		height: 10rem;
		// object-fit: contain;
		border-radius: 6px;
	}
	.bock-one .bock-mode{
		width: 15.25rem;
		height: 5.05rem;
		background: #FFFFFF;
		box-shadow: 0rem 0.3rem 0.75rem -0.05rem rgba(0,0,0,0.06);
		border-radius: 0.2rem;
		text-align: center;
		margin: 0 auto;
		margin-top: -1.5rem;
		position: relative;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.bock-one .bock-mode .mode-cn{
		font-size: 1rem;
		font-weight: 700;
		color: #333333;
		line-height: 1.4rem;
	}
	.bock-one .bock-mode .mode-en{
		font-size: 0.6rem;
		color: #333333;
		line-height: 0.85rem;
	}
	.bock-one .bock-mode .mode-info{
		margin-top: 0.4rem;
		font-size: 0.6rem;
		color: #014F93;
		line-height: 0.85rem;
	}
	
	@media (max-width: 768px) {
		
		.bock-one .bock-mode{
			width: 90%;			
			height: 90px;

		}
		
		.bock-one .bock-mode .mode-cn{
			font-size: 14px;
			line-height: 20px;
		}
		.bock-one .bock-mode .mode-en{
			font-size:8px;
			line-height:15px;
		}
		.bock-one{
			margin-bottom: 15px;
		}
		.bock-one img{
			height: 107px;
		}
	}
/**********************行业结束*********************/
/**********************培训计划开始*****************/

.trainingplan{
  margin-top: 1.5rem;
  // margin-bottom: 9.7rem;
  .planbox{
  // width: 16.65rem;
  // height: 15rem;
  // padding: 0.5rem !important;
  margin-bottom: 1.2rem !important;
  &:hover{
      cursor: pointer;
    }
  .theTopbox{
    position: relative;
    .planbox-img{
		width: 100%;
		height: 8.6rem;
		border-top-left-radius: 0.6rem;
		border-top-right-radius: 0.6rem;
		object-fit: cover;
    }
    .theTop{
      border-radius: 0.05rem;
      position: absolute;
      left: 0.75rem;
      top: 0.7rem;
      width: 2.9rem;
      height: 1.2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: #006AFF;
      color: #FFFFFF;
      font-size: 0.7rem;
      .theTop-star{
        width: 0.7rem;
        height: 0.7rem;
        margin-top: -2px;
      }
      .theTop-text{
        margin-left: 0.2rem;
      }
    }
  }
  .plancontent{
    border-bottom-left-radius: 0.6rem;
    border-bottom-right-radius: 0.6rem;
    border: 0.05rem solid #D8D8D8;
    height: 7.8rem;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .plantitle{
      font-size: 0.9rem;
      font-weight: bold;
      margin: 0rem 1rem;
      margin-top: 0.95rem;
      text-align: left;
	  overflow: hidden;
	  text-overflow: ellipsis;
	  display: -webkit-box;
	  -webkit-line-clamp: 2;
	  -webkit-box-orient: vertical;
    }
    .planstatus{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 1rem;
      margin-top: 1.8rem;
      .status-box{
        .status-text{
          font-size: 0.8rem;
          margin-left: 0.4rem;
          font-weight: bold;
          &.begin{
            color: #18CD04;
          }
          &.begining{
            color: #FFAF4A;
          }
          &.over{
            color: #8C8C8C;
          }
        }
      }
      .plantime-box{
        display: flex;
        font-size: 0.7rem;
        color: #333333;
        .planbox-line{
          width: 0.05rem;
          height: 0.7rem;
          background: #333333;
          border-radius: 0.03rem;
          margin: 0 0.5rem;
          margin-top: 4px;
        }
      }
    }
  }
}
}
@media (max-width: 768px) {
  .trainingplan{
	  margin-top: 20px;
  }
  .trainingplan{
    .planbox{
      margin-bottom: 0px !important;
      // height: 310px;
	  height: auto;
	  margin-bottom: 10px !important;
      .theTopbox{
      .planbox-img{
        // height: 180px;
		height: 95px;
      }
    }
    .plancontent{
      // height: 130px;
        .plantitle{
          font-size: 13px;
          font-weight: bold;
          text-align: left;
          margin: 10px;
        }
        .planstatus{
          padding: 0 10px;
		  display: block;
          margin-top: 0px;
          .status-box{
            display: flex;
            align-items: center;
            img{
              width: 14px;
              height: 14px;
            }
            .status-text{
              font-size: 14px;
            }
          }
          .plantime-box{
            font-size: 10px;
			height: auto;
            .planbox-line{
              margin-top: 2px;
            }
          }
        }
      }
    }
  }
}
/**********************培训计划结束*****************/

/****************新闻资讯开始*****************/
  .news-content {
    text-align: left;
    .content-box {
      margin-bottom: 1.5rem;
      display: flex;
      &:hover {
        cursor: pointer;
      }
      .news-content-box {
        position: relative;
        .content-box-img {
          width: 14rem;
          height: 7.3rem;
        }
        .img-top {
          position: absolute;
          top: 0.5rem;
          left: 0.5rem;
          width: 2.9rem;
          height: 1.2rem;
          background-color: #006aff;
          display: flex;
          justify-content: center;
          align-items: center;
          .star {
            width: 0.7rem;
            height: 0.7rem;
          }
          .img-top-text {
            font-size: 0.7rem;
            color: #ffffff;
            margin-left: 0.15rem;
          }
        }
      }
      .content-text {
        margin-left: 1rem;
        margin-top: -5px;
		width: calc(100% - 14rem);
        .text-title {
          width:100%;
          // height: 1.1rem;
          font-size: 1.1rem;
          font-weight: bold;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .text {
          margin-top: 1.1rem;
          width:100%;
          font-size: 0.7rem;
          color: #999999;
          height: 1.9rem;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }
        .from {
          font-size: 0.7rem;
          color: #666666;
          display: flex;
          margin-top: 1.5rem;
          .from-time {
            .time-img {
              width: 0.7rem;
              height: 0.7rem;
              margin-top: -3px;
            }
            .time {
              margin-left: 0.45rem;
            }
          }
          .from-text{
            margin-left: 2rem;
          }
        }
      }
    }
  }
  
    @media (max-width: 768px) {
  	  .container-search{
  		  font-size: 16px;
  		  padding: 15px;
  	  }
  	  .news-content {
  	    margin-top: 0rem;
  	    text-align: left;
  	    .content-box {
  	  	margin-top: 1.5rem;
  	  	display: block;
  	  	padding-bottom: 10px;
  	  	border-bottom: 1px solid #ccccccab;
  	      &:hover {
  	        cursor: pointer;
  	      }
  	      .news-content-box {
  	        margin: 10px;
  	        .content-box-img {
  	  		width: 100%;
  	  		height: auto;
  	        }
  	        .img-top {
  	          position: absolute;
  	          top: 0.5rem;
  	          left: 0.5rem;
  	          width: 2.9rem;
  	          height: 1.2rem;
  	          background-color: #006aff;
  	          display: flex;
  	          justify-content: center;
  	          align-items: center;
  	          .star {
  	            width: 0.7rem;
  	            height: 0.7rem;
  	          }
  	          .img-top-text {
  	            font-size: 0.7rem;
  	            color: #ffffff;
  	            margin-left: 0.15rem;
  	          }
  	        }
  	      }
  	      .content-text {
  	        // margin-left: 10px;
  	        margin-top: -5px;
  			width: 100%;
  	  	  overflow: hidden;
  	        .text-title {
  	          font-size:14px;
  	          height: auto;
  	          overflow: hidden;
  	          text-overflow: ellipsis;
  	          display: block;
  	          -webkit-line-clamp: 2;
  	          -webkit-box-orient: vertical;
  	  		width: 100%;
  	        }
  	        .text {
  	          margin-top: 10px;
  	          font-size: 0.7rem;
  	          color: #999999;
  	          height: auto;
  	          overflow: hidden;
  	          text-overflow: ellipsis;
  	          display: -webkit-box;
  	          -webkit-line-clamp: 2;
  	          -webkit-box-orient: vertical;
  	  		width: 100%;
  	        }
  	        .from {
  	          font-size: 0.7rem;
  	          color: #666666;
  	          display: flex;
  	          margin-top: 10px;
  	          .from-time {
  	            .time-img {
  	              width: 0.7rem;
  	              height: 0.7rem;
  	              margin-top: -3px;
  	            }
  	            .time {
  	              margin-left: 0.45rem;
  	            }
  	          }
  	          .from-text{
  	            margin-left: 2rem;
  	          }
  	        }
  	      }
  	    }
  	  }
  	  
    }
  /****************新闻资讯结束*****************/
  /***************法律法规*********************/
  .new-list-flfg{
	  
	  .new-one{
		padding-bottom: 1.65rem;
		border-bottom: 1px solid #EFEFEF;
		margin-bottom: 1.5rem;
		.new-title{
			font-size: 1.1rem;
			font-weight: 700;
			color: #333333;
			line-height: 1.1rem;
		}
		.new-content{
			font-size: 0.7rem;
			color: #999999;
			line-height: 0.9rem;
			margin-top: 1.1rem;
			margin-bottom: 0.45rem;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
		}
		.new-bottom{
			.new-span-block{
				margin-right: 2rem;
				.new-icon-time{
					width: 0.7rem;
					height: 0.7rem;
					margin-right: 0.5rem;
				}
				span{
					font-size: 0.7rem;
					color: #666666;
					line-height: 0.9rem;
				}
			}
		}
	  }
  }
  
  @media (max-width: 768px){
	  .new-list-flfg{
		.new-list{
			padding-top: 10px;
		}
		.new-one .new-title{
			font-size: 14px;
		}
		.new-one .new-content{
			font-size: 12px;
			overflow: hidden;
			text-overflow: ellipsis;
			display: -webkit-box;
			-webkit-line-clamp: 4;
			-webkit-box-orient: vertical;
		}
		.new-one .new-bottom .new-span-block span{
			font-size: 10px;
		}
	  }
  }
  /***************法律法规结束*****************/
</style>
