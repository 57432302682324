<template>
  
  <div class="container container-fluid fx-justify-space-between mgtop215 pdbt50 ">
	 <div class="block-left20">
		 <div v-for="(item,index) in blockOneList" class="nav-menu fx-center cursorpointer" :class="item.id==id?'nav-menu-sel':''" @click="navTab(item.id,index)">{{item.name}}</div>
	 </div>
	 <div class="block-right78" v-if="blockOneList.length>0">
		 <div v-show="menuJgObj['法律法规'] == id">
			<FaLvFaGuiApiData :id="menuJgObj['法律法规']"></FaLvFaGuiApiData> 
		 </div>
		 <div  v-show="menuJgObj['数据与报告'] == id">
			<ShuJuYuBaoGaoApiData :id="menuJgObj['数据与报告']"></ShuJuYuBaoGaoApiData> 
		 </div>
		 <div v-show="menuJgObj['合作案例'] == id">
			<HeZuoAnLiApiData :id="menuJgObj['合作案例']"></HeZuoAnLiApiData> 
		 </div>
		 <div v-show="menuJgObj['认证资源'] == id">
			<RengZhengZiYuan :id="menuJgObj['认证资源']"></RengZhengZiYuan> 
		 </div>
		 <div v-show="menuJgObj['评价资源'] == id">
			<PingJiaZiYuanApiData :id="menuJgObj['评价资源']"></PingJiaZiYuanApiData> 
		 </div>
		 <div v-show="menuJgObj['生命周期类证书公示及查询'] == id">
			<ZhengshuGongshiJIChaxunView :id="menuJgObj['生命周期类证书公示及查询']"></ZhengshuGongshiJIChaxunView> 
		 </div>
		 <div v-show="menuJgObj['核查审定证书查询'] == id">
			<ZhengshuChaxunView :id="menuJgObj['核查审定证书查询']"></ZhengshuChaxunView>
		 </div>
		 
		 <div v-if="blockOneList[navlistIndex].content!= undefined">
			 <div v-html="blockOneList[navlistIndex].content"></div>
			 
		 </div>
	 </div>
  </div>
</template>

<script>

import {FindConstantBySysContantId,GetAutoAirApi} from '@/api/index'
import FaLvFaGuiApiData from './resource/FaLvFaGuiApiDataView.vue' //法律法规
import ShuJuYuBaoGaoApiData from './resource/ShuJuYuBaoGaoApiDataView.vue' //数据报告
import HeZuoAnLiApiData from './resource/HeZuoAnLiApiDataView.vue' //合作案例
import RengZhengZiYuan from './resource/RengZhengZiYuanView.vue' //认证资源
import PingJiaZiYuanApiData from './resource/PingJiaZiYuanApiDataView.vue' //评价资源
import ZhengshuGongshiJIChaxunView from './resource/ZhengshuGongshiJIChaxunView.vue' //评价资源
import ZhengshuChaxunView from './resource/ZhengshuChaxunView.vue' // 核查审定证书查询

import PolicyRightView from "@/views/PolicyRightView.vue";
export default {
  name: "PolicyDetailView",
  components:{
	  FaLvFaGuiApiData,
	  ShuJuYuBaoGaoApiData,
	  HeZuoAnLiApiData,
	  RengZhengZiYuan,
	  PingJiaZiYuanApiData,
	  ZhengshuGongshiJIChaxunView,
	  ZhengshuChaxunView
  },
  data() {
    return {
		blockOneList:[],
		id:'',
		menuJgObj:{},
		autoAirList:[],
		navlistIndex:0,
		isIDlist:[
			'3a0acf0d0498e73bcf0114e4ed118118',
			'3a0acf0d596265e80d1014e4ed118118',
			'3a0cc7ba6194b317b6fb1531ee11ae07',
			'3a0cc7baa571c5dd11061631ee11ae07',
			'3a0cc7baf43c854c17121631ee11ae07',
			'3a0d704e0788c3533703cf4aee11811b',
			'3a14a92ecb4eb05e18cfec64ef1185d6'
		]
    };
  },
	//监听网址 变化 刷新页面
	watch: {
		$route: {
			handler(to, from) {
				if(to.query.id!=undefined){
					this.id = to.query.id
				}else{
					
				}
			},
			deep: true,
		},
	},
  mounted() {
  	this.getFindConstantBySysContantId()
  },
  methods:{
	navTab(id,index){
		this.id = id
		this.navlistIndex = index
		// this.getList()
	},  
	getList(){
		this.getGetAutoAirApi()
	},  
	getGetAutoAirApi(index,id){
		let that = this
		GetAutoAirApi({SysContantid:id}).then(res=>{
			let obj = res.data
			if(res.code==0){
				that.blockOneList[index].content = obj.content
			}
		})
	},
	getFindConstantBySysContantId(){
		let that = this
		FindConstantBySysContantId({ArticleTypeId:'5'}).then(res=>{
			this.blockOneList=res
			
				// this.id = res[0].id
			this.id = this.$route.query.id==undefined?res[0].id:this.$route.query.id
			
			let menuJgObj  = {}
			res.forEach(function(e,index){
				menuJgObj[e.name] = e.id
				if(that.isIDlist.indexOf(e.id)<=-1){
					that.getGetAutoAirApi(index,e.id)
				}
				if(e.id == that.id){
					that.navlistIndex = index
				}
			})
			this.menuJgObj = menuJgObj
			
			// console.log(this.menuJgObj,'=========blockOneListblockOneListblockOneList')
		})
	}
	
  }
};
</script>

<style lang="less" scoped>
	.block-left20{
		width: 20%;
	}
	.block-right78{
		width: 78%;
	}
	.nav-menu{
		height: 3rem;
		font-size: 1.1rem;
		color: #333333;
		line-height: 1.3rem;
		background: #EFF1F6;
		border-radius: 0.1rem;
		margin-bottom: 0.7rem;
	}
	.nav-menu-sel{
		background: #0C2F66;
		border-radius: 0.1rem;
		color: #FFFFFF;
	}
	
	@media (max-width: 768px){
		.fx-justify-space-between{
			display: block;
		}
		.block-left20{
			width: 100%;
			display:flex;
			flex-wrap:wrap;
		}
		.nav-menu{
			font-size: 13px;
			width: auto;
			height: auto;
			padding: 3px 5px;
			margin-right: 8px;
		}
		.block-right78{
			width: 100%;
		}
	}
</style>