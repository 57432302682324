<template>
	 <searchIndustry :ArticleTypeId='id' @searchFun="searchFun"></searchIndustry>
	 <div class="block-list text-left row">
      <!-- 头部导航区 -->
		 <div class="block-one col-12 col-md-4" v-for="(item,index) in list" @click="gotoDetail(item)">
			 <div class="block-one-sun">
				 <div>
					 <div class="block-title">{{item.title}}</div>
					 <div class="block-content">
						 <div v-html="item.synopsis"></div>
					 </div>
				 </div>
				 <div>
					 <div class="block-line"></div>
					 <div class="block-bottom fx">
						 <div class="block-span-block">
							  <div class="block-span-block-lable fx-center">{{item.source}}</div>
							 <span>{{item.releaseTime.split('T')[0]}}</span>
						 </div>
						 <div class="block-span-block">
							 <div class="block-span-block-button fx-center"  v-if="item.isFree"><span>￥</span>{{item.money}}</div>
							 <div class="block-span-block-button fx-center"  v-else>免费</div>
						 </div>
					 </div>
				 </div>
			 </div>
		 </div>
	 </div>
	 <div class="page">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div>
</template>

<script>
import {GetShuJuYuBaoGaoApiData} from '@/api/index'
import searchIndustry from '@/components/searchIndustry.vue' 
export default {
  name: "PolicyDetailView",
  components:{
	searchIndustry
  },
  data() {
    return {
		list:[],
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 6,
		  "sysContantId":''
		},
		total:0
    };
  },
  props: {
    id: String,
  },
  mounted() {
  	this.getGetFaLvFaGuiApiData()
  },
  methods:{
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.queryParma.currentPage = size
		this.getGetFaLvFaGuiApiData()
	},
	handleCurrentChange (currentPage) {
		this.queryParma.currentPage = currentPage
		this.getGetFaLvFaGuiApiData()
	},
	searchFun(val){
		//console.log(val,'===============w333')
		this.queryParma.sysContantId = val
		this.getGetFaLvFaGuiApiData()
	},
	getGetFaLvFaGuiApiData(){
		// this.queryParma.sysContantId = this.id
		GetShuJuYuBaoGaoApiData(this.queryParma).then(res=>{
			if(res.code == 0){
				this.list = res.data
			}else{
				this.list = []
			}
			this.total = res.total
		})
	},
	gotoDetail(item){
		if (item.isWaibuUrl == true) {
			window.open(item.toUrl, '_target');
		} else {
			this.$router.push('/datadetail?id='+item.id)
		}
	},
  }
};
</script>

<style lang="less" scoped>
	
	
	.block-list{
		
		.block-one:nth-child(3n+1) .block-one-sun{
			margin-right:1.43rem ;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-right:0.71rem ;
			margin-left:0.71rem ;
		}
		.block-one:nth-child(3n+3) .block-one-sun{
			margin-left:1.43rem ;
		}
		.block-one{
			.block-one-sun{
				background: #FFFFFF;
				box-shadow: 0rem 0rem 0.75rem -0.05rem rgba(0,0,0,0.06);
				border-radius: 0.2rem;
				padding: 1rem;
				margin-bottom: 1.15rem;
				height: 12.1rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
				.block-title{
					font-size: 0.9rem;
					font-weight: 700;
					color: #333333;
					line-height: 0.9rem;
					margin-bottom: 1rem;
				}
				.block-content{
					font-size: 0.7rem;
					color: #666666;
					line-height: 1rem;
					overflow: hidden;
					text-overflow: ellipsis;
					display: -webkit-box;
					-webkit-line-clamp: 4;
					-webkit-box-orient: vertical;
				}
				.block-line{
					height: 0.05rem;
					background: #F1F1F1;
					border-radius: 0.08rem;
					// margin-top: 1.15rem;
					margin-bottom: 0.85rem;
				}
				.block-bottom{
					display: flex;
					justify-content: space-between;
					.block-span-block{
						
					}
					span{
						font-size: 0.6rem;
						color: #C4C4C4;
						line-height: 0.6rem;
					}
					.block-span-block-lable{
						padding: 4px 8px;
						border-radius: 0.1rem;
						border: 0.05rem solid #014F93;
						font-size: 0.6rem;
						color: #014F93;
						line-height: 0.85rem;
						max-width: 160px;
						display: block;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
					}
					.block-span-block-button{
						width: 6.1rem;
						height: 2.2rem;
						background: #0C2F66;
						border-radius: 0.1rem;
						font-size: 0.9rem;
						font-weight: 500;
						color: #FFFFFF;
						line-height: 1rem;
						span{
							font-size: 0.7rem;
							font-weight: 500;
							color: #FFFFFF;
							line-height: 1rem;
						}
					}
				}
			}
		}
	}
	/**********************************/
	.page {
		margin: 5.25rem 0;
		display: flex;
		justify-content: center;
		align-items: end;
		::v-deep .el-pagination.is-background .el-pager li {
		  width: 1.1rem;
		  height: 1.1rem;
		  background-color: #ffffff;
		  color: #999999;
		  border: 1px solid #d9d9d9;
		  &.is-active {
			background-color: #0c2f66;
			color: #ffffff;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev,
		::v-deep .btn-next.is-last {
		  width: 2.8rem;
		  height: 1.5rem;
		  background-color: #0c2f66;
		  color: #ffffff;
		  &:hover {
			background-color: #ffffff;
			color: #0c2f66;
			border: 1px solid #0c2f66;
		  }
		}
		::v-deep .el-pagination.is-background .btn-prev {
		  margin-right: 1rem;
		}
		::v-deep .btn-next.is-last {
		  margin-left: 1rem;
		}
	}

	@media (max-width: 768px){
		
		.block-one:nth-child(3n+1) .block-one-sun{
			margin-right:0rem !important;
		}
		.block-one:nth-child(3n+2) .block-one-sun{
			margin-right:0rem !important;
			margin-left:0rem !important;
		}
		.block-one:nth-child(3n+3) .block-one-sun{
			margin-right:0rem !important;
			margin-left:0rem !important;
		}
		// .block-list{
		// 	padding:0 10px;
		// }
	}
</style>