import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import axios from './util/request';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap';
import '../node_modules/swiper/swiper.scss'
import '../node_modules/swiper/vue/swiper.js'
import '../node_modules/swiper/modules/pagination/pagination.min.css'
// 引入element-ui
import ElementPlus from 'element-plus'
import VueWechatTitle from 'vue-wechat-title'
import 'element-plus/dist/index.css'

router.afterEach((to, from, next) => {
    document.querySelector("body").setAttribute("style", "overflow: auto !important;")
});
import Base64 from 'js-base64'
const app = createApp(App)
app.use(router)
app.use(ElementPlus)
app.use(Base64)
app.use(VueWechatTitle)
app.mount('#app')