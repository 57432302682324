<template>
	<div class="container  text-center home-container">
		<div class="tab-title-block" v-if="blockOneTitleList[0]">
			<span v-for="(item, index) in blockOneTitleList[0].childContantList" :key="index"
				:class="blockOneTitleIndex == index ? 'tab-title-block-select' : ''" @click="blockTabone(item,index)">{{ item.name }}
				<div class="tab-title-block-select-line"></div>
			</span>
			<div class="index-header-more" @click="tiaozhuan()">更多>></div>
		</div>
		<div class="tab-title"  :class="blockOneListXG[blockOneListXGId]">
			<div v-for="(item, index) in   blockOneList[blockOneListXGId] " :key="index" class=" industry"
				style="overflow: hidden;border-radius: 8px;" @click="gotoDeatil(item)">
				<div style="overflow: hidden; width: 100%;border-radius: 8px;">
					<img :src="blockOneTitleIndex==2?item.tailorPictureUrl:item.picturlUrl" class="img-hover" />
				</div>
				<div class="tab-title-content" style="padding-top: 1rem;border-radius: 8px;">
					<div class="tab-title-title">
						<div class="tab-title-title-line"></div>{{ blockOneTitleIndex==2?item.name:item.title }}
					</div>
					<div class="tab-title-titleen">{{ item.enName }}</div>
					<!-- <div class="tab-title-desc">{{ item.desc }}<span>了解详情</span></div> -->
				</div>
			</div>
		</div>
	</div>

	<div class="container  text-center home-container gb-img-box" @click="openAlerttwo()">
		<img src="../assets/bg/bg-home2.png" alt="" class="box-img">
		<div class="text-box">
			<img src="../assets/public/circle-right.png" alt="" class="text-box-img">
			<span class="text-box-text">业务咨询与申请</span>
			<img src="../assets/public/circle-left.png" alt="" class="text-box-img">
		</div>
	</div>

	<div class="container  text-center home-container">
		<div class="tab-title-block" v-if="blockOneTitleList[1]">
			<div class="nav-more">
				<span class="tab-title-block-select">{{blockOneTitleList[1].childContantList[0].name}}<div class="tab-title-block-select-line"></div></span>
				<div @click="goMore()" class="index-header-more">更多>></div>
			</div>
		</div>
		<div class="grid-md-2">
			<div class=" maskimg ">
				
				<swiper :modules="modules" :slidesPerView="1" :autoplay="{ delay: 3500, disableOnInteraction: true }" :loop="true"
					:direction="'horizontal'" style="height: 100%;" :spaceBetween='20' navigation>
					<swiper-slide v-for="(item,index) in trainingPlanData"  @click="gotoWB(item.toUrl)">
						<div class="mask cursorpointer">
							<span>{{item.cnName }}</span>
							<div class="maskText">
								{{ item.synopsis }}
							</div>
						</div>
						<img :src="item.tailorPictureUrl" alt="" class="college-img">
					</swiper-slide>
				</swiper>
			</div>
			<div class=" three-contant" >
				<div class="three-right cursorpointer" v-for="(item,index) in courseList" @click="gotoWB(item.toUrl)">
					<span>{{item.cnName}}</span>
					<div class="text">
						{{item.synopsis}}
					</div>
					<div class="btn">
						<div class="btn-left"></div>
						<div class="btn-right">{{timeFattmer(item.createTime)}}</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container  text-center home-container">
		<div class="tab-title-block">
			<span class="tab-title-block-select apply">应用中心<div class="tab-title-block-select-line"></div></span>
		</div>
		<!-- <div class="row tab-title tab-title-two">
			<div v-for="(  item, index  ) in   articlee " :key="index" class="col-6 col-md-2 industry">
				<img :src="item.picturlUrl" />
				<div class="tab-title-content platformTools" style="justify-content: flex-start;padding-left: 1.8rem;">
					<div class="tab-title-title" style="font-weight: 500;">{{ item.cnName }}</div>
				</div>
			</div>
		</div> -->
		<div class="tab-title tab-title-two grid-2 grid-md-3 grid-gl-6">
			<div v-for="(  item, index  ) in   articlee " :key="index" class="industry" @click="goApplicationCenter(item)">
				<div style="overflow: hidden; width: 100%;border-radius: 6px;">
					<img :src="item.picturlUrl" class="img-hover" />
				</div>
				<div class="tab-title-content" style="padding-top: 1rem;border-radius: 6px;">
					<div class="tab-title-title">
						{{ item.cnName }}
					</div>
					<!-- <div class="tab-title-titleen">{{ item.titleEn }}</div> -->
					<div class="tab-title-desc">{{ item.synopsis }}</div>
				</div>
			</div>
		</div>
	</div>
	<div class="container-fluid row blockFour">
		<div class="col-12 col-md-6 flex-center">
			<div class="blockFour-tabQh-alse">
				<div class="blockFour-tabQh">
					<span :class="{ select: zsxmCXIndex == 0 }" @click="zsxmCX(0)">证书查询</span>
					<span :class="{ select: zsxmCXIndex == 1 }" @click="zsxmCX(1)">项目进度查询</span>
				</div>
				<form class="flex-center" v-if="zsxmCXIndex == 0">
					<input class="form-check-input" type="text" placeholder="证书编号" v-model="projectSearch.number">
					<input class="form-check-input" type="text" placeholder="企业名称" v-model="projectSearch.qy">
					<div style="color: #ef0f0f;font-weight: 700;" v-show="projectSearch.tishi!=''">{{projectSearch.tishi}}</div>
					<div>
						<button type="button" class="btn-search" @click="searchProject(0)" style="margin-right: 10px;">查 询</button>
						<button type="button" class="btn-search" @click="searchProject(3)">重 置</button>
					</div>
				</form>
				<form class="flex-center" v-if="zsxmCXIndex == 1">
					<input class="form-check-input" type="text" placeholder="项目编号"  v-model="projectSearch.no">
					<div style="color: #ef0f0f;font-weight: 700;" v-show="projectSearch.tishi!=''">{{projectSearch.tishi}}</div>
					<input class="form-check-input" type="text" placeholder="企业名称" style="opacity: 0;">
					<div>
						<button type="button" class="btn-search" @click="searchProject(1)" style="margin-right: 10px;">查 询</button>
						<button type="button" class="btn-search" @click="searchProject(4)">重 置</button>
					</div>
				</form>
			</div>
		</div>
		<div class="col-12 col-md-6 renzheng-list "  v-if="zsxmCXIndex == 0">
			<div class="grid-3 renzheng-list-sun">
				<div class="renzheng-list-one" v-for="(  item, index  ) in   certificateQuery  " :key="index">
					<img :src="item.tailorPictureUrl" />
					<div class="renzheng-title">{{ item.cnName }}</div>
				</div>
			</div>
		</div>
		<div class="col-12 col-md-6 project-info  " v-if="zsxmCXIndex == 1">
			<div class="">
				<div class="project-zong-title">
					项目流程图
				</div>
				<div class="project-list fx-justify-space-around" >
					
					<div class="project-one " v-if="project!=undefined && project.course_info_list!=undefined && project.course_info_list.length>0" v-for="(item,i) in project.course_info_list">
						<div class="project-heng-line"  :class="project.course_show_list[i]=='show'?'project-heng-linesel':''" v-if="i!=0"></div>
						<div  class="project-one-bolck">
							<div class="project-step fx-center" :class="project.course_show_list[i]=='show'?'project-stepsel':''">{{i+1}}</div>
							<div class="project-title" :class="project.course_show_list[i]=='show'?'project-titlesel':''">{{item.name}}</div>
							<div v-if="project.course_show_list[i]=='show'">
								<div class="project-line"></div>
								<div class="project-reviewer">{{item.person}}</div>
								<div class="project-reviewerTime">{{item.date}}</div>
							</div>
						</div>
						<!-- <div class="project-one-bolck">
							<div class="project-step fx-center" :class="project.course_show_list[i]=='show'?'project-stepsel':''">{{i+1}}</div>
							<div class="project-title" :class="project.course_show_list[i]=='show'?'project-titlesel':''">{{item.name}}</div>
							<div v-if="project.course_show_list[i]=='show'==true">
								<div class="project-line"></div>
								<div class="project-reviewer">{{item.person}}</div>
								<div class="project-reviewerTime">{{item.date}}</div>
							</div>
						</div>
						<div class="project-heng-line"  :class="project.course_show_list[i]=='show'?'project-heng-linesel':''" v-if="i!=0"></div> -->
					</div>
					<div class="project-one " v-for="(item,i) in projectProcessList" v-else>
						
						<div  v-if="item.step==undefined" class="project-heng-line"  :class="item.isEax==true?'project-heng-linesel':''"></div>
						<div v-else class="project-one-bolck">
							<div class="project-step fx-center" :class="item.isEax==true?'project-stepsel':''">{{item.step}}</div>
							<div class="project-title" :class="item.isEax==true?'project-titlesel':''">{{item.title}}</div>
							<div v-if="item.isEax==true" style="opacity: 0;">
								<div class="project-line"></div>
								<div class="project-reviewer">{{item.reviewer}}</div>
								<div class="project-reviewerTime">{{item.reviewerTime}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- 
			id:0,
			step:1,
			title:'立项',
			reviewer:'原包容',
			reviewerTime:'2023-04-18' -->
	</div>
	<div class="container  text-center home-container">
		<div class="row">
			<div class="col-12 col-md-6">
				<div class="tab-title-block ">
					<span class="tab-title-block-select par-text">合作伙伴<div class="tab-title-block-select-line"></div></span>
				</div>
				
				<div class="row tab-title tab-title-two "  style="margin-bottom: 20px;margin-top: 1.55rem ;margin-right:3.5rem;">
					<swiper :modules="modules" :slidesPerView="2"  :autoplay="{ delay: 3000, disableOnInteraction: false,loopPreventsSlide: true }"   :speed="2000" :loop="true"
						:direction="'vertical'" style="height: 11rem;" :spaceBetween='pageWidth > 766 ? 10 : 10'>
						<swiper-slide v-for="(  item, index  ) in   partneres  " :key="index" class="swiper-partneres">
							<div class="lunbolisttwo ">
								<div  v-for="(  itemtwo, indextwo  ) in   item " :key="indextwo">
									<aside>
										<img :src="itemtwo.pictureUrl"   @click="gotoWB(itemtwo.toUrl)"/>
									</aside>
									<span>{{itemtwo.name}}</span>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div> 
			</div>
			<div class="col-12 col-md-6">
				<div class="tab-title-block ">
					
					<span class="tab-title-block-select par-text">服务客户<div class="tab-title-block-select-line"></div></span>
				</div>
				<div class="row tab-title tab-title-two tab-title-five"  style="margin-bottom: 20px;margin-top: 1.55rem ;margin-right: 3.5rem;">
					<swiper :modules="modules" :slidesPerView="2"  :autoplay="{ delay:3000, disableOnInteraction: false,loopPreventsSlide: true }"   :speed="2000" :loop="true"
						:direction="'vertical'" style="height: 11rem;" :spaceBetween='pageWidth > 766 ? 10 : 10'>
						<swiper-slide v-for="(  item, index  ) in   partnerestwo  " :key="index" class="swiper-partneres">
							<div class="lunbolist">
								<div  v-for="(  itemtwo, indextwo  ) in   item " :key="indextwo" >
									<img :src="itemtwo.pictureUrl" @click="gotoWB(itemtwo.toUrl)"/>
								</div>
							</div>
						</swiper-slide>
					</swiper>
				</div> 
			</div>
		</div>
		<!-- <div class="row tab-title tab-title-two tab-title-five">
			<swiper :modules="modules" :slidesPerView="8"  :speed="5000" :loop="true"
				:direction="'horizontal'" style="height: 100%;" :spaceBetween='pageWidth > 766 ? 100 : 10'>
				<swiper-slide v-for="(  item, index  ) in   partneres  " :key="index" class="swiper-partneres">
						<img :src="item[0].pictureUrl" />
						<img v-if="item[1]!=undefined" :src="item[1].pictureUrl" />
				</swiper-slide>
			</swiper>
		</div> -->
	</div>
	<div class="container  text-center home-container">
		<div class="tab-title-block">
			<span class="tab-title-block-select">友情链接<div class="tab-title-block-select-line"></div></span>
		</div>
		<div class="row tab-title tab-title-six">
			<el-select v-model="protocol.value" class="select" placeholder="相关协会">
				<el-option
				v-for="(item,i) in protocol"
				:key="i"
				:label="item.cnName"
				:value="item.cnName"
				@click="goToUrl(item)"
				>
				</el-option>
			</el-select>
			<el-select v-model="institution.value" class="select" placeholder="相关机构">
				<el-option
				v-for="(item,i) in institution"
				:key="i"
				:label="item.cnName"
				:value="item.cnName"
				@click="goToUrl(item)"
				>
				</el-option>
			</el-select>
			<el-select v-model="government.value" class="select" placeholder="政府部门">
				<el-option
				v-for="(item,i) in government"
				:key="i"
				:label="item.cnName"
				:value="item.cnName"
				@click="goToUrl(item)"
				>
				</el-option>
			</el-select>
		</div>
	</div>
	<div class="alert-mask" v-if="isAlertMask">
		<div class="alert-mask-content">
			<div class="row icon-close">
				<div class="col-12"  @click="closeAlert()">
					<img src="../assets/icons/icon-close.png"/>
				</div>
			</div>
			<div class="row tools-content ">
				<div class="col-12 col-md-6 tools-list " v-for="(item,index) in toolsList" v-bind:key="index">
					<div class="tools-block fx-justify-content">
						<div class="tools-left fx">
							<img src="../assets/icons/icon-tools.png"/>
							<span>{{item.name}}</span>
						</div>
						<div class="tools-right fx-center" @click="goTools(item)">
							前往使用
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<div class="alert-masktwo" v-if="isAlertMasktwo">
		<div class="alert-masktwo-content">
			<div class="masktwo-header">
				<span>业务咨询与申请</span>
				<img src="../assets/icons/icon-close-two.png" class="icon-close" @click="closeAlerttwo()"/>
			</div>
			<div class="masktwo-tab"  v-if="ywzxysq.length>0">
				<div :class="ywzxysqIndex==0?'masktwo-tab-select':''" @click="setYwzxysqIndex(0)">
					<div class="masktwo-tab-name">{{ywzxysq[0].name}}</div>
					<div class="masktwo-tab-line"></div>
				</div>
				<div :class="ywzxysqIndex==1?'masktwo-tab-select':''" @click="setYwzxysqIndex(1)">
					<div class="masktwo-tab-name">{{ywzxysq[1].name}}</div>
					<div class="masktwo-tab-line"></div>
				</div>
			</div>
			<div class="masktwo-content"  v-if="ywzxysq.length>0">
				<div class="masktwo-content-top" >
					<div class="top-block-one" v-for="(item,index) in ywzxysq[ywzxysqIndex].homeMessageChildData" :class="homeMessageChildDataIndex==index?'top-block-onesel':''" @click="setHomeMessageChildDataIndex(index)">
						<span>|&nbsp;&nbsp;&nbsp;{{item.name}}</span>
					</div>
				</div>
				<div class="masktwo-content-line"></div>
				<div class="masktwo-content-bottom row" v-if="ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex]!=undefined">
					<div class="col-12 col-md-6">
						<img src="../assets/icons/icon-persone.png">
						<span>联系人：<span>{{ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contact}}</span></span>
					</div>
					<div class="col-12 col-md-6">
						<img src="../assets/icons/icon-phone.png">
						<span>联系方式：<span>{{ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactWay}}</span></span>
					</div>
					<div class="col-12 col-md-6" v-if="ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactEmail!=null && ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactEmail!='' && ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactEmail!=undefined">
						<img src="../assets/icons/icon-email.png">
						<span>联系邮箱：<span>{{ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactEmail}}</span></span>
					</div>
					<div class="col-12 col-md-6" v-if="ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactAddress!=null && ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactAddress!='' && ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactAddress!=undefined">
						<img src="../assets/icons/icon-location.png">
						<span>联系地址：<span>{{ywzxysq[ywzxysqIndex].homeMessageChildData[homeMessageChildDataIndex].contactAddress}}</span></span>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

// @ is an alias to /src
import { ProjectGreenjcCourse,GetHomeMessageData,WebSiteGetSysBanner,WebSiteGetArticle,WebSiteContantList,Articlee,Partneres,WebSiteNews,GetStarndData,TrainingPlanGetWebSiteTrainingPlanData,CoursesGetWebSiteCourseList,FindArticleChildByIdData} from '@/api/index'
import { Navigation, Pagination, Scrollbar, A11y, Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css/navigation' // 轮播图两边的左右箭头
export default {
	name: 'HomeView',
	components: {
		Swiper,
		SwiperSlide
	},
	data() {
		return {
			homeMessageChildDataIndex:0,
			ywzxysqIndex:0,
			isAlertMask:false,
			isAlertMasktwo:false,
			mySwiper:[],
			zsxmCXIndex: 0,
			// 协会数据
			protocol:[],
			// 机构数据
			institution:[],
			// 政府数据
			government:[],
			// 首页导航
			navigation:[],
			// 应用中心数据
			articlee:[],
			projectSearch:{
				qy:'',//企业名称
				number:'',//证书编号
				no:'',//项目编号
				tishi:''
			},
			projectProcessList:[
				{
					id:0,
					step:1,
					title:'立项',
					reviewer:'袁保绒',
					reviewerTime:'2023-04-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:2,
					title:'计划',
					reviewer:'袁保绒',
					reviewerTime:'2023-07-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:3,
					title:'执行',
					reviewer:'袁保绒',
					reviewerTime:'2023-04-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:4,
					title:'发起评定',
					reviewer:'袁保绒',
					reviewerTime:'2023-08-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:5,
					title:'评定',
					reviewer:'颜小波',
					reviewerTime:'2023-08-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:6,
					title:'申请完结',
					reviewer:'颜小波',
					reviewerTime:'2023-08-18',
					isEax:true
					
				},
				{
					id:false,
					isEax:true
				},
				{
					id:0,
					step:7,
					title:'完结',
					reviewer:'颜小波',
					reviewerTime:'2023-04-18',
					isEax:true
					
				}
			],
			project:[],
			// 合作伙伴
			partneres:[],
			partnerestwo:[],
			// 国建学院简介
			synopsis:'',
			trainingPlanData:[],//国建学院培训计划
			courseList:[],//国建学院精品课程
			zscprzList: [
			],
			modules: [Navigation, Pagination, Scrollbar, A11y, Autoplay],
			logoList: [],
			blockOneTitleIndex: 0,
			blockOneTitleList: [],
			blockOneListXGId:3,
			blockOneListXG:{
				3:'grid-2 grid-md-3  grid-gl-4',
				6:'grid-2 grid-md-3',
				11:'grid-2 grid-md-3 grid-gl-5',
			},
			blockOneList: {
				
			},
			blockTwoList: [
			],
			blockThreeList: [
			],
			pageWidth: 1920,
			activeIndex: 0,
			searchKeyWord:{
			  "currentPage": 1,
			  "pageSize": 3,
			},
			certificateQuery:[],
			toolsList:[],
			ywzxysq:[],
			isLoginAlertA:1
		}
	},
	mounted() {
		this.pageWidth = window.innerWidth
		this.getProtocol()
		this.getInstitution()
		this.getGovernment()
		this.getNavigation()
		this.getArticlee()
		this.getPartneres()
		this.getNewsList('3')
		this.getNewsList('6')
		this.getGetStarndData()
		this.getSynopsis()
		this.getCertificateQuery()
		this.getSwiper()
		this.getGetHomeMessageData()
	},
	methods: {
		login(){
			this.isLoginAlertA = this.isLoginAlertA+1
			this.$emit('isLoginAlertFun',this.isLoginAlertA)
		},
		gotoWB(url){
			if(url==undefined){
				return
			}
			window.open(url)
		},
		setYwzxysqIndex(val){
			this.ywzxysqIndex = val
			this.homeMessageChildDataIndex = 0
		},
		setHomeMessageChildDataIndex(val){
			this.homeMessageChildDataIndex = val
		},
		closeAlert(){
			this.isAlertMask = false
		},
		closeAlerttwo(){
			this.isAlertMasktwo = false
		},
		openAlerttwo(){
			this.isAlertMasktwo = true
		},
		gotoDeatil(item){
			let url = ''
			switch(this.blockOneTitleList[0].childContantList[this.blockOneTitleIndex].id){
				case '3':
					url = '/industrydetail';
					break;
				case '6':
					url = '/servicedetail';
					break;
				case '11':
					url = '/standard';
					break;
				
			}
			this.$router.push(url+'?id='+item.id)
		},
		tiaozhuan(){
			let url = ''
			switch(this.blockOneTitleList[0].childContantList[this.blockOneTitleIndex].id){
				case '3':
					url = '/industry';
					break;
				case '6':
					url = '/service';
					break;
				case '11':
					url = '/standard';
					break;
				
			}
			this.$router.push(url)
		},
		goMore(){
			this.$router.push('/training')
		},
		//小工具
		goTools(item){
			let token = window.localStorage.getItem('DanDianDenglu1225');
			if(token == null){
					this.isLoginAlertA = this.isLoginAlertA+1
					this.$emit('isLoginAlertFun',this.isLoginAlertA)
			}else{
				let path = '';
				if(item.toUrl.indexOf("?") >= 0 ){
					path = item.toUrl + '&token='+token;
				}else{
					path = item.toUrl + '?token='+token;
				}
				// window.open(item.toUrl+'gettoken?mobile_secret='+window.localStorage.getItem('ProjectGreenjcCnToken'))
				window.open(path)
			}
		},
		goApplicationCenter(item){
			if(item.isToUrl==true){
				this.toolsList = []
				this.getFindArticleChildByIdData(item.id)
				this.isAlertMask = true
			}else if(item.toUrl!=null && item.toUrl!='' && item.toUrl!=undefined){
				var token = window.localStorage.getItem('DanDianDenglu1225');
				if(token == null){
					this.isLoginAlertA = this.isLoginAlertA+1
					this.$emit('isLoginAlertFun',this.isLoginAlertA)
				}else{
					let path = '';
					if(item.toUrl.indexOf("?") >= 0 ){
						path = item.toUrl + '&token='+token;
					}else{
						path = item.toUrl + '?token='+token;
					}
					window.open(path)
				}
			}
		},
		currenNow(e) {
			let activeIndex = e.activeIndex - 1
			activeIndex = activeIndex == 4 ? 0 : activeIndex
			this.activeIndex = activeIndex
		},
		timeFattmer(date){
			let dateArr = date!=undefined?date.split('T'):[]
			return dateArr[0]
		},
		getGetHomeMessageData(){
			GetHomeMessageData().then(res=>{
				this.ywzxysq=res
			})
		},
		// 应用中心-获取弹窗外链
		getFindArticleChildByIdData(id){
			let that = this
			FindArticleChildByIdData({ArticleId:id}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					that.toolsList=res.data
				}
			})
		},
		// 轮播图
		getSwiper(){
			WebSiteGetSysBanner().then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.mySwiper=res.data
				}
			})
		},
		// 获取相关协议
		getProtocol(){
			WebSiteGetArticle({BlogrollTypeId:1}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.protocol=res.data
				}
			})
		},
		// 获取相关机构
		getInstitution(){
			WebSiteGetArticle({BlogrollTypeId:2}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.institution=res.data
				}
			})
		},
		// 获取政府部门
		getGovernment(){
			WebSiteGetArticle({BlogrollTypeId:3}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.government=res.data
				}
			})
		},
		// 获取行业导航
		getNavigation(){
			WebSiteContantList().then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.blockOneTitleList=res.data
					// console.log(this.blockOneTitleList);
				}
			})
		},
		// 获取新闻数据
		getNewsList(TypeId){
			WebSiteNews({articleTypeId:TypeId,hometype:"1"}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.blockOneList[TypeId]=res.data
					// console.log(this.blockOneList);
				}
			})
		},
		// 证书查询
		getCertificateQuery(){
			Articlee({typeid:4}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.certificateQuery=res.data
				}
			})
		},
		// 国建学院
		getSynopsis(){
			// TrainingPlanGetWebSiteTrainingPlanData,CoursesGetWebSiteCourseList
			// TrainingPlanGetWebSiteTrainingPlanData(this.searchKeyWord).then(res=>{
			// 	this.trainingPlanData=res.data
			// })
			// CoursesGetWebSiteCourseList(this.searchKeyWord).then(res=>{
			// 	this.courseList=res.data
			// })
			
			Articlee({typeid:2}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.trainingPlanData=res.data
				}
			})
			Articlee({typeid:3}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.courseList=res.data
				}
			})
		},
		// 获取应用中心数据
		getArticlee(){
			Articlee({ConstantId:1}).then(res=>{
				if(res.code == 0 && res.data.length>0){
					this.articlee=res.data
				}
			})
		},
		// 获取合作伙伴数据
		getPartneres(){
			Partneres({type:1}).then(res=>{//合作伙伴
				if(res.code == 0 && res.data.length>0){
					let obj = res.data
					let dataArr = []
					
					obj.forEach(function(element, index) {
						let indexOne = Math.floor(index/2)
						if(dataArr[indexOne]==undefined){
							dataArr[indexOne] = []
						}
						dataArr[indexOne].push(element)
					})
					this.partneres=dataArr
				}
			})
			Partneres({type:2}).then(res=>{ //服务客户
				if(res.code == 0 && res.data.length>0){
					let obj = res.data
					let dataArr = []
					
					obj.forEach(function(element, index) {
						let indexOne = Math.floor(index/4)
						if(dataArr[indexOne]==undefined){
							dataArr[indexOne] = []
						}
						dataArr[indexOne].push(element)
					})
					this.partnerestwo=dataArr
				}
			})
		},
		// 跳转url
		goToUrl(item){
			if(item.toUrl=='' || item.toUrl==null || item.toUrl==undefined){
				return
			}
			window.open("https://"+item.toUrl)
		},
		zsxmCX(index) {
			this.zsxmCXIndex = index
		},
		searchProject(index) {
			if(index==0){
				if(this.projectSearch.qy=='' && this.projectSearch.number==''){
					this.projectSearch.tishi = '证书编号和企业名称二者必须填写一个'
					return
				}
				this.projectSearch.tishi = ''
				
				this.$router.push('/resourcecentre?id=3a0d704e0788c3533703cf4aee11811b&type=1&zsbh='+this.projectSearch.number+'&qymc='+this.projectSearch.qy)
				// this.getProjectGreenjcCertificate()
			}else if(index==1){
				if(this.projectSearch.no=='' ){
					this.projectSearch.tishi = '项目编号不能为空'
					return
				}
				this.projectSearch.tishi = ''
				
				if (localStorage.getItem('token')) {
					this.getProjectGreenjcCourse()
				}else{
					this.login()
				}
			}else if(index==3){
				this.projectSearch.qy = ''
				this.projectSearch.number = ''
				this.projectSearch.tishi = ''
			}else if(index==4){
				this.projectSearch.no = ''
				this.projectSearch.tishi = ''
				this.project = []
			}
		},
		getProjectGreenjcCourse(){
			
			let ProjectGreenjcCnToken = window.localStorage.getItem('ProjectGreenjcCnToken')
			ProjectGreenjcCourse({token:ProjectGreenjcCnToken,no:this.projectSearch.no}).then(res=>{
				if(res.code!=404){
					this.project = res.list[0]
				}else{
					this.projectSearch.tishi = '查询失败，项目编号不正确'
				}
			})
		},
		blockTabone(item,index) {
			this.blockOneTitleIndex = index
			// this.blockOneList = []
			// if(item.id == 11){
			// 	this.getGetStarndData()
			// }else{
			// 	this.getNewsList(item.id)
			// }
			this.blockOneListXGId=item.id
		},
		getGetStarndData(){
			
			GetStarndData({currentPage: 1,pageSize: 5}).then(res=>{
				this.blockOneList['11']=res
				
			})
		}
	}
}
</script>
<style>
	.project-info>div{
		width: 35.25rem;
		overflow: hidden;
		text-align: left;
	}
	.renzheng-list>div{
		margin:3.5rem;
	}
	.renzheng-list-one{
		margin-bottom: 1.2rem;
		text-align: center;
	}
	.index-header-more{
		float: right;
		line-height: 80px;
		font-size: 15px;
		color: #0d6efd;
	}
	.project-info{
		padding:0 2.55rem !important ;
		align-items: flex-start;
		display: flex;
		flex-direction: column;
		justify-content: center;
	}
	.project-zong-title{
		padding-left: 36px;
		
	}
	.project-zong-title{
		font-size: 1.2rem;
		font-weight: 600;
		color: #141414;
		line-height: 1.65rem;
		text-align: left;
		margin-bottom: 38px;
	}
	.project-list{
	}
	.project-list .project-one .project-heng-line{
		width: 50px;
		height: 1.5px;
		border: 2px dashed #0C2F66;
		position: absolute;
		top: 1.15rem;
		left: -1.25rem;
		opacity: 0.4;
	}
	.project-list .project-one .project-heng-linesel{
		opacity: 1;
		border: 2px solid #0C2F66;
	}
	
	.project-list .project-one{
		position: relative;
	}
	.project-list .project-step{
		border: 0.2rem solid #0C2F66;
		font-size: 0.9rem;
		color: #0C2F66;
		border-radius: 50%;
		width: 2.1rem;
		height: 2.1rem;
		opacity: 0.4;
		margin: 0 auto;
		font-weight: 700;
	}
	.project-list  .project-stepsel{
		opacity: 1;
		border: 0.2rem solid #A5B9D9;
		background: #0C2F66;
		font-size: 0.9rem;
		color: #FFFFFF;
		line-height: 1.25rem;
	}
	.project-list .project-one-bolck{
		width: 96px;
		text-align: center;
	}
	.project-list  .project-title{
		font-size: 0.8rem;
		font-weight: 700;
		color: #0C2F66;
		line-height: 1.1rem;
		margin-top: 14px;
		opacity: 0.4;
	}
	.project-list  .project-titlesel{
		opacity: 1;
	}
	.project-list .project-line{
		width: 0.05rem;
		height: 1.4rem;
		border: 1px dashed  #0C2F66;
		margin: 0 auto;
		margin-top: 10px;
	}
	.project-list  .project-reviewer{
		font-size: 16px;
		font-weight: 600;
		color: #383838;
		line-height: 1.1rem;
		margin-top: 10px;
	}
	.project-list .project-reviewerTime{
		font-size: 14px;
		font-weight: 600;
		color: #383838;
		line-height: 1rem;
		margin-top: 10px;
	}
	/**************业务与咨询弹框开始*************/
	.alert-masktwo{
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.alert-masktwo .alert-masktwo-content{
		width: 57.5rem;
		min-height: 34.83rem;
		background: #FFFFFF;
		border-radius: 0.4rem;
	}
	
	.alert-masktwo .alert-masktwo-content .masktwo-header{
		background:url('../assets/icons/icon-bg-ywzxysq.png');
		width: 100%;
		height: 4.25rem;
		background-size: 100.5%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-header img{
		width: 1.6rem;
		height: 1.6rem;
		position: absolute;
		right: 1.4rem;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-header span{
		font-size: 1.4rem;
		color: #FFFFFF;
		line-height: 2rem;
	}
	
	.alert-masktwo .alert-masktwo-content .masktwo-tab {
		display: inline-block;
		width: 50rem;
		border-bottom: 0.15rem solid #F0F4F4;
		text-align: left;
		margin-top: 2.5rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div{
		display: inline-block;
		font-size: 1rem;
		font-weight: 600;
		color: #0C2F66;
		line-height: 1.45rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div .masktwo-tab-name{
		padding:10px 1.8rem ;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div .masktwo-tab-line{
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div.masktwo-tab-select{
		
			display: inline-block;
			font-size: 1.3rem;
			font-weight: 600;
			color: #0C2F66;
			line-height: 1.85rem;
			position: relative;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div.masktwo-tab-select .masktwo-tab-line{
			background: linear-gradient(138deg, #93E1FF 0%, #22AAFF 100%);
			height: 0.15rem;
			width: 100%;
			position: absolute;
			bottom: -2.2px;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content{
		width: 52rem;
		margin: 0 auto;
		padding-left: 0.3rem;
		margin-top: 2.05rem;
		margin-bottom: 2rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top{
		text-align: left;
		display: flex;
		flex-wrap: wrap;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-one{
		width: 7.25rem;
		height: 2.6rem;
		border-radius: 0.2rem;
		border: 0.09rem solid #014F93;
		font-size: 0.8rem;
		color: #0C2F66;
		line-height: 1.1rem;
		margin: 0 0.65rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0.8rem;
		padding-left: 1rem;
		font-weight: 600;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-onesel{
		background: #014F93;
		border-radius: 0.2rem;
		color: #FFFFFF;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-one span{
		display: inline-block;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-line{
		width: 50rem;
		height: 0.05rem;
		border: 0.13rem solid #F0F4F4;
		margin: 0 auto;
		margin-top: 1rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom{
		margin: 0.8rem;
		margin-top: 1.2rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div{
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div img{
		width: 1.3rem;
		height: 1.35rem;
		margin: 0.575rem 0.5rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div>span{
		font-size: 0.9rem;
		color: #0C2F66;
		line-height: 1.25rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div>span>span{
		font-weight: 700;
	}
	/**************业务与咨询弹框结束*************/
	/**************弹框开始*************/
	.alert-mask{
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.5);
		border-radius: 0.2rem;
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.alert-mask .alert-mask-content{
		width: 65%;
		min-height: 20vh;
		background: #FFFFFF;
		border-radius: 0.2rem;
		padding: 1.5rem 1.5rem;
	}
	.alert-mask .alert-mask-content .icon-close div{
		text-align: right;
		cursor: pointer;
	}
	.alert-mask .alert-mask-content .icon-close img{
		width: 1.9rem;
		height: 1.9rem;
		margin-bottom: 0.5rem;
		cursor: pointer;
	}
	.alert-mask .alert-mask-content .tools-content{
		margin: 0.5rem;
	}
	.alert-mask .alert-mask-content .row .tools-list{
		/* border: 0.05rem solid #0C2F66; */
		cursor: pointer;
	}
	.alert-mask .alert-mask-content .row .tools-block{
		background: #FFFFFF;
		box-shadow: 0rem 0.05rem 1.95rem 0rem rgba(223,223,223,0.61);
		border-radius: 0.2rem;
		padding: 2rem  !important;
		width: 100%;
		margin-bottom: 1rem;
	}
	
	.alert-mask .alert-mask-content .tools-list:nth-child(2n){
		padding-left:1% !important;
	}
	.alert-mask .alert-mask-content .tools-list:nth-child(2n-1){
		padding-right:1% !important;
	}
	
	.alert-mask .alert-mask-content .row .tools-list .tools-left{}
	.alert-mask .alert-mask-content .row .tools-list .tools-left img{
		width: 1.8rem;
		height: 1.83rem;
		margin-right:1.3rem ;
	}
	.alert-mask .alert-mask-content .row .tools-list .tools-left span{
		font-size: 1.2rem;
		font-weight: 500;
		color: #333333;
		line-height: 1.65rem;
	}
	.alert-mask .alert-mask-content .row .tools-list .tools-right{
		width: 7.2rem;
		height: 2.3rem;
		background: #F4FAFF;
		border-radius: 1.25rem;
		border: 0.05rem solid #0C2F66;
		font-size: 1rem;
		color: #0C2F66;
		line-height: 1.4rem;
	}
	
	@media (max-width: 768px) {
		.project-info>div{
			width: auto;
		}
		.maskimg{
			height: 290px !important;
		}
		.renzheng-list>div{
			margin:8px;
			height: 0;
		}
		.renzheng-list-one{
			margin-bottom: 1.2rem;
		}
		.index-header-more{
			float: right;
			line-height: 35px;
			font-size: 12px;
			color: #0d6efd;
		}
		.alert-mask .alert-mask-content .tools-list:nth-child(2n){
			padding-left:0% !important;
		}
		.alert-mask .alert-mask-content .tools-list:nth-child(2n-1){
			padding-right:0% !important;
		}
		.gb-img-box{
			margin-bottom: 10px;
		}
		.alert-mask .alert-mask-content{
			width: 95%;
			height: 70vh;
			overflow-y: auto;
			padding: 15px;
		}
		.alert-mask .alert-mask-content .icon-close img{
			width: 20px;
			height: 20px;
			margin-bottom: 15px;
		}
		.alert-mask .alert-mask-content .row .tools-list .tools-left img{
			width: 20px;
			height: 20px;
			margin-right: 15px;
		}
		.alert-mask .alert-mask-content .row .tools-block{
			padding: 15px !important;
		}
		.alert-mask .alert-mask-content .row .tools-list .tools-right{
			width: auto;
			height: auto;
			font-size: 12px;
			padding:3px 8px;
			line-height: 20px;
		}
		.alert-mask .alert-mask-content .row .tools-list .tools-left span{
			font-size: 14px;
		}
	
		
		.project-info{
			padding: 10px !important;
		}
		.project-list{
			flex-direction: row;
			flex-wrap: wrap;
			overflow: hidden;
			justify-content: flex-start;
		}
		.project-zong-title{
			font-size: 16px;
		}
		.project-zong-title,.project-list .project-one{
			margin-bottom: 15px;
		}
		.project-list .project-one .project-heng-line{
			
			width: 41px;
			left: -21px;
		}
		.project-list .project-one-bolck{
			width: 90px;
		}
		.blockFour>div:nth-child(2){
			min-height: 227px !important;
			height: auto !important;
			padding:15px !important;
		}
		.project-list .project-reviewer{
			font-size: 14px;
		}
		.project-list .project-reviewerTime{
			font-size: 12px;
		}
		.project-list .project-step{
			width:40px;
			font-size: 14px;
			height: 40px;
		}
		/*************************/
	}
	/**************弹框结束*************/
::v-deep(.swiper-wrapper, .swiper-slide) {
        -webkit-transition-timing-function: linear; /*之前是ease-out*/
        -moz-transition-timing-function: linear;
        -ms-transition-timing-function: linear;
        -o-transition-timing-function: linear;
        transition-timing-function: linear;
}
.par-text{
	padding: 0 1.95rem 0.75rem 0.65rem !important;
}
.college .swiper-button-prev, .swiper-button-next{
	z-index: 999;
}
.college{
	width: 100%;
	height: 100%;
}
.college-img{
	width: 100%;
	height: 100%;
    border-radius: 6px;
}
.gb-img-box{
	position: relative;
	padding: 0 !important;
}
.gb-img-box :hover{
	cursor: pointer;
}
.box-img{
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 4.25rem;
}
.text-box{
	position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4.25rem;
}
.text-box-img{
	width: 1.5rem;
	height: 1.5rem;
}
.text-box-text{
	font-size: 1.4rem;
	color: #FFFFFF;
	margin: 0 2rem;
}
.tab-title>div.swiper-partneres{
	/* margin-right: 5.5rem !important; */
	padding: 0 !important;
}
.tab-title-content.platformTools{
	height: 17.7rem;
}

.el-select .el-input__wrapper{
	width: 22rem;
	height: 3.25rem;
}
.el-select.select{
	width: 32%;
    padding: 0 !important;
}
.el-select .el-input__inner{
    padding-left: 1.8rem;
}
/* .tab-title>div{
	width: 22rem;
	margin-right: 2rem;
} */

.home-container .form-select option{
	background-color: #FFFFFF;
	color: #606266;
	margin-top: 5px;
}
.tab-title-title1 {
	align-items: flex-start !important;
}

.platformTools:hover {
	/* position: absolute;
	top: 0;
	left: 0; */
	/* width: 100% !important;
	height: 100% ! important; */
	background: rgba(12, 47, 102, 0.75);
	border-radius: 8px;
}

.renzheng-list .row {
	--bs-gutter-x: 0;
	align-items: center;
	justify-content: space-around;
	padding-top: 3rem !important;
}

.renzheng-list .row .renzheng-list-one {
	text-align: center;
    margin-bottom: 1.2rem;
}

.renzheng-list img {
	width: 3.9rem;
	height: 3.9rem;
}

.renzheng-list .renzheng-title {
	font-size: 0.75rem;
	color: #383838;
	line-height: 1.65rem;
	white-space: nowrap;
}

.btn-search {
	width: 7rem;
	height: 2.3rem;
	background: #0C2F66;
	border-radius: 0.2rem;
	backdrop-filter: blur(1px);
	font-size: 0.7rem;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 1rem;
	letter-spacing: 1px;
	border: 0px;
	margin-top: 1rem;
	cursor: pointer;
}

.blockFour .blockFour-tabQh {
	font-size: 1rem;
	color: #FFFFFF;
	line-height: 1.4rem;
	margin-bottom: 1.6rem;
	width: 23.7rem;
	text-align: left;
	cursor: pointer;
}

.blockFour-tabQh-alse,.renzheng-list-sun{
	width: 35.05rem;
}
.blockFour .blockFour-tabQh .select {
	font-size: 1.3rem;
	font-family: AlibabaPuHuiTiB;
	color: #FFFFFF;
	font-weight: 600;
}

.blockFour .blockFour-tabQh span:nth-child(1) {
	margin-right: 2.8rem;

}

form {
	align-items: flex-start !important;
}

form .form-check-input {
	width: 23.7rem !important;
	height: 2.5rem !important;
	background: rgba(0, 0, 0, 0.3);
	border-radius: 0.3rem;
	border: 0.05rem solid rgba(255, 255, 255, 0.3);
	margin-bottom: 0.5rem;
	font-size: 0.8rem;
	color: #FFFFFF;
	line-height: 1.1rem;
	letter-spacing: 1px;
	text-indent: 1.6rem;
}

input::-webkit-input-placeholder {
	font-size: 0.8rem;
	color: #FFFFFF;
	line-height: 1.1rem;
	letter-spacing: 1px;
}

.tab-title-six {
	
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 5.11rem;
}

.tab-title-six form {
	width: 22rem;
}

.tab-title-six form:nth-child(2) {
	margin: 0 2rem !important;
}

.home-container {
	margin-top: 1.65rem;
}

.tab-title {}

.tab-title>div {
	display: flex;
	align-items: center;
	justify-content: center;
	/* padding: 0.5rem !important; */
	margin-bottom: 1rem;
	position: relative;
	/* margin: 0 1rem !important; */
	/* overflow: hidden !important; */
}

.tab-title img {
	width: 100%;
	object-fit: cover;
	height: 9rem;
	border-radius: 8px;
	overflow: hidden;
}

.tab-title-content {
	position: absolute;
	left: 0rem;
	top: 0rem;
	color: #FFFFFF;
	width: 100%;
	height: 100%;
	padding-left: 1.3rem;
	text-align: left;
	/* padding-top: 1rem; */
	cursor: pointer;
	border-radius: 8px;
	overflow: hidden;
}

/* .tab-title-three .tab-title-content {
	left: 0rem;
	top: 0rem;
	color: #FFFFFF;
	width: 100%;
	height: 100%;
} */
.tab-title-content .tab-title-title {
	font-size: 1.2rem;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 1.65rem;
	display: flex;
	align-items: center;
	/* padding-left: 3rem; */
	border-radius: 8px;
	overflow: hidden;
}

.tab-title-content .tab-title-title .tab-title-title-line {
	width: 2px;
	height: 1rem;
	background: #FFFFFF;
	border-radius: 0.1rem;
	margin-right: 0.35rem;
}

.tab-title-content .tab-title-titleen {
	font-size: 0.6rem;
	color: #FFFFFF;
	line-height: 0.85rem;
}

.tab-title-content .tab-title-desc {
	margin-top: 0.7rem;
	font-size: 0.65rem;
	color: #FFFFFF;
	line-height: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 12;
    -webkit-box-orient: vertical;
	display: -webkit-box;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-line-clamp: 12;
	-webkit-box-orient: vertical;
	opacity: 0;
	/* width: 22.35rem; */
}
.col-md-3 .tab-title-content .tab-title-desc{
	
    max-width: 14.35rem;
}
.tab-title-content .tab-title-desc span {
	color: #3CB6FF;
	margin-left: 0.5rem;
}

.tab-title .tab-title-content:hover {
	background: rgba(12, 47, 102, 0.75);
	border-radius: 8px;
}

.tab-title-content:hover .tab-title-desc {
	opacity: 1;
}

.tab-title-two {
	margin-bottom: 2.45rem;
}

.tab-title-two .tab-title-content {
	/* display: flex;
	align-items: center;
	justify-content: center; */
	padding:0 1.5rem;
	padding-top: 1.6rem !important;
	font-size: 1rem;
	color: #FFFFFF;
	line-height: 1.6rem;
	top: 0rem;
	left: 0rem;
	/* width: 92%; */
	height: 100%;
	border-radius: 8px;

}

.tab-title-two img {
	height: 17.7rem;
}

.tab-title-two>div {
	/* padding: 0.25rem !important; */
}

.tab-title-three {
	flex-wrap: unset !important;
	/* display: flex;
	justify-content: space-around; */
}
.row .img{
	margin-right: 1.4rem;
}
.tab-title-three img {
	width: 35.45rem;
	height: 23.25rem; 
}
.row .three-right{
	/* margin-left: 1.4rem; */
	/* margin-bottom: 1rem; */
}
.row .three-right:last-child{
	margin-bottom: 0;
}
.maskimg{
	position: relative;
	height: 23.25rem;
	border-radius: 8px;
}
.mask{
	width: 100%;
	height: 100%;
	position: absolute;
	background: rgba(0,0,0,0.39);
	z-index: 99;
	border-radius: 10px;
	color: #FFFFFF;
	padding: 0 6.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
/* .bgimg{
	width: 100%;
	height: 100%;
	border-radius: 10px;
} */
.mask span{

	font-size: 1.4rem;
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box;
	-webkit-line-clamp: 2;
	-webkit-box-orient: vertical;
}
.mask .maskText{
	margin-top: 0.8rem;
	font-size: 0.8rem;
	color: #FFFFFF;
	letter-spacing: 1px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
}
.three-contant{
	height: 23.25rem;
	/* justify-content: space-between; */
}
.three-right{
	/* padding: 1.3rem; */
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	/* width: 33.25rem; */
	height: 7.1rem;
	/* border: 1px dashed gray; */
	background-color: #F2F2F2;
    margin-bottom: 1rem;
	border-radius: 8px;
}
.three-right span,.three-right .text,.three-right .btn{
	margin-left: 1.3rem !important;
	margin-right: 1.2rem !important;
}
.three-right span{
	width: 31rem;
	height: 1.3rem;
	font-size: 0.9rem;
	font-weight: bold;
	text-align: left;
	color: #333333;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	margin-top: 1rem;
}
.three-right .text{
	font-size: 0.7rem;
	text-align: left;
	color: #666666;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.three-right .btn{
	padding: 0;
	font-size: 0.6rem;
	display: flex;
	justify-content: space-between;
	margin-bottom: 0.75rem;
}
.three-right .btn .btn-left{
	border-radius: 0.2rem;
	width: 3.6rem;
	height: 1.35rem;
	color: #FFFFFF;
	text-align: center;
	line-height: 1.35rem;
	/* background-color: #0C2F66; */
}
.three-right .btn .btn-right{
	border-radius: 0.2rem;
	width: 4.3rem;
	height: 1.35rem;
	background: #FFFFFF;
	text-align: center;
	line-height: 1.35rem;
	overflow: hidden;
}
.tab-title-three .tab-title-content {
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 1rem;
	color: #FFFFFF;
	line-height: 1.4rem;
	padding-left: 3rem;
}

.tab-title-three .tab-title-content:hover {
	display: flex;
	align-items: flex-start;
	justify-content: center !important;
	flex-direction: column;
}

.tab-title-three .tab-title-content:hover .tab-title-title {
	font-size: 0.9rem;
	color: #FFFFFF;
	line-height: 1.35rem;
	margin-bottom: 1.95rem;
}

.tab-title-three .tab-title-content:hover .tab-title-desc {
	font-size: 0.65rem;
}

.blockFour {
	margin-bottom: 2.15rem;
	--bs-gutter-x: 0 !important
}

.blockFour>div:nth-child(1) {
	height: 100%;
	background: url(@/assets/bg/bg-block-four1.png);
	background-size: 100% 100%;
	height: 19rem;
	align-items: flex-end;
}

.blockFour>div:nth-child(2) {
	height: 100%;
	background: url(@/assets/bg/bg-block-four2.png);
	background-size: 100% 100%;
	height: 19rem;
}

.tab-title-five>div {
	display: flex;
	justify-content: start;
}

.blockFour .col-md-24 {
	display: flex;

}

.col-md-24 img {
	max-width: 9.3rem;
	max-height: 4.35rem;
}

.tab-title-five img {
	height: auto !important;
	/* max-width: 13.45rem; */
	/* max-height: 4.7rem ; */
}

	.lunbolist{
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
	.lunbolist div{
		width: 22%;
		height: 100px;
		display: flex;
		align-items: center;
	}
	.lunbolist img{
		width: 100%;
		/* margin-right: 4%; */
		max-width:none ;
		object-fit: contain;
		max-height: 100%;
	}
	.lunbolist img:nth-child(4n){
		margin-right: 0;
	}
	
	.lunbolisttwo{
		display: grid;
		flex-wrap: wrap;
		grid-template-columns: repeat(auto-fill, 49%);
		justify-content: space-between;
		width: 100%;
	}
	.lunbolisttwo div:nth-child(1){
		height: 100px;
		display: flex;
		align-items: center;
		text-align: left;
	}
	.lunbolisttwo div:nth-child(2){
		height: 100px;
		display: flex;
		align-items: center;
		text-align: left;
	}
	.lunbolisttwo aside{
		width: 6.6rem;
		height: 4.35rem;
		display: flex;
		align-items: center;
		padding: 5px;
	}
	.lunbolisttwo span{
		width: calc(100% -  6.6rem);
		font-size: 0.8rem;
	}
	.lunbolisttwo aside img{
		max-width: 6.6rem;
		object-fit: contain;
		max-height: 100%; 
		overflow: unset;
	}
	.lunbolisttwo img:nth-child(4n){
		margin-right: 0;
	}
@media (max-width: 768px) {
	.three-contant{
		height: auto;
	}
	
	.blockFour-tabQh-alse,.renzheng-list-sun{
		width: 100%;
	}
	.text-box{
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 50px;
	}
	.box-img {
	    position: absolute;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height:50px;
	}
	.text-box-img{
		width: 20px;
		height: 20px;
	}
	.text-box-text{
		font-size: 16px;
		color: #FFFFFF;
		margin: 0 2rem;
	}
	.swiper-slide.swiper-partneres{
		width: 100% !important;
	}
	.swiper-partneres.swiper-partneres{
		margin-right: 0.5rem !important;
	}
	.tab-title-five>div{
		height:8rem !important;
	}
	
	.el-select .el-input__wrapper{
		width: 22rem;
		height: 3rem;
	}
	.mask{
		height: 100%;
		font-size: 0.5rem;
		border-radius: 10px;
	}
	.mask span{
		font-size: 20px;
		font-weight: bold;
		margin: 0 20%;
		margin-top: 15%;
		padding-bottom: 0;
		height: auto;
	}
	.mask .maskText{
		padding: 0 5%;
		font-size: 12px;
		width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
	}
	/* .bgimg{
		width: 100% !important;
		border-radius: 10px;
	} */
	.row .maskimg{
		width: 100%;
		height: auto;
	}
	.row .three-contant{
		width: 100%;
	}
	.row .three-right{
		margin-bottom: 0.25rem;
		margin-left:1px;
		height: auto;
	}
	.tab-title-two .tab-title-content {
	/* display: flex;
	align-items: center;
	justify-content: center; */
	font-size: 1rem;
	color: #FFFFFF;
	line-height: 1.4rem;

	top: 8px;
	left: 8px;
	width: 91%;
	height: 85% !important;
	border-radius: 8px;
	height: 7rem;
	}
	.three-right{
		/* width: 22rem;
		height: 7rem; */
		width: 100%;
		margin-top: 8px;
		border-radius: 8px;
		height: auto;
	}
	.three-right span{
		text-align: left;
		font-size: 14px;
		width: 95%;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
		margin-bottom: 8px;
	}
	.three-right .text{
		width: 89%;
		font-size: 12px;
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
	.three-right .btn{
		margin-top: 0.5rem;
	}
	.tab-title-five {
		margin-top: 0px  !important;
	}

	.tab-title-five img {

		max-width: 8rem;
		max-height: 4rem;
	}

	.tab-title img {
		object-fit: cover;
		width: 100%;
		height: 6rem;
		border-radius: 8px;
	}

	.tab-title-two {
		margin-bottom: 0.5rem !important;
	}

	form .form-check-input {
		width: 100% !important;
	}

	.blockFour>div {
		padding: 20px !important;
		height: 14rem !important;
	}

	.blockFour .blockFour-tabQh {
		padding: 0 40px !important;
		font-size: 0.8rem !important;
		margin-bottom: 1rem;
	}

	.blockFour .blockFour-tabQh .select {
		font-size: 1rem !important;
	}

	.renzheng-list .row {
		padding-top: 0rem !important;
	}

	.renzheng-list img {
		width: 2.5rem;
		height: 2.5rem;
	}

	.renzheng-list .renzheng-title {
		font-size: 0.6rem !important;
		height: 40px !important;
	}

	.tab-title-three .tab-title-content {
		padding-left: 10px;
		font-size: 0.8rem;
	}

	.blockFour form.flex-center {
		width: 100% !important;
	}
	.home-container{
		margin-top: 20px;
	}
	.tab-title-six {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}

	.tab-title-six form,
	.tab-title-six form:nth-child(2) {
		margin-bottom: 10px !important;
	}

	.tab-title-content .tab-title-title {
		font-size: 14px;
	}

	.tab-title-content .tab-title-titleen {
		font-size: 0.5rem !important;
	}
	.tab-title-content:hover .tab-title-desc {
		display: none;
	}
	.el-select.select{
		margin-bottom: 10px !important;
	}
	.lunbolist{
		margin: 0 10px;
	}
	.lunbolist div{
		height: 60px;
	}
	.lunbolist img{
		width: 100%;
		/* margin-right: 4%;
		max-width:22% ; */
		object-fit: contain;
	}
	.lunbolist img:nth-child(4n){
		margin-right: 0;
	}
	
	/**************业务与咨询弹框开始*************/
	.alert-masktwo{
		position: fixed;
		top: 0;
		left: 0;
		background: rgba(0,0,0,0.5);
		width: 100%;
		height: 100%;
		z-index: 99;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.alert-masktwo .alert-masktwo-content{
		width: 95%;
		min-height: 34.83rem;
		background: #FFFFFF;
		border-radius: 0.4rem;
	}
	
	.alert-masktwo .alert-masktwo-content .masktwo-header{
		background:url('../assets/icons/icon-bg-ywzxysq.png');
		width: 100%;
		height: 54px;
		background-size: 100.5% 100%;
		background-repeat: no-repeat;
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-header img{
		width: 1.6rem;
		height: 1.6rem;
		position: absolute;
		right: 1.4rem;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-header span{
		font-size: 1.4rem;
		color: #FFFFFF;
		line-height: 2rem;
	}
	
	.alert-masktwo .alert-masktwo-content .masktwo-tab {
		display: inline-block;
		width: 100%;
		border-bottom: 0.15rem solid #F0F4F4;
		text-align: left;
		margin-top: 10px;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div{
		display: inline-block;
		font-size: 14px;
		font-weight: 600;
		color: #0C2F66;
		line-height: 1.45rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div .masktwo-tab-name{
		padding:5px 20px ;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div .masktwo-tab-line{
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div.masktwo-tab-select{
		display: inline-block;
		font-size: 18px;
		font-weight: 600;
		color: #0C2F66;
		line-height: 1.85rem;
		position: relative;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-tab>div.masktwo-tab-select .masktwo-tab-line{
		background: linear-gradient(138deg, #93E1FF 0%, #22AAFF 100%);
		height: 0.15rem;
		width: 100%;
		position: absolute;
		bottom: -2.2px;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content{
		width: 100%;
		margin: 0 auto;
		padding-left: 0.3rem;
		margin-top: 20px;
		margin-bottom: 20px;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top{
		text-align: left;
		display: flex;
		flex-wrap: wrap;
		max-height: 44vh;
		overflow-y: auto;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-one{
		width: 100px;
		height: 2.6rem;
		border-radius: 0.2rem;
		border: 0.09rem solid #014F93;
		font-size: 0.8rem;
		color: #0C2F66;
		line-height: 1.1rem;
		margin: 0 0.65rem;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin-bottom: 0.8rem;
		padding-left: 10px;
		font-weight: 600;
		cursor: pointer;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-onesel{
		background: #014F93;
		border-radius: 0.2rem;
		color: #FFFFFF;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-top .top-block-one span{
		display: inline-block;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-line{
		width: 100%;
		height: 1px;
		border: 0.025rem solid #F0F4F4;
		margin: 0 auto;
		margin-top: 1rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom{
		margin: 0.8rem;
		margin-top: 20px !important;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div{
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div img{
		width: 1.3rem;
		height: 1.35rem;
		margin: 0.575rem 0.5rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div>span{
		font-size: 0.9rem;
		color: #0C2F66;
		line-height: 1.25rem;
	}
	.alert-masktwo .alert-masktwo-content .masktwo-content .masktwo-content-bottom>div>span>span{
		font-weight: 700;
	}
	/**************业务与咨询弹框结束*************/
}

.swiper-button-prev,
.swiper-button-next {
	color: #25abff;
}

.industry {
	overflow: hidden !important;
	transition: all .5s ease .1s;
}

.industry:hover {
	overflow: hidden !important;

}

.industry:hover img{
	/* transform: scale(1.2); */
	animation: bigbian 1s ;
	z-index: 9999;
}
</style>
