import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import PolicyView from '@/views/PolicyView.vue' //新闻资讯
import PolicyDetailView from '@/views/PolicyDetailView' //新闻咨询详情
import TrainDetailView from '@/views/TrainDetailView.vue'
import TrainingView from '@/views/TrainingView.vue'//国建学院-培训计划
import CoursesView from '@/views/CoursesView.vue'//国建学院-精品课程
import IndustryView from '@/views/IndustryView.vue' //行业
import IndustryDetailView from '@/views/IndustryDetailView.vue' //行业详情
import ServiceView from '@/views/ServiceView.vue' //服务与解决方案
import ServiceDetailView from '@/views/ServiceDetailView.vue' //服务与解决方案详情
import ResourceCentreView from '@/views/ResourceCentreView.vue' //资源中心
import AboutView from '@/views/AboutView.vue' //关于我们
import StandardView from '@/views/StandardView.vue' //标准
import CoursesDetailView from '@/views/CoursesDetailView.vue' //标准
import PersonalCenterView from '@/views/PersonalCenterView.vue' //个人中心
import DataDetailView from '@/views/DataDetailView.vue' //个人中心
import SearchView from '@/views/SearchView.vue' //搜索
import ResourceCentreDetailView from '@/views/ResourceCentreDetailView.vue' //搜索
import AgreeView from '@/views/AgreeView.vue' //搜索
import StandardDetailView from '@/views/StandardDetailView.vue' //搜索

// Industry
const routes = [{
    path: '/',
    redirect: '/home',
    component: () =>
        import ('@/layouts/Layout'),
    children: [{
            path: '/',
            name: 'home',
            component: HomeView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/policy',
            name: 'policy',
            component: PolicyView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/policydetail',
            name: 'policydetail',
            component: PolicyDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/training',
            name: 'training',
            component: TrainingView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/trainingdetail',
            name: 'trainingdetail',
            component: TrainDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/coursesdetail',
            name: 'coursesdetail',
            component: CoursesDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/courses',
            name: 'courses',
            component: CoursesView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/industry',
            name: 'industry',
            component: IndustryView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/industrydetail',
            name: 'industrydetail',
            component: IndustryDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/service',
            name: 'service',
            component: ServiceView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/servicedetail',
            name: 'servicedetail',
            component: ServiceDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/resourcecentre',
            name: 'resourcecentre',
            component: ResourceCentreView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/resourcecentredetail',
            name: 'resourcecentredetail',
            component: ResourceCentreDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/about',
            name: 'about',
            component: AboutView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/standard',
            name: 'standard',
            component: StandardView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/personalcenter',
            name: 'personalcenter',
            component: PersonalCenterView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/datadetail',
            name: 'datadetail',
            component: DataDetailView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/search',
            name: 'search',
            component: SearchView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/agree',
            name: 'agree',
            component: AgreeView,
			 meta: {x: 0, y: 0}
        },
        {
            path: '/standarddetail',
            name: 'standarddetail',
            component: StandardDetailView,
			 meta: {x: 0, y: 0}
        }
    ],
}, ]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    // history: createWebHistory(),
    routes,
	scrollBehavior(to, from, savedPosition) {
		// 始终滚动到顶部
		return { top: 0 }
	},
})

export default router