<template>
	 <div class="new-list text-left">
		 
		<div class="tab-title-block">
			<div class="nav-more">
				<span class="tab-title-block-select">评价资源<div class="tab-title-block-select-line"></div></span>
				
			</div>
		</div>
		<div class="new-one ">
			<div class="new-one-sun fx-justify-content cursorpointer"  v-for="(item,index) in list" @click="gotoWB(item.toUrl)">
				<div class="fx">
					<div class="new-icon"></div>
					<div class="new-title">{{item.title}}</div>
				</div>
				<div class="new-time">{{item.createTime.split('T')[0]}}</div>
			</div>
		 </div>
	 </div>
<!-- 	 <div class="page">
	   <el-pagination
	     small
	     background
	     layout="prev, pager, next"
	     prev-text="上一页"
	     next-text="下一页"
	     :total="total"
	     class="mt-4"
		  :page-size='queryParma.pageSize'
		  @size-change="handleSizeChange"
		  @current-change="handleCurrentChange"
	   />
	 </div> -->
</template>

<script>
import {GetPingJiaZiYuanApiData} from '@/api/index'
export default {
  name: "PolicyDetailView",
  data() {
    return {
		list:[],
		queryParma:{
		  "currentPage": 1,
		  "pageSize": 100,
		  "sysContantId": ""
		},
		total:0
    };
  },
  props: {
    id: String,
  },
  mounted() {
	//console.log(1111111111111)
  	this.getGetFaLvFaGuiApiData()
  },
  methods:{
	  gotoWB(url){
		  window.open(url)
	  },
	// 初始页currentPage、初始每页数据数pagesize和数据data
	handleSizeChange (size) {
		this.BoutiqueSerch.currentPage = size
		this.getGetFaLvFaGuiApiData()
	},
	handleCurrentChange (currentPage) {
		this.BoutiqueSerch.currentPage = currentPage
		this.getGetFaLvFaGuiApiData()
	},
	getGetFaLvFaGuiApiData(){
		// this.queryParma.sysContantId = this.id
		GetPingJiaZiYuanApiData(this.queryParma).then(res=>{
			if(res.code == 0){
				this.list = res.data
			}
			this.total = res.total
		})
	},
  }
};
</script>

<style lang="less" scoped>
	
	.new-one{
		margin-top: 2.2rem;
		background: #FAFAFA;
		border-radius: 0.1rem;
		padding: 0rem 1.65rem;
		.new-one-sun{
			padding-top: 1.5rem;
			padding-bottom: 1.5rem;
			border-bottom: 1px solid #F1F1F1;
			.new-icon{
				width: 0.4rem;
				height: 0.4rem;
				background: #0C2F66;
				border-radius: 50%;
				margin-right: 0.5rem;
			}
			.new-title{
				font-size: 1rem;
				color: #141414;
				line-height: 1.65rem;
			}
			.new-time{
				font-size: 0.8rem;
				color: #666666;
				line-height: 0.9rem;
			}
		}
	}
	
	@media (max-width: 768px){
		.new-one{
			margin-top: 20px;
			padding: 0 15px;
		}
	
		.tab-title-block{
			padding-top: 0px;
		}
		.new-one .new-one-sun{
			padding: 10px 0;
		}
		.new-title{
			font-size: 14px !important;
		}
	}
</style>